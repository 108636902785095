<template>
<!-- ACTIVE SESSION -->
<div v-if="showPanel && showPanelLoanDetail"
  class="card card-body mb-2 mt-0" style="background-color: #fff1e3">
  <div v-if="vData.statusCall === 'RINGING'"
      class="call_popup_container">
    <div class="row">
      <div class="col-md-12">
        <div class="wrap_info_call">
          <h2>{{ vData.customer_name }}</h2>
          <p>+{{ vData.phone_no }}</p>
          <p>{{ (vData.subIoType || '').replace('_', ' ') }}</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-auto">
        <button @click="answerCall" type="button" v-b-tooltip.hover title="Accept" class="btn rounded-pill btn-icon btn-success">
          <i class="ti ti-phone-check"></i>
        </button>
      </div>

      <div class="col-md-auto">
        <button @click="rejectCall" type="button" v-b-tooltip.hover title="Decline" class="btn rounded-pill btn-icon btn-danger">
          <i class="ti ti-phone-x"></i>
        </button>
      </div>
    </div>
  </div>

  <div v-else-if="vData.statusCall === 'ACCEPTING'"
      class="call_popup_container">
    <div class="row">
      <div class="col-md-12">
        <div class="wrap_info_call">
          <h2>{{ vData.customer_name }}</h2>
          <p>+{{ vData.phone_no }}</p>
          <p>{{ (vData.subIoType || '').replace('_', ' ') }}</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-auto">
        <button type="button" disabled class="btn rounded-pill btn-icon btn-success">
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </button>
      </div>

      <div class="col-md-auto">
        <button type="button" disabled class="btn rounded-pill btn-icon btn-danger">
          <i class="ti ti-phone-x"></i>
        </button>
      </div>
    </div>
  </div>
  
  <div v-else>
    <!-- ONGOING / ENDED CALL -->
      <div class="call_action_box m-0">
      <div class="call_process_wrap">
        <h2>
          {{ vData.customer_name }}
        </h2>
        <h3>
          {{ (vData.subIoType || '').replace('_', ' ') }}
        </h3>
        <p>
          {{ vData.phone_no }}
        </p>

        <h4 v-if="UASessionCallStatus === 'RINGING'" class="text-danger">
          <b class="d-block mb-1">RINGING</b>
          {{ tempTimer }}
        </h4>
        <h4 v-else-if="UASessionCallStatus === 'ONGOING'" class="text-success">
          <b class="d-block mb-1">ONGOING</b>
          {{ tempTimer }}
        </h4>
        <h4 v-else class="text-primary">
          <b class="d-block mb-1 text-danger">ENDED</b>
          {{ finalTimer }}
        </h4>
      </div>

      <div v-if="showButtonRinging" 
        class="mt-3 row align-items-center justify-content-md-center">
        <div class="col-md-auto">
          <div class="wrap_act">
            <button @click="endCall()"
              type="button" class="btn rounded-pill btn-icon btn-sm btn-danger mb-2 mb-md-0">
              <i class="ti ti-phone-x"></i>
            </button>
            <p>Cancel</p>
          </div>
        </div>
      </div>

      <div v-else-if="showButtonOngoing" 
        class="mt-3 row align-items-center justify-content-md-center">
        <div class="col-md-auto">
          <div class="wrap_act">
            <button @click="holdCall()"
              type="button" :class="{
                'btn rounded-pill btn-icon btn-sm mb-2 mb-md-0': true,
                'btn-outline-warning': !UASessionData.feature.onHold,
                'btn-warning': UASessionData.feature.onHold
              }">
              <i :class="{
                'ti ti-player-pause': !UASessionData.feature.onHold,
                'ti ti-player-play': UASessionData.feature.onHold
              }"></i>
            </button>
            <p>{{ UASessionData.feature.onHold ? 'Unhold' : 'Hold' }}</p>
          </div>
        </div>
        <div class="col-md-auto">
          <div class="wrap_act">
            <button @click="endCall()"
              type="button" class="btn rounded-pill btn-icon btn-sm btn-danger mb-2 mb-md-0">
              <!-- <i class="ti ti-phone-check"></i> -->
              <i class="ti ti-phone-x"></i>
            </button>
            <p>End</p>
          </div>
        </div>
        <div class="col-md-auto">
          <div class="wrap_act">
            <button @click="muteCall()"
              type="button" :class="{
                'btn rounded-pill btn-icon btn-sm mb-2 mb-md-0': true,
                'btn-outline-warning': !UASessionData.feature.onMute,
                'btn-warning': UASessionData.feature.onMute
              }">
              <i :class="{
                'ti ti-microphone-off': true
              }"></i>
            </button>
            <p>{{ UASessionData.feature.onMute ? 'Unmute' : 'Mute' }}</p>
          </div>
        </div>
      </div>

      <div v-else-if="isReadyCallResult === true" 
        class="mt-3 row align-items-center justify-content-md-center">
        <div class="col-md-auto">
          <button @click="formConf.viewCallResult = true"
            type="button" class="btn btn-rounded btn-primary btn-sm btn-icon-text mb-2 mb-md-0">
            <i class="ti ti-edit"></i> 
            <span class="ms-1">Add Call Result</span> 
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<b-modal
  :modelValue="showPanel && !showPanelLoanDetail" hide-footer hide-header hide-header-close no-close-on-backdrop no-close-on-esc size="md">
  <div v-if="showPanel">
    <div v-if="vData.statusCall === 'RINGING'"
    class="call_popup_container">
      <div class="row">
        <div class="col-md-12">
          <div class="wrap_info_call">
            <h2>{{ vData.customer_name }}</h2>
            <p>+{{ vData.phone_no }}</p>
            <p>{{ (vData.subIoType || '').replace('_', ' ') }}</p>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-auto">
          <button @click="answerCall" type="button" v-b-tooltip.hover title="Accept" class="btn rounded-pill btn-icon btn-success">
            <i class="ti ti-phone-check"></i>
          </button>
        </div>

        <div class="col-md-auto">
          <button @click="rejectCall" type="button" v-b-tooltip.hover title="Decline" class="btn rounded-pill btn-icon btn-danger">
            <i class="ti ti-phone-x"></i>
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="vData.statusCall === 'ACCEPTING'"
      class="call_popup_container">
      <div class="row">
        <div class="col-md-12">
          <div class="wrap_info_call">
            <h2>{{ vData.customer_name }}</h2>
            <p>+{{ vData.phone_no }}</p>
            <p>{{ (vData.subIoType || '').replace('_', ' ') }}</p>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-auto">
          <button type="button" disabled class="btn rounded-pill btn-icon btn-success">
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </button>
        </div>

        <div class="col-md-auto">
          <button type="button" disabled class="btn rounded-pill btn-icon btn-danger">
            <i class="ti ti-phone-x"></i>
          </button>
        </div>
      </div>
    </div>
    
    <div v-else>
      <!-- ONGOING / ENDED CALL -->
      <div class="call_action_box m-0">
        <div class="call_process_wrap">
          <h2>
            {{ vData.customer_name }}
          </h2>
          <h3>
            {{ (vData.subIoType || '').replace('_', ' ') }}
          </h3>
          <p>
            {{ vData.phone_no }}
          </p>

          <h4 v-if="UASessionCallStatus === 'RINGING'" class="text-danger">
            <b class="d-block mb-1">RINGING</b>
            {{ tempTimer }}
          </h4>
          <h4 v-else-if="UASessionCallStatus === 'ONGOING'" class="text-success">
            <b class="d-block mb-1">ONGOING</b>
            {{ tempTimer }}
          </h4>
          <h4 v-else class="text-primary">
            <b class="d-block mb-1 text-danger">ENDED</b>
            {{ finalTimer }}
          </h4>
        </div>

        <div v-if="showButtonRinging" 
          class="mt-3 row align-items-center justify-content-md-center">
          <div class="col-md-auto">
            <div class="wrap_act">
              <button @click="endCall()"
                type="button" class="btn rounded-pill btn-icon btn-sm btn-danger mb-2 mb-md-0">
                <i class="ti ti-phone-x"></i>
              </button>
              <p>Cancel</p>
            </div>
          </div>
        </div>

        <div v-else-if="showButtonOngoing" 
          class="mt-3 row align-items-center justify-content-md-center">
          <div class="col-md-auto">
            <div class="wrap_act">
              <button @click="holdCall()"
                type="button" :class="{
                  'btn rounded-pill btn-icon btn-sm mb-2 mb-md-0': true,
                  'btn-outline-warning': !UASessionData.feature.onHold,
                  'btn-warning': UASessionData.feature.onHold
                }">
                <i :class="{
                  'ti ti-player-pause': !UASessionData.feature.onHold,
                  'ti ti-player-play': UASessionData.feature.onHold
                }"></i>
              </button>
              <p>{{ UASessionData.feature.onHold ? 'Unhold' : 'Hold' }}</p>
            </div>
          </div>
          <div class="col-md-auto">
            <div class="wrap_act">
              <button @click="endCall()"
                type="button" class="btn rounded-pill btn-icon btn-sm btn-danger mb-2 mb-md-0">
                <!-- <i class="ti ti-phone-check"></i> -->
                <i class="ti ti-phone-x"></i>
              </button>
              <p>End</p>
            </div>
          </div>
          <div class="col-md-auto">
            <div class="wrap_act">
              <button @click="muteCall()"
                type="button" :class="{
                  'btn rounded-pill btn-icon btn-sm mb-2 mb-md-0': true,
                  'btn-outline-warning': !UASessionData.feature.onMute,
                  'btn-warning': UASessionData.feature.onMute
                }">
                <i :class="{
                  'ti ti-microphone-off': true
                }"></i>
              </button>
              <p>{{ UASessionData.feature.onMute ? 'Unmute' : 'Mute' }}</p>
            </div>
          </div>
        </div>

        <div v-else-if="isReadyCallResult === true" 
          class="mt-3 row align-items-center justify-content-md-center">
          <div class="col-md-auto">
            <button @click="formConf.viewCallResult = true"
              type="button" class="btn btn-rounded btn-primary btn-sm btn-icon-text mb-2 mb-md-0">
              <i class="ti ti-edit"></i> 
              <span class="ms-1">Add Call Result</span> 
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</b-modal>
<!-- END :: ACTIVE SESSION -->

<!-- ADD RESULT -->
<PanelAddResult 
  v-model:open="formConf.viewCallResult" 
  :data="vData"
  />
<!-- END :: ADD RESULT -->
</template>

<script setup>
import { defineProps, computed, watch, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { moment } from '@/libs/vFormat.js'
import { Gen } from '@/libs/Gen.js'

import PanelAddResult from '@/components/VVoip/PanelAddResult.vue'

// initiate
const store = useStore()
const route = useRoute()
const router = useRouter()
const props = defineProps({
  panel: String, // LOAN_DETAIL / FLYING_PANEL / ???
  vIdLoan: {
    type: Number,
    default: 0
  }
})

// variables : computed
const vData = computed(() => {
  if (isReadyCallResult.value) {
    return Gen.reParsingJson(dataCallResult.value)
  } else {
    return {
      ...Gen.reParsingJson(UASessionDataCall.value),
      ...Gen.reParsingJson(UASessionData.value)
    }
  }
})

const isReadyCallResult = computed(() => {
  return store.getters['voip/isReadyCallResult']
})

const dataCallResult = computed(() => {
  return store.getters['voip/dataCallResult']
})

const UASessionStatus = computed(() => {
  return store.getters['voip/UASessionStatus']
})

const UASessionCallStatus = computed(() => {
  return store.getters['voip/UASessionCallStatus']
})

const UASessionData = computed(() => {
  return store.getters['voip/UASessionData']
})

const UASessionDataCall = computed(() => {
  return store.getters['voip/UASessionDataCall']
})

const hasActiveSession = computed(() => {
  return store.getters['voip/hasActiveSessionInbound']
})

const showPanel = computed(() => {
  return (
    hasActiveSession.value &&
    vData.value.type === 'CALLING' &&
    vData.value.ioType === 'INBOUND' &&
    (
      UASessionData.value.tabId === window.currentTabID || // u/ tab yg inisiasi call saja
      isReadyCallResult.value
    ) && 
    (
      props.vIdLoan === vData.value.id_loan ||
      props.vIdLoan === 0
    )
  )
})

const showPanelLoanDetail = computed(() => {
  return (
    showPanel.value &&
    vData.value.subIoType === 'INBOUND_PREDICTIVE' &&
    route.name === 'BoAgentCampaignLoan' &&
    parseInt(route.params.pageId || '0') === parseInt(vData.value.id_loan || '0')
  )
})

const showButtonRinging = computed(() => {
  return (
    isReadyCallResult.value === false && 
    UASessionCallStatus.value === 'RINGING'
  )
})

const showButtonOngoing = computed(() => {
  return (
    isReadyCallResult.value === false && 
    UASessionCallStatus.value === 'ONGOING'
  )
})

// variables : timer
const tempTimer = ref('00:00:00')

const finalTimer = computed(() => {
  if (showPanel.value) {
    if (vData.value.rtc_ongoing_start_at && vData.value.rtc_ongoing_finish_at) {
      const c = vData.value.rtc_ongoing_start_at
      const d = vData.value.rtc_ongoing_finish_at
      const e = moment.duration(moment(d).diff(c))
      return (
        String(e.hours()).padStart(2, '0') + ':' + 
        String(e.minutes()).padStart(2, '0') + ':' + 
        String(e.seconds()).padStart(2, '0')
      )
    } else {
      const c = vData.value.rtc_connecting_start_at
      const d = vData.value.rtc_connecting_finish_at
      const e = moment.duration(moment(d).diff(c))
      return (
        String(e.hours()).padStart(2, '0') + ':' + 
        String(e.minutes()).padStart(2, '0') + ':' + 
        String(e.seconds()).padStart(2, '0')
      )
    }
  } else {
    return null
  }
})

// variables : forms
const formConf = reactive({
  viewCallResult: false
})

// methods
function initialInivite () {
  if (window.voipInterval) {
    clearInterval(window.voipInterval)
  }

  tempTimer.value = '00:00:00'

  window.voipInterval = setInterval(() => {
    if (showPanel.value) {
      const c = UASessionDataCall.value.rtc_connecting_start_at
      const d = moment.duration(moment().diff(c))
      tempTimer.value = (
        String(d.hours()).padStart(2, '0') + ':' + 
        String(d.minutes()).padStart(2, '0') + ':' + 
        String(d.seconds()).padStart(2, '0')
      )
    }
  }, 1 * 1000) // every seconds
}

function initialOngoing () {
  if (window.voipInterval) {
    clearInterval(window.voipInterval)
  }

  tempTimer.value = '00:00:00'

  window.voipInterval = setInterval(() => {
    if (showPanel.value) {
      const c = UASessionDataCall.value.rtc_ongoing_start_at
      const d = moment.duration(moment().diff(c))
      tempTimer.value = (
        String(d.hours()).padStart(2, '0') + ':' + 
        String(d.minutes()).padStart(2, '0') + ':' + 
        String(d.seconds()).padStart(2, '0')
      )
    }
  }, 1 * 1000) // every seconds
}

function answerCall () {
  store.dispatch('voip/answerIncomingCall')
}

function rejectCall () {
  store.dispatch('voip/endIncomingCall')
}

function endCall () {
  store.dispatch('voip/endIncomingCall')
}

function holdCall () {
  store.dispatch('voip/holdIncomingCall')
}

function muteCall () {
  store.dispatch('voip/muteIncomingCall')
}

// watch computed
watch(UASessionStatus, (newValue, oldValue) => {
  if (oldValue === 'READY' && newValue === 'BUSY' && showPanel.value) {
    initialInivite()
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 500)
  }
})

watch(UASessionCallStatus, (newValue, oldValue) => {
  if (oldValue === 'RINGING' && newValue === 'ONGOING' && showPanel.value) {
    initialOngoing()
  }
})

watch(showPanel, open => {
  if (open) {
    if (vData.value.subIoType === 'INBOUND_PREDICTIVE' && !showPanelLoanDetail.value) {
      router.push({
        name: 'BoAgentCampaignLoan',
        params: {
          pageId: vData.value.id_loan,
          pageSlug: 'detail'
        }
      })
    }
  }
})
</script>
