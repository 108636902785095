import { nextTick } from 'vue'
// import { useRouter } from 'vue-router'
import { UserAgent, Registerer, RegistererState, Inviter, SessionState } from 'sip.js'
import { Wording, Gen } from '@/libs/Gen.js'
import { moment } from '@/libs/moment.js'
import Recorder from 'recorder-js'
import _ from '@/libs/lodash.js'

const restApi = 'BoCampaignFollowUp'
const urlWebSocket = process.env.VUE_APP_WEB_SOCKET_VOIP_URL
const urlTransportWebSocket = process.env.VUE_APP_WEB_SOCKET_VOIP_TRANSPORT_URL
// const router = useRouter()

window.UASession = null

const listMonitoringExtentions = {
  LISTEN: '222',
  WHISPER: '223',
  BARGE_IN: '224'
}

const listSwitchMonitoringCode = {
  LISTEN: '4',
  WHISPER: '5',
  BARGE_IN: '6'
}

// eslint-disable-next-line no-unused-vars
function isRegisteredOnTabs (tabId) {
  const dataTabs = Gen.getStorage('Tabs', [])
  const isExists = _.find(dataTabs, { tab: tabId })
  // eslint-disable-next-line no-unneeded-ternary
  return isExists ? true : false
}

const moduleVoip = {
  namespaced: true,
  state: {
    isBypass: false, // // WARNING **** BYPASS | set to false jk sudah ada VOIP
    UA: null,
    UASession: null,
    UASessionData: null,
    dataCallResult: null,
    openCallResult: false,
    onCallMonitoring: null,
    micDevices: [],
    speakerDevices: [],
    progressiveQueue: {}, // tdk usah di taro di storage
    triggerUpdateProgressiveQueue: {},
    predictiveQueue: {}, // DITARO di taro di storage
    readyAgentsPredictive: [] // dipakai ketika predictive saja
  },
  getters: {
    UAStatus: (state) => {
      if (!state.UA) {
        return 'NOT_YET_REGISTER'
      } else if (state.UA.isRegistered()) {
        return 'CONNECTED'
      } else if (
        state.UA.on_connecting === true ||
        state.UA.on_registering === true
      ) {
        return 'CONNECTING'
      } else {
        return 'FAILED_TO_CONNECT'
      }
    },
    UACurrentMessage: (state) => {
      return state.UA.current_message || '-'
    },
    UAIsReady: (_, getters) => {
      return getters.UAStatus === 'CONNECTED'
    },
    UASessionData: (state) => {
      return state.UASessionData || {}
    },
    UASessionDataCall: (state) => {
      return (state.UASessionData || {}).data || {}
    },
    UASessionStatus: (state, getters) => {
      if (state.UASession || getters.UASessionData.tabId) {
        return 'BUSY'
      } else if (getters.UAIsReady) {
        return 'READY' // tdk ada session yg jalan (ready to dipake)
      } else {
        return 'NOT_READY'
      }
    },
    UASessionCallStatus: (state, getters) => {
      if (getters.UASessionStatus === 'BUSY' && state.UASessionData) {
        return state.UASessionData.statusCall
      } else {
        return false
      }
    },
    dataCallResult: (state) => {
      return state.dataCallResult || {}
    },
    isReadyCallResult: (state) => {
      if (state.dataCallResult) {
        return true
      } else {
        return false
      }
    },
    userCredential: (a, b, c, rootGetters) => {
      const creds = rootGetters.BoUser.voip_credentials || {}

      if (creds.extention && creds.username && creds.password) {
        return {
          ...creds,
          id: rootGetters.BoUser.id,
          full_name: rootGetters.BoUser.full_name,
          level_type: rootGetters.BoUser.level_type,
          io_type_agent: rootGetters.BoUser.io_type_agent,
          id_spv: rootGetters.BoUser.id_spv,
          id_manager: rootGetters.BoUser.id_manager
        }
      } else {
        return false
      }
    },
    agentIsReady: (a, b, rootState, rootGetters) => {
      return (
        rootGetters.isUserAgent && 
        rootState.currentAgentStatus === 'READY'
      )
    },
    userIsReady: (a, getters, rootState, rootGetters) => {
      return (
        rootGetters.isUserAgent === false && 
        getters.UASessionStatus === 'READY'
      )
    },
    hasMicDevice: (state) => {
      return state.micDevices.length >= 1
    },
    hasSpeakerDevice: (state) => {
      return state.speakerDevices.length >= 1
    },
    hasProgressiveQueue: (state) => {
      return (state.progressiveQueue || {}).id
    },
    hasPredictiveQueue: (state) => {
      return (state.predictiveQueue || {}).id
    },
    isCurrentTabPredictive: (state, getters) => {
      if (getters.hasPredictiveQueue) {
        return isRegisteredOnTabs(state.predictiveQueue.browser_tab_id)
      } else {
        return false
      }
    },
    hasReadyAgent: (state) => {
      return state.readyAgentsPredictive.length > 0
    },
    hasActiveSessionOutbound: (state) => {
      if (state.UASessionData && window.UASession) {
        return state.UASessionData.ioType === 'OUTBOUND'
      } else {
        return false
      }
    },
    hasInbound: (state) => {
      if (state.UASessionData || state.dataCallResult) {
        return (state.UASessionData || state.dataCallResult).ioType === 'INBOUND'
      } else {
        return false
      }
    },
    hasActiveSessionInbound: (state) => {
      if (state.UASessionData && window.UASession) {
        return state.UASessionData.ioType === 'INBOUND'
      } else {
        return false
      }
    },
    typeInbound: (state, getters) => {
      if (getters.hasInbound) {
        return (state.UASessionData || state.dataCallResult).subIoType
      } else {
        return false
      }
    }
  },
  mutations: {
    initial (state, { x, getters, rootGetters }) {
      // UASession = null
      // state.UASessionData = null
      // state.dataCallResult = null

      // Checking & Fixing status before hard reload
      this.dispatch('voip/initialBeforeHardClose')

      setTimeout(() => {
        this.dispatch('voip/initialBeforeHardClose')
      }, 5 * 1000)

      // Register connection
      /* if (state.isBypass) { 
        // WARNING **** BYPASS
        state.UA = {
          isRegistered: () => {
            return true
          }
        }
      } */

      const userCredential = getters.userCredential

      const isReadyRegister = (
        (
          [
            'NOT_YET_REGISTER',
            'FAILED_TO_CONNECT'
          ].indexOf(getters.UAStatus) > -1 ||
          x.resetInitial
        ) &&
        !!userCredential
      )

      if (isReadyRegister) {
        // start register
        const credsExtention = userCredential.extention
        // const credsUsername = userCredential.username
        const credsPassword = userCredential.password

        const maxReconnectTransport = 3 // bisa diubah
        const timeoutReconnectTransport = 15 // bisa diubah

        // (1) Create agent uri
        let uriAgent = 'sip:' + credsExtention + '@' + urlWebSocket

        uriAgent = UserAgent.makeURI(uriAgent)
        if (!uriAgent) {
          state.UA.current_message = 'Failed to create target URI.'
          state.UA.error_message = state.UA.current_message
          throw new Error(state.UA.current_message)
        }

        // (2) Create user agent instance (caller)
        state.UA = new UserAgent({
          uri: uriAgent,
          transportOptions: {
            server: urlTransportWebSocket,
            traceSip: true,
            connectionTimeout: 15
          },
          logBuiltinEnabled: false, // set true to start debug
          authorizationUsername: credsExtention,
          authorizationPassword: credsPassword,
          autoStart: false,
          autoStop: true,
          register: false,
          noAnswerTimeout: 120,
          delegate: {
            onInvite: (sip) => {
              /**
               * Setup handling for incoming INVITE requests
               */
              this.dispatch('voip/initiateIncomingCall', { sip: sip })
            }
          }
        })

        state.UA.error_message = null
        state.UA.current_message = null

        state.UA.on_connecting = true
        state.UA.on_registering = false
        state.UA.register_complete = false

        state.UA.isRegistered = () => { // set functions
          return (
            state.UA &&
            state.UA.registerer &&
            state.UA.registerer.state === RegistererState.Registered
          )
        }

        state.UA.sessions = state.UA._sessions
        state.UA.transport.ReconnectionAttempts = maxReconnectTransport
        state.UA.transport.attemptingReconnection = false

        // create sub functions
        const startReconnectTransport = () => {
          if (
            state.UA.transport.ReconnectionAttempts <= 0 ||
            state.UA.transport.attemptingReconnection ||
            !state.UA
          ) {
            state.UA.on_connecting = false
            return false
          }

          state.UA.transport.attemptingReconnection = true

          window.setTimeout(() => {
            state.UA.current_message = 'ReConnecting to WebSocket...'
            console.log(state.UA.current_message)

            state.UA.reconnect().then(() => {
              state.UA.current_message = 'Reconnected to Web Socket!'
              console.log(state.UA.current_message)
              state.UA.isReRegister = false

              state.UA.transport.ReconnectionAttempts = maxReconnectTransport
              state.UA.transport.attemptingReconnection = false

              onTransportSuccess() // call
              onRegisterSuccess() // call

            }).catch((error) => {
              state.UA.transport.attemptingReconnection = false
              state.UA.current_message = 'Failed to reconnect'
              console.warn(state.UA.current_message, error)
              onTransportError(error)
            })
          }, timeoutReconnectTransport * 1000)

          state.UA.current_message = 'Waiting to Re-connect...'
          console.log(state.UA.current_message + timeoutReconnectTransport)
          console.log('Attempt remaining', state.UA.transport.ReconnectionAttempts)

          state.UA.transport.ReconnectionAttempts = state.UA.transport.ReconnectionAttempts - 1
        }

        const onTransportSuccess = () => {
          state.UA.current_message = 'Connect to Web Socket'
          console.log(state.UA.current_message)

          state.UA.transport.ReconnectionAttempts = maxReconnectTransport

          if (
            state.UA.transport.attemptingReconnection === false && 
            state.UA.on_registering === false
          ) {
            window.setTimeout(() => {
              startRegister()
            }, 500)
          }
        }

        const onTransportError = (error) => {
          state.UA.current_message = 'WebSocket Connection Failed'
          console.warn(state.UA.current_message, error)

          state.UA.isReRegister = false

          startReconnectTransport()
        }

        const onTransportDisconnected = () => {
          state.UA.current_message = 'Disconnected from Web Socket'
          console.log(state.UA.current_message)

          state.UA.isReRegister = false
          state.UA.on_connecting = false
        }

        const startRegister = () => {
          if (
            !state.UA || 
            state.UA.on_registering === true || 
            state.UA.isRegistered()
          ) {
            return false
          }

          state.UA.current_message = 'Sending Registration...'
          console.log(state.UA.current_message)

          state.UA.on_registering = true
          
          state.UA.registerer.register({
            requestDelegate: {
              onReject: (sip) => {
                const response = sip.message.reasonPhrase
                const cause = sip.message.statusCode

                state.UA.current_message = 'Registration Failed cause: ' + cause
                console.log(state.UA.current_message)

                window.Swal.fire(
                  Wording.voip.ua_register_failed.heading,
                  Gen.strVar(Wording.voip.ua_register_failed.html, {
                    reason: JSON.stringify(response), 
                    status_code: cause 
                  }),
                  'warning'
                )

                state.UA.on_registering = false
                state.UA.on_connecting = false
              }
            }
          })
        }

        const onRegisterSuccess = () => {
          state.UA.register_complete = true

          if (state.UA.isReRegister) {
            state.UA.current_message = 'ReRegistered'
            console.log(state.UA.current_message)

            state.UA.on_registering = false
          } else {
            state.UA.current_message = 'Registered'
            console.log(state.UA.current_message)

            state.UA.on_registering = false
          }

          state.UA.isReRegister = true
          state.UA.on_connecting = false
        }

        // continue
        state.UA.transport.onConnect = () => {
          onTransportSuccess()
        }

        state.UA.transport.onDisconnect = (error) => {
          if (error) {
            onTransportError(error)
          } else {
            onTransportDisconnected()
          }
        }

        //
        state.UA.registerer = new Registerer(state.UA, {
          expires: 300
        })

        state.UA.current_message = 'Creating Registerer...'
        console.log(state.UA.current_message)

        state.UA.registerer.stateChange.addListener((newState) => {
          console.log('User Agent Registration State:', newState)

          switch (newState) {
            case RegistererState.Initial:
              // Nothing to do
              break
            case RegistererState.Registered:
              onRegisterSuccess()
              break
            case RegistererState.Terminated:
              // Nothing to do
              break
          }
        })

        state.UA.current_message = 'User Agent Connecting to WebSocket'
        console.log(state.UA.current_message)

        //
        state.UA.start().catch((error) => {
          onTransportError(error)
        })
      }
    },

    initialBeforeHardClose (state, { getters, rootGetters }) {
      if (getters.UASessionStatus === 'BUSY') {
        if (
          isRegisteredOnTabs(getters.UASessionData.tabId) === false &&
          (
            getters.UASessionData.statusAddResult === 'ON_PROCESS' ||
            getters.UASessionData.statusAddResult === 'NOT_USED'
          )
        ) {
          const isDifferentUser = parseInt(getters.UASessionDataCall.created_by) !== parseInt(getters.userCredential.id)
          const isMonitoringCall = getters.UASessionData.type === 'MONITORING'

          if (isDifferentUser || isMonitoringCall) {
            // different user or is monitoring - clear all session
            window.UASession = null
            state.UASession = null
            state.UASessionData = null
            state.openCallResult = false
            state.dataCallResult = null
            state.onCallMonitoring = null

          } else {
            // same user - continue to create add result
            Gen.loadingGlobal()

            if (
              getters.UASessionData.optAction.isRejectBeforeConnected === false &&
              getters.UASessionData.optAction.isRejectAfterConnected === false) {
              state.UASessionData.optAction.isRejectAfterConnected = true
            }
            state.UASessionData.optAction.rejectByThem = false
            state.UASessionData.optAction.rejectByUs = true

            state.UASessionData.reasonCode = 101
            state.UASessionData.reasonText = 'HARD_RELOAD_FROM_OUR_APP'

            // open
            this.dispatch('voip/dropSessionOutgoingCall', 'NOT_NORMAL')
          }
        }
      } else if (getters.isReadyCallResult) {
        this.dispatch('voip/openCallResult')
      } else if (window.pendingFormResultFromAuth) {
        this.commit('voip/initiateCallResult', 'PENDING_FORM_RESULT_AUTH')
      }

      if (window.pendingProgressiveQueue) {
        const dtVal = window.pendingProgressiveQueue
        if (isRegisteredOnTabs(dtVal.browser_tab_id) === false) {
          // force quit progressive queueu who already REMOVED
          Gen.apiRest(
            '/crud/do/BoProgressiveCall/force-quit-ongoing-process',
            {
              method: 'POST',
              data: {
                id_job: dtVal.id
              }
            }
          )
            .catch(() => {})
        }
      }

      if (window.pendingPredictiveQueue) {
        const dtVal = window.pendingPredictiveQueue
        if (isRegisteredOnTabs(dtVal.browser_tab_id) === false) {
          // force quit predictive queueu who already REMOVED
          Gen.apiRest(
            '/crud/do/BoPredictiveCall/force-quit-ongoing-process',
            {
              method: 'POST',
              data: {
                id_job: dtVal.id
              }
            }
          )
            .catch(() => {})

          state.predictiveQueue = {}
        }
      }/*  else if (state.predictiveQueue.id) {
        const dtVal = state.predictiveQueue
        if (isRegisteredOnTabs(dtVal.browser_tab_id) === false) {
          // force quit predictive queueu who already REMOVED
          console.log('ladklakdladk')
          Gen.apiRest(
            '/crud/do/BoPredictiveCall/force-quit-ongoing-process',
            {
              method: 'POST',
              data: {
                id_job: dtVal.id
              }
            }
          )
            .catch(() => { })

          state.predictiveQueue = {}
        }
      } */
    },

    initialDevice (state) {
      const initialDevice = () => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          // devices will not have a populated lable unless to accept the permission
          // during getUserMedia. This normally happens at startup/setup
          // so from then on these devices will be with lables.
          for (let i = 0; i < devices.length; ++i) {
            if (devices[i].kind === 'audioinput') {
              state.micDevices.push(devices[i])
            } else if (devices[i].kind === 'audiooutput') {
              state.speakerDevices.push(devices[i])
            }
          }
        }).catch((e) => {
          window.Swal(Wording.voip.something_wrong_with_audio)
        })
      }

      //
      initialDevice()

      setTimeout(() => {
        if (state.micDevices.length === 0 || state.speakerDevices.length === 0) {
          initialDevice()
        }
      }, 5000)
    },

    // INBOUND //
    initiateIncomingCall (state, { payload, getters, rootGetters }) {
      // manage incoming call
      console.log('sip', payload)

      let IncomingSession, callerName, callerNumber, referredData
      
      // WARNING **** BYPASS
      if (state.isBypass) {
        // WARNING **** BYPASS
        IncomingSession = {
          data: {}
        }
        callerName = payload.callerName
        callerNumber = payload.callerNumber
        referredData = payload.referredData

      } else {
        IncomingSession = payload.sip

        callerName = IncomingSession.remoteIdentity.displayName
        callerNumber = IncomingSession.remoteIdentity.uri.user
        if (typeof callerName === 'undefined') callerName = callerNumber

        console.log('IncomingSession.remoteIdentity', IncomingSession.remoteIdentity)
        console.log('IncomingSession', IncomingSession)

        referredData = JSON.parse(IncomingSession.request.getHeader('Referred-By') || '{}')
      }

      const isPredictiveCall = referredData.SOURCE_DATA === 'TRANSFER_PREDICTIVE'
      let callId

      if (isPredictiveCall === false) {
        // get Call_ID INBOUND_BIASA
        callId = IncomingSession.request.getHeader('X-Call-Id')
        console.log('callId', callId)
      }

      // initiate session first
      window.UASession = IncomingSession

      // ring the call
      window.AudioIncomingCall = document.createElement('audio')
      window.AudioIncomingCall.id = 'AudioIncomingCall'
      window.AudioIncomingCall.src = require('@/@assets/audio/incoming-call-audio.wav')
      window.AudioIncomingCall.preload = 'auto'
      window.AudioIncomingCall.hidden = true
      window.AudioIncomingCall.autoplay = true
      window.AudioIncomingCall.loop = true
      window.AudioIncomingCall.controls = true

      window.AudioIncomingCall.addEventListener('ended', function () {
        console.log('audio stop')
        // this.play()
      }, false)

      /* // WARNING **** BYPASS
      if (state.isBypass) {
        // WARNING **** BYPASS
        console.log(require('@/@assets/audio/incoming-call-audio.wav'))
        document.body.addEventListener('mousemove', () => {
          window.AudioIncomingCall.play()
        })
      } */

      // update : agent
      this.dispatch('updateCurrentAgentStatus', {
        status: 'BUSY',
        activity: {
          source: 'INCOMING_CALL'
        }
      })

      //
      if (isPredictiveCall) {
        // INBOUND_PREDICTIVE
        Gen.apiRest(
          '/crud/do/' + restApi + '/initiate-incoming-call',
          {
            method: 'POST',
            data: {
              source: 'INBOUND_PREDICTIVE',
              logged_in_as_agent: getters.userCredential.id,
              referred_data: referredData
            }
          }
        )
          .then(resp => {
            // initiate
            this.dispatch('voip/startIncomingCall', {
              source: 'INBOUND_PREDICTIVE',
              idCall: resp.data.response.id_call,
              callerNumber: resp.data.response.destination_number,
              callerName: resp.data.response.customer_name,
              dataCall: resp.data.response
            })
          })
          .catch(err => {
            err = err.response
            if (err) {
              window.Swal.fire({
                ...Wording.errMessage.swalGen,
                confirmButtonText: 'OK',
                html: err.data.message
              })
            }

            this.dispatch('voip/endIncomingCall', {
              type: 'CATCH_INITIATE_INCOMING_CALL'
            })
          })

      } else {
        // INBOUND_BIASA
        Gen.apiRest(
          '/crud/do/' + restApi + '/initiate-incoming-call',
          {
            method: 'POST',
            data: {
              source: 'INBOUND_BIASA',
              logged_in_as_agent: getters.userCredential.id,
              receiver_extention_as_agent: getters.userCredential.extention,
              call_id: callId,
              referred_data: {
                CALLER_NUMBER: callerNumber,
                CALLER_NAME: callerName
              }
            }
          }
        )
          .then(resp => {
            this.dispatch('voip/startIncomingCall', {
              source: 'INBOUND_BIASA',
              idCall: resp.data.response.id_call,
              callerNumber: resp.data.response.destination_number,
              callerName: resp.data.response.customer_name,
              dataCall: resp.data.response
            })
          })
          .catch(err => {
            err = err.response
            if (err) {
              window.Swal.fire({
                ...Wording.errMessage.swalGen,
                confirmButtonText: 'OK',
                html: err.data.message
              })
            }

            this.dispatch('voip/endIncomingCall', {
              type: 'CATCH_INITIATE_INCOMING_CALL'
            })
          })
      }
     
    },

    startIncomingCall (state, { payload, getters }) {
      //
      const destinationNumber = payload.destinationNumber
      const idCall = payload.idCall

      let dataCall = payload.dataCall || {}
      dataCall = {
        ...dataCall,
        io_type: 'INBOUND',
        sub_io_type: payload.source,
        line_type: 'CALL',
        id_call: idCall,
        destination_number: destinationNumber,
        caller_number: null, // default undefined - will be get after CRON,
        calling_method: dataCall.calling_method,
        request_filename_recording: dataCall.request_filename_recording,

        api_register_start_at: dataCall.api_register_start_at,
        api_register_finish_at: dataCall.api_register_finish_at,

        rtc_connecting_start_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'), // with miliseconds
        rtc_connecting_finish_at: null, // default - wil be replace after THEN
        rtc_connecting_is_failed: 'D',
        rtc_connecting_failed_reason: {}
      }

      //
      window.UASession.data = {
        type: 'INBOUND',
        subType: payload.source,
        AudioSourceTrack: null, // by webrtc system
        earlyMedia: null, // by webrtc system
        rinngerObj: null, // by webrtc system
        recorder: null // by webrtc system
      }

      // Configure DATA_CALL
      state.UASessionData = {
        type: 'CALLING',
        tabId: window.currentTabID,
        ioType: 'INBOUND',
        subIoType: payload.source,
        idCall: dataCall.id_call,
        callingMethod: dataCall.calling_method,
        destinationNumber: dataCall.destination_number,

        data: {},
        optAction: {},
        feature: {},

        statusConnection: null,
        statusCall: null,
        reasonCode: null,
        reasonText: null,
        statusAddResult: null,
        reasonAddResult: 'NORMAL' // NORMAL | NOT_NORMAL (session nya di force quit sebelumnya)
      }
      state.UASessionData.data = dataCall
      state.UASessionData.feature = {
        onMute: false,
        onHold: false
      }
      state.UASessionData.optAction = {
        failedToConnect: false,
        isRejectBeforeConnected: false,
        isRejectAfterConnected: false,
        rejectByThem: false,
        rejectByUs: false
      }

      state.UASessionData.statusConnection = 'CONNECTING' // **
      state.UASessionData.statusCall = 'RINGING' // **
      state.UASessionData.statusAddResult = 'ON_PROCESS'

      // configure SESSION
      window.UASession.delegate = {
        onBye: (sip) => {
          /**
            * Ongoing call is Ended secara normal setelah ongoing call
            */
          this.commit('voip/updateSessionData', {
            idCall: idCall,
            statusConnection: 'CONNECTED',
            statusCall: 'CLOSED',
            data: {
              rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
            },
            optAction: {
              isRejectBeforeConnected: false,
              isRejectAfterConnected: true,
              rejectByThem: true,
              rejectByUs: false
            },
            reasonCode: 16,
            reasonText: 'Normal Call clearing'
          })

          this.dispatch('voip/dropSessionIncomingCall')
        },
        onSessionDescriptionHandler: (sdh, provisional) => {
          if (sdh) {
            if (sdh.peerConnection) {
              sdh.peerConnection.ontrack = (event) => {
                this.commit('voip/onTrackAddedEvent')
              }
            } else {
              console.warn('onSessionDescriptionHandler fired without a peerConnection')
            }
          } else {
            console.warn('onSessionDescriptionHandler fired without a sessionDescriptionHandler')
          }
        }
      }

      window.UASession.incomingInviteRequest.delegate = {
        onCancel: (sip) => {
          // call di akhiri oleh sistem PBX
          // call belum tersambung
          // khusus yg INBOUND_BIASA tiketnya gausah diinput
          console.log('Call canceled by remote party before answer')

          window.UASession.dispose().catch(function (error) {
            console.log('Failed to dispose the cancel dialog', error)
          })

          this.commit('voip/updateSessionData', {
            idCall: idCall,
            statusConnection: 'FAILED_TO_CONNECT',
            statusCall: 'DISCONNECTED',
            data: {
              rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
            },
            optAction: {
              isRejectBeforeConnected: true,
              isRejectAfterConnected: false,
              rejectByThem: true,
              rejectByUs: false
            },
            reasonCode: 0,
            reasonText: 'Call Cancelled'
          })

          this.dispatch('voip/dropSessionIncomingCall')
        }
      }

      //
      nextTick(() => {
        window.Swal.close()
      })
    },

    holdIncomingCall (state, { getters }) {
      this.dispatch('voip/holdOutgoingCall')
    },

    muteIncomingCall (state, { getters }) {
      this.dispatch('voip/muteOutgoingCall')
    },

    answerIncomingCall (state, { payload, getters }) {
      if (!window.UASession) return

      const idCall = getters.UASessionData.idCall
      state.UASessionData.statusCall = 'ACCEPTING'

      // Stop the ringtone
      if (window.AudioIncomingCall) {
        window.AudioIncomingCall.loop = false
        window.AudioIncomingCall = null
      }

      // Check vitals
      if (state.micDevices.length <= 0) {
        this.dispatch('voip/endIncomingCall') // FORCE

        return window.Swal.fire(
          Wording.voip.no_audio_device.heading,
          Wording.voip.no_audio_device.html,
          'info'
        )
      }

      // WARNING **** BYPASS
      /* if (state.isBypass) {
        // WARNING **** BYPASS

        this.commit('voip/updateSessionData', {
          idCall: idCall,
          statusConnection: 'CONNECTED',
          statusCall: 'ONGOING',
          data: {
            rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
            rtc_ongoing_start_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
            rtc_ongoing_finish_at: null
          },
          optAction: {
            isRejectAfterConnected: false,
            isRejectBeforeConnected: false,
            rejectByThem: false,
            rejectByUs: false
          },
          feature: {
            onMute: false,
            onHold: false
          }
        })

        // send the updated status
        if (getters.UASessionDataCall) {
          Gen.apiRest(
            '/crud/do/' + restApi + '/answer-incoming-call',
            {
              method: 'POST',
              data: {
                source: getters.typeInbound,
                id_fu: getters.UASessionDataCall.id_fu
              }
            }
          )
        }

        return false // WARNING **** BYPASS
      } */

      //
      const supportedConstraints = navigator.mediaDevices.getSupportedConstraints()
      const spdOptions = {
        earlyMedia: true,
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: { deviceId: 'default' },
            video: false
          }
        }
      }

      // Add additional Constraints
      if (supportedConstraints.autoGainControl) {
        spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl = 1
      }
      if (supportedConstraints.echoCancellation) {
        spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation = 1
      }
      if (supportedConstraints.noiseSuppression) {
        spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression = 1
      }

      // Save Devices
      window.UASession.data.withvideo = false
      window.UASession.data.VideoSourceDevice = null
      window.UASession.data.AudioSourceDevice = 'default'
      window.UASession.data.AudioOutputDevice = 'default'

      // Send Answer
      window.UASession.accept(spdOptions).then(() => {
        /**
         * Successfully Connect & Call is answered and in ongoing call
         */

        /**
         * CALL IS ACCAPTED BY CUSTOMER
         * SET TO STREAM AUDITO
         */
        this.commit('voip/updateSessionData', {
          idCall: idCall,
          statusConnection: 'CONNECTED',
          statusCall: 'ONGOING',
          data: {
            rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
            rtc_ongoing_start_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
            rtc_ongoing_finish_at: null
          },
          optAction: {
            isRejectAfterConnected: false,
            isRejectBeforeConnected: false,
            rejectByThem: false,
            rejectByUs: false
          },
          feature: {
            onMute: false,
            onHold: false
          }
        })

        if (window.UASession && state.UASessionData) {
          if (window.UASession.data.earlyMedia) {
            window.UASession.data.earlyMedia.pause()
            window.UASession.data.earlyMedia.removeAttribute('src')
            window.UASession.data.earlyMedia.load()
            window.UASession.data.earlyMedia = null
          }
        }

        // send the updated status
        if (getters.UASessionDataCall) {
          Gen.apiRest(
            '/crud/do/' + restApi + '/answer-incoming-call',
            {
              method: 'POST',
              data: {
                source: getters.typeInbound,
                id_fu: getters.UASessionDataCall.id_fu
              }
            }
          )
        }

      }).catch((error) => {
        console.log('accepting error', error)
        
        this.commit('voip/updateSessionData', {
          idCall: idCall,
          statusConnection: 'FAILED_TO_CONNECT',
          statusCall: 'DISCONNECTED',
          data: {
            rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
          },
          optAction: {
            isRejectBeforeConnected: true,
            rejectByThem: true,
            rejectByUs: false
          },
          reasonCode: 500,
          reasonText: 'Failed to answer call: Client Error'
        })

        // send the updated status (auto reject)
        if (getters.UASessionDataCall) {
          Gen.apiRest(
            '/crud/do/' + restApi + '/reject-incoming-call',
            {
              method: 'POST',
              data: {
                source: getters.typeInbound,
                id_fu: getters.UASessionDataCall.id_fu
              }
            }
          )
        }

        nextTick(() => {
          this.dispatch('voip/dropSessionIncomingCall')
        })
      })
    },

    endIncomingCall (state, { payload, getters }) {
      //
      if (payload.type !== 'CATCH_INITIATE_INCOMING_CALL') {
        const idCall = getters.UASessionData.idCall

        if (getters.UASessionCallStatus === 'ONGOING') {
          this.commit('voip/updateSessionData', {
            idCall: idCall,
            statusConnection: 'CONNECTED',
            statusCall: 'CLOSED',
            data: {
              rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
              rtc_ongoing_finish_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS')
            },
            optAction: {
              isRejectBeforeConnected: false,
              isRejectAfterConnected: true,
              rejectByThem: false,
              rejectByUs: true
            },
            reasonCode: 200,
            reasonText: 'Normal End Call by ONGOING'
          })

        } else if (getters.UASessionCallStatus === 'RINGING') {
          this.commit('voip/updateSessionData', {
            idCall: idCall,
            statusConnection: 'CONNECTING',
            statusCall: 'CLOSED',
            data: {
              rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
              rtc_ongoing_start_at: null,
              rtc_ongoing_finish_at: null
            },
            optAction: {
              isRejectBeforeConnected: true,
              isRejectAfterConnected: false,
              rejectByThem: false,
              rejectByUs: true
            },
            reasonCode: 200,
            reasonText: 'Normal End Call by RINGING'
          })

          // send the updated status
          if (getters.UASessionDataCall) {
            Gen.apiRest(
              '/crud/do/' + restApi + '/reject-incoming-call',
              {
                method: 'POST',
                data: {
                  source: getters.typeInbound,
                  id_fu: getters.UASessionDataCall.id_fu
                }
              }
            )
          }
        }
      }

      if (window.UASession) {
        // Stop the ringtone
        if (window.AudioIncomingCall) {
          window.AudioIncomingCall.loop = false
          window.AudioIncomingCall = null
        }

        // reject the call
        try {
          if (window.UASession) {
            window.UASession.bye().catch((e) => {
              console.warn('Problem in RejectCall(), could not bye() call', e, window.UASession)
            })
          }
        } catch (err) {
          console.log('err', err)
        }

        try {
          if (window.UASession) {
            window.UASession.cancel().catch((e) => {
              console.warn('Problem in RejectCall(), could not bye() call', e, window.UASession)
            })
          }
        } catch (err) {
          console.log('err', err)
        }

        try {
          if (window.UASession) {
            window.UASession.reject({
              statusCode: 486,
              reasonPhrase: 'Busy Here'
            }).catch((e) => {
              console.warn('Problem in RejectCall(), could not reject() call', e, window.UASession)
            })
          }
        } catch (err) {
          console.log('err', err)
        }
      }

      nextTick(() => {
        this.dispatch('voip/dropSessionIncomingCall', payload)
      })
    },

    dropSessionIncomingCall (state, { payload, getters }) {
      if (payload.type === 'CATCH_INITIATE_INCOMING_CALL') {
        // drop the session
        window.UASession = null
        state.UASession = null

        // update : agent
        this.dispatch('updateCurrentAgentStatus', {
          status: 'READY'
        })
      } else {
        //
        if (state.UASessionData.teardownComplete === true || !state.UASessionData) return false
        state.UASessionData.teardownComplete = true // Run this code only once

        // Mixed Tracks
        if (window.UASession) {
          // Stop the ringtone
          if (window.AudioIncomingCall) {
            window.AudioIncomingCall.loop = false
            window.AudioIncomingCall = null
          }

          if (
            window.UASession.data.AudioSourceTrack &&
            window.UASession.data.AudioSourceTrack.kind === 'audio'
          ) {
            window.UASession.data.AudioSourceTrack.stop()
            window.UASession.data.AudioSourceTrack = null
          }
          // Stop any Early Media
          if (window.UASession.data.earlyMedia) {
            window.UASession.data.earlyMedia.pause()
            window.UASession.data.earlyMedia.removeAttribute('src')
            window.UASession.data.earlyMedia.load()
            window.UASession.data.earlyMedia = null
          }
          // Stop any ringing calls
          if (window.UASession.data.rinngerObj) {
            window.UASession.data.rinngerObj.pause()
            window.UASession.data.rinngerObj.removeAttribute('src')
            window.UASession.data.rinngerObj.load()
            window.UASession.data.rinngerObj = null
          }

          // Make sure you have released the microphone
          if ((window.UASession.sessionDescriptionHandler || {}).peerConnection) {
            const peerConnection = window.UASession.sessionDescriptionHandler.peerConnection

            peerConnection.getSenders().forEach((RTCRtpSender) => {
              if (RTCRtpSender.track && RTCRtpSender.track.kind === 'audio') {
                RTCRtpSender.track.stop()
              }
            })
          }
        }

        // Update data
        if (
          getters.UASessionDataCall.rtc_ongoing_start_at &&
          !getters.UASessionDataCall.rtc_ongoing_finish_at
        ) {
          state.UASessionData.data.rtc_ongoing_finish_at = moment().format('YYYY-MM-DD HH:mm:ss.SSS')
          state.UASessionData.data.rtc_ongoing_is_failed = 'N'

        }

        if (
          getters.UASessionDataCall.rtc_connecting_start_at &&
          !getters.UASessionDataCall.rtc_connecting_finish_at
        ) {
          state.UASessionData.data.rtc_connecting_finish_at = moment().format('YYYY-MM-DD HH:mm:ss.SSS')
        }

        if (window.UASession) {
          // Stop Recording if exists
          if (window.UASession.data.recorder) {
            window.UASession.data.recorder.stop()
              .then(({ blob, buffer }) => {
                console.log('RECORDING inbound', blob)

                /* try {
                  const reader = new FileReader()
                  reader.readAsDataURL(buffer)
                  reader.onloadend = function () {
                    let base64 = reader.result
                    base64 = base64.split(',')[1]
                    console.log('base64 buffer', base64)
                  }
                } catch (err) {
                  console.log('err', err)
                }

                try {
                  const reader = new FileReader()
                  reader.readAsDataURL(blob)
                  reader.onloadend = function () {
                    let base64 = reader.result
                    base64 = base64.split(',')[1]
                    console.log('base64 blob', base64)
                  }
                } catch (err) {
                  console.log('err', err)
                } */
                // this.AddCallResult(JSON.parse(JSON.stringify(state.UASessionData)), blob)
                // belum // not yet
                // infonya recording bisa diambil dari API
              })
          }
        }

        //
        state.UASessionData.statusAddResult = 'READY'
        state.UASessionData.reasonAddResult = 'NORMAL'

        //
        if (
          getters.typeInbound === 'INBOUND_BIASA' &&
          !state.UASessionData.optAction.isRejectAfterConnected
        ) {
          // INBOUND_BIASA & REJECT_WHEN=BEFORE_CONNECT
          // auto finish = FINISH_IGNORE

          // agent AUTO READY
          // update : agent
          this.dispatch('updateCurrentAgentStatus', {
            status: 'READY'
          })

          // data disimpan di static variable biasa
          // gausah open call result
          const dataCallResult = Gen.reParsingJson({
            ...state.UASessionData.data,
            ...state.UASessionData.optAction,
            type: state.UASessionData.type,
            ioType: state.UASessionData.ioType,
            subIoType: state.UASessionData.subIoType,
            statusConnection: state.UASessionData.statusConnection,
            statusCall: state.UASessionData.statusCall,
            reasonCode: state.UASessionData.reasonCode,
            reasonText: state.UASessionData.reasonText,
            statusAddResult: state.UASessionData.statusAddResult,
            reasonAddResult: state.UASessionData.reasonAddResult,
            rejectWhen: state.UASessionData.optAction.isRejectAfterConnected ? 'AFTER_CONNECTED' : 'BEFORE_CONNECT',
            rejectBy: state.UASessionData.optAction.rejectByUs ? 'US' : 'THEM'
          })

          window.UASession = null // **
          state.UASession = null
          state.UASessionData = null // **

          //
          state.openCallResult = false // * FALSE

          // submit data
          Gen.apiRest(
            '/crud/do/' + restApi + '/finish-ignore-incoming-call',
            {
              method: 'POST',
              data: dataCallResult
            }
          )
            .then(resp => {
              window.Swal.close()
            })

        } else {
          // waiting result kayak biasa
          // reset Session
          state.dataCallResult = Gen.reParsingJson({
            ...state.UASessionData.data,
            ...state.UASessionData.optAction,
            type: state.UASessionData.type,
            ioType: state.UASessionData.ioType,
            subIoType: state.UASessionData.subIoType,
            statusConnection: state.UASessionData.statusConnection,
            statusCall: state.UASessionData.statusCall,
            reasonCode: state.UASessionData.reasonCode,
            reasonText: state.UASessionData.reasonText,
            statusAddResult: state.UASessionData.statusAddResult,
            reasonAddResult: state.UASessionData.reasonAddResult,
            rejectWhen: state.UASessionData.optAction.isRejectAfterConnected ? 'AFTER_CONNECTED' : 'BEFORE_CONNECT',
            rejectBy: state.UASessionData.optAction.rejectByUs ? 'US' : 'THEM'
          })

          Gen.putStorage('dataCallResult', state.dataCallResult)

          window.UASession = null // **
          state.UASession = null
          state.UASessionData = null // **

          // update : agent
          this.dispatch('updateCurrentAgentStatus', {
            status: 'ACW',
            activity: {
              is_exists: 'Y',
              source: 'INCOMING_CALL',
              loan_external_loan_no: state.dataCallResult.external_loan_no,
              loan_customer_name: state.dataCallResult.customer_name,
              client_name: state.dataCallResult.client_name,
              campaign_name: state.dataCallResult.campaign_name
            }
          })

          //
          state.openCallResult = true

          // update waiting result
          if (getters.typeInbound === 'INBOUND_PREDICTIVE') {
            Gen.apiRest(
              '/crud/do/' + restApi + '/waiting-result-outgoing-call',
              {
                method: 'POST',
                data: state.dataCallResult
              }
            )
              .then(resp => {
                window.Swal.close()
              })
          } else if (getters.typeInbound === 'INBOUND_BIASA') {
            Gen.apiRest(
              '/crud/do/' + restApi + '/waiting-result-incoming-call',
              {
                method: 'POST',
                data: state.dataCallResult
              }
            )
              .then(resp => {
                window.Swal.close()
              })
          }
        }
      }
    },
    // END :: INBOUND //

    // OUTBOUND //
    registerOutgoingCall (state, { payload, getters }) {
      if (getters.UAStatus !== 'CONNECTED') {
        return window.Swal.fire(Wording.voip.ua_is_not_connected)
      } else if (getters.UASessionStatus === 'NOT_READY') {
        return window.Swal.fire(Wording.voip.ua_session_is_not_ready)
      } else if (getters.UASessionStatus === 'BUSY') {
        return window.Swal.fire(Wording.voip.ua_session_is_busy)
      } else if (
        getters.agentIsReady === false &&
        getters.userIsReady === false
      ) {
        return window.Swal.fire(Wording.voip.no_user_ready)
      } else if (getters.isReadyCallResult) {
        return window.Swal.fire(Wording.voip.should_fill_call_result_first)
      }

      //
      Gen.loadingGlobal('Register Calling')

      Gen.apiRest(
        '/crud/do/' + restApi + '/register-id-outgoing-call',
        {
          method: 'POST',
          data: payload
        }
      )
        .then(resp => {
          // update : agent
          this.dispatch('updateCurrentAgentStatus', {
            status: 'BUSY',
            activity: {
              is_exists: 'Y',
              loan_external_loan_no: resp.data.response.external_loan_no,
              loan_customer_name: resp.data.response.customer_name,
              client_name: resp.data.response.client_name,
              campaign_name: resp.data.response.campaign_name
            }
          })

          //
          if (['MANUAL', 'PROGRESSIVE'].indexOf(payload.calling_method) > -1) {
            this.dispatch('voip/startOutgoingCall', {
              idCall: resp.data.response.id_call,
              destinationNumber: resp.data.response.destination_number,
              dataCall: resp.data.response
            })
          }
        })
        .catch(err => {
          err = err.response
          if (err) {
            window.Swal.fire({
              ...Wording.errMessage.swalGen,
              confirmButtonText: 'OK',
              html: err.data.message
            })
          }
        })
    },

    startOutgoingCall (state, { payload, getters }) {
      if (getters.UAStatus !== 'CONNECTED') {
        return window.Swal.fire(Wording.voip.ua_is_not_connected)
      } else if (getters.UASessionStatus === 'NOT_READY') {
        return window.Swal.fire(Wording.voip.ua_session_is_not_ready)
      } else if (getters.UASessionStatus === 'BUSY') {
        return window.Swal.fire(Wording.voip.ua_session_is_busy)
      }

      //
      const requestHeaders = payload.requestHeaders || []
      const destinationNumber = payload.destinationNumber
      const idCall = payload.idCall

      let dataCall = payload.dataCall || {}
      dataCall = {
        ...dataCall,
        io_type: 'OUTBOUND',
        line_type: 'CALL',
        id_call: idCall,
        destination_number: destinationNumber,
        caller_number: null, // default undefined - will be get after CRON,
        calling_method: dataCall.calling_method,
        request_filename_recording: dataCall.request_filename_recording,
        
        api_register_start_at: dataCall.api_register_start_at,
        api_register_finish_at: dataCall.api_register_finish_at,
        
        rtc_connecting_start_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'), // with miliseconds
        rtc_connecting_finish_at: null, // default - wil be replace after THEN
        rtc_connecting_is_failed: 'D',
        rtc_connecting_failed_reason: {}
      }

      // WARNING **** BYPASS
      /* if (state.isBypass) {
        // WARNING **** BYPASS
        window.UASession = {}

        window.Swal.close()

        window.UASession.data = {
          type: 'OUTBOUND',
          AudioSourceTrack: null,
          earlyMedia: null,
          rinngerObj: null,
          recorder: null
        }
        state.UASessionData = {
          type: 'CALLING',
          tabId: window.currentTabID,
          ioType: dataCall.io_type,
          idCall: dataCall.id_call,
          callingMethod: dataCall.calling_method,
          destinationNumber: dataCall.destination_number,

          data: {},
          optAction: {},
          feature: {},

          statusConnection: null,
          statusCall: null,
          reasonCode: null,
          reasonText: null,
          statusAddResult: null,
          reasonAddResult: 'NORMAL'
        }
        state.UASessionData.data = dataCall
        state.UASessionData.feature = {
          onMute: false,
          onHold: false
        }
        state.UASessionData.optAction = {
          failedToConnect: false,
          isRejectBeforeConnected: false,
          isRejectAfterConnected: false,
          rejectByThem: false,
          rejectByUs: false
        }

        state.UASessionData.statusConnection = 'CONNECTING' 
        state.UASessionData.statusCall = 'RINGING'
        state.UASessionData.statusAddResult = 'ON_PROCESS'

        // broadcast monitoring call
        this.dispatch('voip/broadcastOutgoingCall', Gen.reParsingJson({
          ...Gen.reParsingJson(getters.UASessionData),
          ...Gen.reParsingJson(getters.UASessionDataCall),
          status_monitoring: 'INSERT'
        }))

        setTimeout(() => {
          // TEST CALL : ACCEPTED
          this.commit('voip/updateSessionData', {
            idCall: idCall,

            data: {
              rtc_connecting_finish_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
              rtc_ongoing_start_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
              rtc_ongoing_finish_at: null
            },

            statusConnection: 'CONNECTED',
            statusCall: 'ONGOING',

            optAction: {
              isRejectAfterConnected: false,
              isRejectBeforeConnected: false,
              rejectByThem: false,
              rejectByUs: false
            },

            feature: {
              onMute: false,
              onHold: false
            }
          })

          // broadcast monitoring call
          this.dispatch('voip/broadcastOutgoingCall', Gen.reParsingJson({
            ...Gen.reParsingJson(getters.UASessionData),
            ...Gen.reParsingJson(getters.UASessionDataCall),
            status_monitoring: 'INSERT'
          }))
        }, 15 * 1000)

        return false // WARNING **** BYPASS
      } */

      //
      const supportedConstraints = navigator.mediaDevices.getSupportedConstraints()
      const spdOptions = {
        // constraints: { audio: true, video: false },
        earlyMedia: true,
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: { deviceId: 'default' },
            video: false
          }
        }
      }

      // Add additional Constraints
      if (supportedConstraints.autoGainControl) {
        spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl = 1
      }
      if (supportedConstraints.echoCancellation) {
        spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation = 1
      }
      if (supportedConstraints.noiseSuppression) {
        spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression = 1
      }

      spdOptions.extraHeaders = [
        ...requestHeaders,
        'X-Call-Id:' + dataCall.id_call,
        'DESTINATION_NUMBER:' + destinationNumber,
        'CUSTOM_ID_CALL:' + dataCall.id_call,
        'CUSTOM_FILENAME_RECORDING:' + dataCall.request_filename_recording
      ] // format: [ 'KEY:VALUE' ]

      // Initiate Session
      const destinationNumberLeadingZero = '0' + destinationNumber.substr(2)
      let targetURI = 'sip:' + destinationNumberLeadingZero + '@' + urlWebSocket
      targetURI = UserAgent.makeURI(targetURI)
      window.UASession = new Inviter(state.UA, targetURI, spdOptions)
      state.UASession = {}

      window.UASession.data = {
        type: 'OUTBOUND',
        AudioSourceTrack: null, // by webrtc system
        earlyMedia: null, // by webrtc system
        rinngerObj: null, // by webrtc system
        recorder: null // by webrtc system
      }

      // Configure DATA_CALL
      state.UASessionData = {
        type: 'CALLING',
        tabId: window.currentTabID,
        ioType: dataCall.io_type,
        idCall: dataCall.id_call,
        callingMethod: dataCall.calling_method,
        destinationNumber: dataCall.destination_number,

        data: {},
        optAction: {},
        feature: {},

        statusConnection: null,
        statusCall: null,
        reasonCode: null,
        reasonText: null,
        statusAddResult: null,
        reasonAddResult: 'NORMAL' // NORMAL | NOT_NORMAL (session nya di force quit sebelumnya)
      }
      state.UASessionData.data = dataCall
      state.UASessionData.feature = {
        onMute: false,
        onHold: false
      }
      state.UASessionData.optAction = {
        failedToConnect: false,
        isRejectBeforeConnected: false,
        isRejectAfterConnected: false,
        rejectByThem: false,
        rejectByUs: false
      }

      state.UASessionData.statusConnection = 'CONNECTING' // **
      /* 
        status: 
          - CONNECTING : dalam proses connect ke server voip
          - CONNECTED : berhasil connect ke server voip
          - FAILED_TO_CONNECT : tidak bisa konek atau putus ditengah
      */

      state.UASessionData.statusCall = 'RINGING' // **
      /* 
        status: 
          - RINGING : sudah berhasil connect & dalam proses reach them
          - ONGOING : sedang mengobrol
          - CLOSED : ditutup secara normal (rejectEarly or not)
          - DISCONNECTED : tiba-tiba disconnect di tengah
      */

      state.UASessionData.statusAddResult = 'ON_PROCESS'
      /*
        status:
          - ON_PROCESS : dalam proses calling
          - READY : ready isi form result
      */

      // broadcast monitoring call
      this.dispatch('voip/broadcastOutgoingCall', Gen.reParsingJson({
        ...Gen.reParsingJson(getters.UASessionData),
        ...Gen.reParsingJson(getters.UASessionDataCall),
        status_monitoring: 'INSERT'
      }))

      // Configure : Feedback Given By Customer ANSWER (accept) or NO_ANSWER (reject)
      const inviterOptions = {
        requestDelegate: {
          onTrying: (sip) => {
            console.log('onTrying', sip)

            this.commit('voip/updateSessionData', {
              idCall: idCall,

              statusConnection: 'CONNECTING', // ??
              statusCall: 'RINGING'
            })
          },
          onProgress: (sip) => {
            console.log('onProgress', sip)

            this.commit('voip/updateSessionData', {
              idCall: idCall,

              statusConnection: 'CONNECTED', // ??
              statusCall: 'RINGING'
            })

            if (sip.message.statusCode === 180) {
              const soundFile = require('@/@assets/audio/incoming-call-audio.wav')

              // Play Early Media
              if (window.UASession.data.earlyMedia) {
                // There is already early media playing
                // onProgress can be called multiple times
                // Don't add it again
                console.log('Early Media already playing')
              } else {
                const earlyMedia = new Audio(soundFile)
                earlyMedia.preload = 'auto'
                earlyMedia.loop = true
                earlyMedia.oncanplaythrough = function (e) {
                  console.log('earlyMedia.sinkId', earlyMedia.sinkId)
                  earlyMedia.play().then(function () {
                    // Audio Is Playing
                    console.log('Audio Is Playing')
                  }).catch(function (e) {
                    console.warn('Unable to play audio file.', e)
                  })
                }

                window.UASession.data.earlyMedia = earlyMedia
                console.log('earlyMedia', earlyMedia)
              }
            }
          },
          onAccept: (sip) => {
            /**
             * Successfully Connect & Call is answered and in ongoing call
             */
            console.log('onAccept', sip)
            
            /**
             * CALL IS ACCAPTED BY CUSTOMER
             * SET TO STREAM AUDITO
             */
            this.commit('voip/updateSessionData', {
              idCall: idCall,
              statusConnection: 'CONNECTED',
              statusCall: 'ONGOING',
              data: {
                rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
                rtc_ongoing_start_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
                rtc_ongoing_finish_at: null
              },
              optAction: {
                isRejectAfterConnected: false,
                isRejectBeforeConnected: false,
                rejectByThem: false,
                rejectByUs: false
              },
              feature: {
                onMute: false,
                onHold: false
              }
            })

            if (window.UASession && state.UASessionData) {
              console.log('window.UASession.data.earlyMedia', window.UASession.data.earlyMedia)
              if (window.UASession.data.earlyMedia) {
                window.UASession.data.earlyMedia.pause()
                window.UASession.data.earlyMedia.removeAttribute('src')
                window.UASession.data.earlyMedia.load()
                window.UASession.data.earlyMedia = null
              }
            }

            // broadcast monitoring call
            this.dispatch('voip/broadcastOutgoingCall', Gen.reParsingJson({
              ...Gen.reParsingJson(getters.UASessionData),
              ...Gen.reParsingJson(getters.UASessionDataCall),
              status_monitoring: 'INSERT'
            }))
          },
          onReject: (response) => {
            /***
             * Failed to connect / Disconnect in the middle / there is something wrong with the status given by the server voip
             */
            console.log('onReject', response)

            this.commit('voip/updateSessionData', {
              idCall: idCall,
              statusConnection: 'FAILED_TO_CONNECT',
              statusCall: 'DISCONNECTED',
              data: {
                rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
              },
              optAction: {
                isRejectBeforeConnected: true,
                rejectByThem: true,
                rejectByUs: false
              },
              reasonCode: response.message.statusCode,
              reasonText: response.message.reasonPhrase
            })
            
            this.dispatch('voip/dropSessionOutgoingCall')
          }
        }
      }

      // Configure : When Call is Connected
      window.UASession.delegate = {
        onBye: (sip) => {
          console.log('onBye', sip)
          /**
            * Ongoing call is Ended (by nya sama siapa ??? belum tau)
            */
          this.commit('voip/updateSessionData', {
            idCall: idCall,
            statusConnection: 'CONNECTED',
            statusCall: 'CLOSED',
            data: {
              rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
            },
            optAction: {
              isRejectAfterConnected: true,
              rejectByThem: true,
              rejectByUs: false
            },
            reasonCode: 16,
            reasonText: 'Normal Call clearing'
          })

          this.dispatch('voip/dropSessionOutgoingCall')

        },
        onSessionDescriptionHandler: (sdh) => {
          console.log('onSessionDescriptionHandler', sdh)
          if (sdh) {
            if (sdh.peerConnection) {
              sdh.peerConnection.ontrack = (event) => {
                this.commit('voip/onTrackAddedEvent')
              }
            } else {
              console.warn('onSessionDescriptionHandler fired without a peerConnection')
            }
          } else {
            console.warn('onSessionDescriptionHandler fired without a sessionDescriptionHandler')
          }
        }
      }

      // FINALLY : make a CALL
      window.UASession.invite(inviterOptions).catch(e => {
        console.warn('Failed to send INVITE:', e)

        if (state.UASessionData) {
          if (state.UASessionData.statusCall === 'RINGING') {
            this.commit('voip/updateSessionData', {
              idCall: idCall,
              statusConnection: 'FAILED_TO_CONNECT',
              statusCall: 'DISCONNECTED',
              data: {
                rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
              },
              optAction: {
                isRejectBeforeConnected: true,
                rejectByThem: true,
                rejectByUs: false
              },
              reasonCode: 500,
              reasonText: 'Failed to send INVITE:' + (e.message.reasonPhrase || '')
            })

            this.dispatch('voip/dropSessionOutgoingCall')
          }
        }
      })

      //
      nextTick(() => {
        window.Swal.close()
      })
    },

    holdOutgoingCall (state, { getters }) {
      if (window.UASession) {
        const toHold = !getters.UASessionData.feature.onHold
        const idCall = getters.UASessionData.idCall

        if (toHold) {
          // to hold
          state.UASessionData.feature.onHold = true

          if (state.isBypass === false) {
            //
            window.UASession.sessionDescriptionHandlerOptionsReInvite = window.UASession.sessionDescriptionHandlerOptionsReInvite.hold = true

            const options = {
              requestDelegate: {
                onAccept: () => {
                  if (
                    window.UASession && 
                    window.UASession.sessionDescriptionHandler && 
                    window.UASession.sessionDescriptionHandler.peerConnection
                  ) {
                    const pc = window.UASession.sessionDescriptionHandler.peerConnection
                    
                    // Stop all the inbound streams
                    pc.getReceivers().forEach((RTCRtpReceiver) => {
                      if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = false
                    })

                    // Stop all the outbound streams (especially usefull for Conference Calls!!)
                    pc.getSenders().forEach((RTCRtpSender) => {
                      // Mute Audio
                      if (RTCRtpSender.track && RTCRtpSender.track.kind === 'audio') {
                        if (RTCRtpSender.track.IsMixedTrack === true) {
                          if (
                            window.UASession.data.AudioSourceTrack && 
                            window.UASession.data.AudioSourceTrack.kind === 'audio'
                          ) {
                            window.UASession.data.AudioSourceTrack.enabled = false
                          }
                        }

                        RTCRtpSender.track.enabled = false
                      }
                    })
                  }

                  this.commit('voip/updateSessionData', {
                    idCall: idCall,
                    feature: {
                      onHold: true
                    }
                  })
                },
                onReject: () => {
                  this.commit('voip/updateSessionData', {
                    idCall: idCall,
                    feature: {
                      onHold: false
                    }
                  })
                }
              }
            }

            window.UASession.invite(options).catch((error) => {
              this.commit('voip/updateSessionData', {
                idCall: idCall,
                feature: {
                  onHold: false
                }
              })
              console.warn('Error attempting to put the call on hold:', error)
            })
          }

        } else {
          // to unhold
          if (state.isBypass === false) {
            window.UASession.sessionDescriptionHandlerOptionsReInvite = window.UASession.sessionDescriptionHandlerOptionsReInvite.hold = false

            const options = {
              requestDelegate: {
                onAccept: () => {
                  if (window.UASession && window.UASession.sessionDescriptionHandler && window.UASession.sessionDescriptionHandler.peerConnection) {
                    const pc = window.UASession.sessionDescriptionHandler.peerConnection

                    // Enable all the inbound streams
                    pc.getReceivers().forEach((RTCRtpReceiver) => {
                      if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = true
                    })

                    // Enable all the outbound streams (especially usefull for Conference Calls!!)
                    pc.getSenders().forEach((RTCRtpSender) => {
                      // Enable Audio
                      if (RTCRtpSender.track && RTCRtpSender.track.kind === 'audio') {
                        if (RTCRtpSender.track.IsMixedTrack === true) {
                          if (window.UASession.data.AudioSourceTrack && window.UASession.data.AudioSourceTrack.kind === 'audio') {
                            window.UASession.data.AudioSourceTrack.enabled = true
                          }
                        }

                        RTCRtpSender.track.enabled = true
                      }
                    })
                  }

                  this.commit('voip/updateSessionData', {
                    idCall: idCall,
                    feature: {
                      onHold: false
                    }
                  })
                },
                onReject: () => {
                  this.commit('voip/updateSessionData', {
                    idCall: idCall,
                    feature: {
                      onHold: true
                    }
                  })
                }
              }
            }

            window.UASession.invite(options).catch((error) => {
              this.commit('voip/updateSessionData', {
                idCall: idCall,
                feature: {
                  onHold: true
                }
              })
              console.warn('Error attempting to put the call on unhold:', error)
            })
          } else {
            state.UASessionData.feature.onHold = false    
          }
        }
      } else if (state.isBypass && state.UASessionData) {
        const toHold = !getters.UASessionData.feature.onHold
        state.UASessionData.feature.onHold = toHold
      }
    },

    muteOutgoingCall (state, { getters }) {
      if (window.UASession) {
        const toMute = !getters.UASessionData.feature.onMute

        if (state.isBypass === false) {
          const pc = window.UASession.sessionDescriptionHandler.peerConnection
          pc.getSenders().forEach((RTCRtpSender) => {
            if (RTCRtpSender.track && RTCRtpSender.track.kind === 'audio') {
              if (RTCRtpSender.track.IsMixedTrack === true) {
                if (
                  window.UASession.data.AudioSourceTrack && 
                  window.UASession.data.AudioSourceTrack.kind === 'audio'
                ) {
                  // eslint-disable-next-line no-unneeded-ternary
                  window.UASession.data.AudioSourceTrack.enabled = toMute ? false : true
                }
              }

              // eslint-disable-next-line no-unneeded-ternary
              RTCRtpSender.track.enabled = toMute ? false : true
            }
          })
        }

        state.UASessionData.feature.onMute = toMute

      } else if (state.isBypass && state.UASessionData) {
        const toMute = !getters.UASessionData.feature.onMute
        state.UASessionData.feature.onMute = toMute
      }
    },

    endOutgoingCall (state, { getters }) {
      const idCall = getters.UASessionData.idCall

      if (getters.UASessionCallStatus === 'ONGOING') {
        this.commit('voip/updateSessionData', {
          idCall: idCall,
          statusConnection: 'CONNECTED',
          statusCall: 'CLOSED',
          data: {
            rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
            rtc_ongoing_finish_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS')
          },
          optAction: {
            isRejectBeforeConnected: false,
            isRejectAfterConnected: true,
            rejectByThem: false,
            rejectByUs: true
          },
          reasonCode: 200,
          reasonText: 'Normal End Call by ONGOING'
        })

      } else if (getters.UASessionCallStatus === 'RINGING') {
        this.commit('voip/updateSessionData', {
          idCall: idCall,
          statusConnection: 'CONNECTING',
          statusCall: 'CLOSED',
          data: {
            rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
            rtc_ongoing_start_at: null,
            rtc_ongoing_finish_at: null
          },
          optAction: {
            isRejectBeforeConnected: true,
            isRejectAfterConnected: false,
            rejectByThem: false,
            rejectByUs: true
          },
          reasonCode: 200,
          reasonText: 'Normal End Call by RINGING'
        })
      }

      // ended session call
      if (window.UASession) {
        if (window.UASession.state === SessionState.Initial || window.UASession.state === SessionState.Establishing) {
          window.UASession.cancel().catch((e) => {
            console.warn('Problem in EndSession(), could not cancel() call', e, window.UASession)
          })
        } else if (window.UASession.state === SessionState.Established) {
          window.UASession.bye().catch((e) => {
            console.warn('Problem in RejectCall(), could not bye() call', e, window.UASession)
          })
        } else {
          window.UASession.reject({
            statusCode: 486,
            reasonPhrase: 'Busy Here'
          }).catch((e) => {
            console.warn('Problem in RejectCall(), could not reject() call', e, window.UASession)
          })
        }
      }

      //
      this.dispatch('voip/dropSessionOutgoingCall')
    },

    dropSessionOutgoingCall (state, { reason, getters }) {
      //
      if (!state.UASessionData) return false
      if (state.UASessionData.teardownComplete === true) return false
      state.UASessionData.teardownComplete = true // Run this code only once

      // Mixed Tracks
      if (window.UASession) {
        if (
          window.UASession.data.AudioSourceTrack && 
          window.UASession.data.AudioSourceTrack.kind === 'audio'
        ) {
          window.UASession.data.AudioSourceTrack.stop()
          window.UASession.data.AudioSourceTrack = null
        }
        // Stop any Early Media
        if (window.UASession.data.earlyMedia) {
          window.UASession.data.earlyMedia.pause()
          window.UASession.data.earlyMedia.removeAttribute('src')
          window.UASession.data.earlyMedia.load()
          window.UASession.data.earlyMedia = null
        }
        // Stop any ringing calls
        if (window.UASession.data.rinngerObj) {
          window.UASession.data.rinngerObj.pause()
          window.UASession.data.rinngerObj.removeAttribute('src')
          window.UASession.data.rinngerObj.load()
          window.UASession.data.rinngerObj = null
        }

        // Make sure you have released the microphone
        if ((window.UASession.sessionDescriptionHandler || {}).peerConnection) {
          const peerConnection = window.UASession.sessionDescriptionHandler.peerConnection

          peerConnection.getSenders().forEach((RTCRtpSender) => {
            if (RTCRtpSender.track && RTCRtpSender.track.kind === 'audio') {
              RTCRtpSender.track.stop()
            }
          })
        }
      }

      // Update data
      if (
        getters.UASessionDataCall.rtc_ongoing_start_at &&
        !getters.UASessionDataCall.rtc_ongoing_finish_at
      ) {
        state.UASessionData.data.rtc_ongoing_finish_at = moment().format('YYYY-MM-DD HH:mm:ss.SSS')
        state.UASessionData.data.rtc_ongoing_is_failed = 'N'
        
      } 
      
      if (
        getters.UASessionDataCall.rtc_connecting_start_at &&
        !getters.UASessionDataCall.rtc_connecting_finish_at
      ) { 
        state.UASessionData.data.rtc_connecting_finish_at = moment().format('YYYY-MM-DD HH:mm:ss.SSS')
      }

      //
      state.UASessionData.statusAddResult = 'READY'
      state.UASessionData.reasonAddResult = reason

      // reset Session
      state.dataCallResult = Gen.reParsingJson({
        ...state.UASessionData.data,
        ...state.UASessionData.optAction,
        type: state.UASessionData.type,
        ioType: state.UASessionData.ioType,
        subIoType: state.UASessionData.subIoType,
        statusConnection: state.UASessionData.statusConnection,
        statusCall: state.UASessionData.statusCall,
        reasonCode: state.UASessionData.reasonCode,
        reasonText: state.UASessionData.reasonText,
        statusAddResult: state.UASessionData.statusAddResult,
        reasonAddResult: state.UASessionData.reasonAddResult,
        rejectWhen: state.UASessionData.optAction.isRejectAfterConnected ? 'AFTER_CONNECTED' : 'BEFORE_CONNECT',
        rejectBy: state.UASessionData.optAction.rejectByUs ? 'US' : 'THEM'
      })

      Gen.putStorage('dataCallResult', state.dataCallResult)

      window.UASession = null // **
      state.UASession = null
      state.UASessionData = null // **

      // update : agent
      this.dispatch('updateCurrentAgentStatus', {
        status: 'ACW',
        activity: {
          is_exists: 'Y',
          loan_external_loan_no: state.dataCallResult.external_loan_no,
          loan_customer_name: state.dataCallResult.customer_name,
          client_name: state.dataCallResult.client_name,
          campaign_name: state.dataCallResult.campaign_name
        }
      })

      //
      state.openCallResult = true

      Gen.apiRest(
        '/crud/do/' + restApi + '/waiting-result-outgoing-call',
        {
          method: 'POST',
          data: state.dataCallResult
        }
      )
        .then(resp => {
          window.Swal.close()
        })

      // broadcast monitoring call
      this.dispatch('voip/broadcastOutgoingCall', Gen.reParsingJson({
        ...Gen.reParsingJson(state.dataCallResult),
        status_monitoring: 'REMOVE'
      }))
    },
    // END :: OUTBOUND //

    // MONITORING OUTBOUND //
    broadcastOutgoingCall (state, { data, getters }) {
      if (window.monitoringBroadcastTo && data && data.id_call) {
        const userCreds = getters.userCredential

        Gen.socketRest(
          '/send-ongoing-ougoing-call',
          {
            method: 'POST',
            data: {
              id_fu: data.id_fu,
              id_agent: userCreds.id,
              name_agent: userCreds.full_name,
              id_spv: userCreds.id_spv,
              id_manager: userCreds.id_manager,
              voip_extention_agent: userCreds.extention,
              id_call: data.id_call,
              id_cp_campaign: data.id_cp_campaign,
              id_rw_campaign: data.id_rw_campaign,
              id_contact: data.id_contact,
              customer_name: data.customer_name,
              external_loan_no: data.external_loan_no,
              product: data.product,
              phone_no: data.phone_no,
              phone_category: data.phone_category,
              calling_method: data.calling_method,
              io_type: data.io_type,
              status_call: data.statusCall,
              status_monitoring: data.status_monitoring,
              broadcast_to: JSON.stringify(window.monitoringBroadcastTo)
            }
          }
        )
          .catch(e => {
            console.log('Throw', e)
          })
      }
    },

    monitoringOutgoingCall (state, { payload, getters }) {
      if (!getters.userCredential) {
        return window.Swal.fire(Wording.voip.monitoring.cannot_do_monitoring)
      } else if (getters.hasPredictiveQueue) {
        return window.Swal.fire(Wording.voip.monitoring.there_is_ongoing_predictive)
      }
      
      const type = payload.type
      const data = payload.data
      data.rtc_connecting_start_at = moment().format('YYYY-MM-DD HH:mm:ss.SSS')

      const onOngoingType = (state.onCallMonitoring || {}).type
      const onOngoingData = (state.onCallMonitoring || {}).data || {}

      const toTurnOn = !state.onCallMonitoring

      if (getters.UAStatus !== 'CONNECTED') {
        return window.Swal.fire(Wording.voip.monitoring.ua_is_not_connected)
      } else if (getters.UASessionStatus === 'NOT_READY') {
        return window.Swal.fire(Wording.voip.monitoring.ua_session_is_not_ready)
      } else if (getters.UASessionStatus === 'BUSY' && toTurnOn) {
        return window.Swal.fire(Wording.voip.monitoring.ua_session_is_busy)
      } else if (getters.userIsReady === false && toTurnOn) {
        return window.Swal.fire(Wording.voip.monitoring.no_user_ready)
      } else if (state.micDevices.length === 0) {
        return window.Swal.fire(Wording.voip.monitoring.no_audio_device)
      } else if (state.onCallMonitoring && onOngoingData.id_fu !== data.id_fu) {
        return state.Swal(Wording.voip.monitoring.bentrok_do_monitoring)
      } else if (state.onCallMonitoring && onOngoingType !== type) {
        return state.Swal(Wording.voip.monitoring.bentrok_do_monitoring)
      }

      const credsExtention = data.voip_extention_agent
      const destinationNumber = data.phone_no
      const idCall = data.id_call
      const monitoringExtention = listMonitoringExtentions[type]

      if (toTurnOn) {
        // to turn on
        state.onCallMonitoring = {
          type: type,
          data: data
        }

        const supportedConstraints = navigator.mediaDevices.getSupportedConstraints()

        const spdOptions = {
          earlyMedia: true,
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: { deviceId: 'default' },
              video: false
            }
          }
        }

        // Add additional Constraints
        if (supportedConstraints.autoGainControl) {
          spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl = 1
        }
        if (supportedConstraints.echoCancellation) {
          spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation = 1
        }
        if (supportedConstraints.noiseSuppression) {
          spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression = 1
        }

        // Set headers
        spdOptions.extraHeaders = [
          'X-Call-Id:' + idCall,
          'MONITORING_EXTENTION:' + monitoringExtention,
          'AGENT_EXTENTION:' + credsExtention,
          'DESTINATION_NUMBER:' + destinationNumber,
          'ID_CALL:' + idCall
        ]

        // Invite Monitoring
        let targetURI = 'sip:*' + monitoringExtention + '' + credsExtention + '@' + urlWebSocket
        // targetURI = 'sip:*79@' + urlWebSocket // tesy
        console.log('monitoring targetURI', targetURI)
        targetURI = UserAgent.makeURI(targetURI)

        window.UASession = new Inviter(state.UA, targetURI, spdOptions)
        state.UASession = {}

        window.UASession.data = {
          type: 'OUTBOUND',
          AudioSourceTrack: null, // by webrtc system
          earlyMedia: null, // by webrtc system
          rinngerObj: null, // by webrtc system
          recorder: null // by webrtc system
        }

        // Configure DATA_CALL
        state.UASessionData = {
          type: 'MONITORING',
          tabId: window.currentTabID,
          ioType: data.io_type,
          idCall: data.id_call,
          callingMethod: data.calling_method,
          destinationNumber: data.phone_category,

          data: {},
          optAction: {},
          feature: {},

          statusConnection: null,
          statusCall: null,
          reasonCode: null,
          reasonText: null,
          statusAddResult: null,
          reasonAddResult: 'NORMAL' // NORMAL | NOT_NORMAL (session nya di force quit sebelumnya)
        }
        state.UASessionData.data = data
        state.UASessionData.feature = {
          onMute: false,
          onHold: false
        }
        state.UASessionData.optAction = {
          failedToConnect: false,
          isRejectBeforeConnected: false,
          isRejectAfterConnected: false,
          rejectByThem: false,
          rejectByUs: false
        }

        state.UASessionData.statusConnection = 'CONNECTING' // **
        state.UASessionData.statusCall = 'RINGING' // **
        state.UASessionData.statusAddResult = 'NOT_USED'

        // Configure : Feedback Given By Customer ANSWER (accept) or NO_ANSWER (reject)
        window.UASession.delegate = {
          onBye: (sip) => {
            /**
            * Ongoing call is Ended
            */
            console.log('onBye', sip)

            this.commit('voip/updateSessionData', {
              idCall: idCall,

              statusConnection: 'CONNECTED',
              statusCall: 'CLOSED',
              data: {
                rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
              },
              optAction: {
                isRejectAfterConnected: true,
                rejectByThem: true,
                rejectByUs: false
              },
              reasonCode: 16,
              reasonText: 'Normal Call clearing'
            })

            this.dispatch('voip/dropSessionMonitoringOutgoingCall')
          },
          onSessionDescriptionHandler: (sdh) => {
            if (sdh) {
              if (sdh.peerConnection) {
                sdh.peerConnection.ontrack = (event) => {
                  this.commit('voip/onTrackAddedEvent')
                }
              } else {
                console.warn('onSessionDescriptionHandler fired without a peerConnection')
              }
            } else {
              console.warn('onSessionDescriptionHandler fired without a sessionDescriptionHandler')
            }
          }
        }

        const inviterOptions = {
          requestDelegate: { // OutgoingRequestDelegate
            onAccept: (sip) => {
              /**
             * Successfully Connect & Call is answered and in ongoing call
             */
              console.log('onAccept', sip)

              /**
             * CALL IS ACCAPTED BY CUSTOMER
             * SET TO STREAM AUDITO
             */
              this.commit('voip/updateSessionData', {
                idCall: idCall,

                statusConnection: 'CONNECTED',
                statusCall: 'ONGOING',
                data: {
                  rtc_connecting_finish_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
                  rtc_ongoing_start_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
                  rtc_ongoing_finish_at: null
                },
                optAction: {
                  isRejectAfterConnected: false,
                  isRejectBeforeConnected: false,
                  rejectByThem: false,
                  rejectByUs: false
                },
                feature: {
                  onMute: false,
                  onHold: false
                }
              })

              if (window.UASession.data.earlyMedia) {
                window.UASession.data.earlyMedia.pause()
                window.UASession.data.earlyMedia.removeAttribute('src')
                window.UASession.data.earlyMedia.load()
                window.UASession.data.earlyMedia = null
              }
            },
            onReject: (response) => {
              /***
             * Failed to connect / Disconnect in the middle / there is something wrong with the status given by the server voip
             */
              console.log('onReject', response)

              this.commit('voip/updateSessionData', {
                idCall: idCall,

                statusConnection: 'FAILED_TO_CONNECT',
                statusCall: 'DISCONNECTED',
                data: {
                  rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
                },
                optAction: {
                  isRejectBeforeConnected: true,
                  rejectByThem: true,
                  rejectByUs: false
                },
                reasonCode: response.message.statusCode,
                reasonText: response.message.reasonPhrase
              })

              this.dispatch('voip/dropSessionMonitoringOutgoingCall')
            }
          }
        }

        // FINALLY : do monitroing CALL
        window.UASession.invite(inviterOptions).catch(e => {
          console.warn('Failed to send INVITE:', e)

          if (state.UASessionData) {
            if (state.UASessionData.statusCall === 'RINGING') {
              this.commit('voip/updateSessionData', {
                idCall: idCall,

                statusConnection: 'FAILED_TO_CONNECT',
                statusCall: 'DISCONNECTED',
                data: {
                  rtc_connecting_finish_at: getters.UASessionDataCall.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
                },
                optAction: {
                  isRejectBeforeConnected: true,
                  rejectByThem: true,
                  rejectByUs: false
                },
                reasonCode: 500,
                reasonText: 'Failed to send INVITE:' + (e.message.reasonPhrase || '')
              })

              this.dispatch('voip/dropSessionMonitoringOutgoingCall')
            }
          }
        })
      } else {
        // to turn off
        if (window.UASession) {
          if (
            window.UASession.state === SessionState.Initial ||
            window.UASession.state === SessionState.Establishing
          ) {
            window.UASession.cancel().catch((e) => {
              console.warn('Problem in EndSession(), could not cancel() call', e, window.UASession)
            })

          } else if (window.UASession.state === SessionState.Established) {
            window.UASession.bye().catch((e) => {
              console.warn('Problem in RejectCall(), could not bye() call', e, window.UASession)
            })

          } else if (state.isBypass === false) {
            window.UASession.reject({
              statusCode: 486,
              reasonPhrase: 'Busy Here'
            }).catch((e) => {
              console.warn('Problem in RejectCall(), could not reject() call', e, window.UASession)
            })

            console.warn('Session not in correct state for cancel.', window.UASession.state)
          }

          //
          this.dispatch('voip/dropSessionMonitoringOutgoingCall')
          state.onCallMonitoring = null

        } else {
          this.dispatch('voip/dropSessionMonitoringOutgoingCall')
          state.onCallMonitoring = null
        }
      }
    },

    switchMonitoringOutgoingCall (state, toType) {
      state.onCallMonitoring = {
        ...state.onCallMonitoring,
        type: toType
      }

      if (window.UASession) {
        if (window.UASession.sessionDescriptionHandler) {
          // console.log('BISA ', listSwitchMonitoringCode[toType])
          window.UASession.sessionDescriptionHandler.sendDtmf(listSwitchMonitoringCode[toType])
        }
      }
    },

    dropSessionMonitoringOutgoingCall (state, { reason, getters }) {
      //
      if (!state.UASessionData) return false
      if (state.UASessionData.teardownComplete === true) return false
      state.UASessionData.teardownComplete = true // Run this code only once

      // Mixed Tracks
      if (window.UASession) {
        if (
          window.UASession.data.AudioSourceTrack &&
          window.UASession.data.AudioSourceTrack.kind === 'audio'
        ) {
          window.UASession.data.AudioSourceTrack.stop()
          window.UASession.data.AudioSourceTrack = null
        }
        // Stop any Early Media
        if (window.UASession.data.earlyMedia) {
          window.UASession.data.earlyMedia.pause()
          window.UASession.data.earlyMedia.removeAttribute('src')
          window.UASession.data.earlyMedia.load()
          window.UASession.data.earlyMedia = null
        }
        // Stop any ringing calls
        if (window.UASession.data.rinngerObj) {
          window.UASession.data.rinngerObj.pause()
          window.UASession.data.rinngerObj.removeAttribute('src')
          window.UASession.data.rinngerObj.load()
          window.UASession.data.rinngerObj = null
        }

        // Make sure you have released the microphone
        if ((window.UASession.sessionDescriptionHandler || {}).peerConnection) {
          const peerConnection = window.UASession.sessionDescriptionHandler.peerConnection

          peerConnection.getSenders().forEach((RTCRtpSender) => {
            if (RTCRtpSender.track && RTCRtpSender.track.kind === 'audio') {
              RTCRtpSender.track.stop()
            }
          })
        }
      }

      // Update data
      if (
        getters.UASessionDataCall.rtc_ongoing_start_at &&
        !getters.UASessionDataCall.rtc_ongoing_finish_at
      ) {
        state.UASessionData.data.rtc_ongoing_finish_at = moment().format('YYYY-MM-DD HH:mm:ss.SSS')
        state.UASessionData.data.rtc_ongoing_is_failed = 'N'

      }

      if (
        getters.UASessionDataCall.rtc_connecting_start_at &&
        !getters.UASessionDataCall.rtc_connecting_finish_at
      ) {
        state.UASessionData.data.rtc_connecting_finish_at = moment().format('YYYY-MM-DD HH:mm:ss.SSS')
      }

      //
      state.UASessionData.statusAddResult = 'READY'
      state.UASessionData.reasonAddResult = reason

      // reset Session
      state.openCallResult = false
      state.dataCallResult = null
      Gen.removeStorage('dataCallResult')

      window.UASession = null // **
      state.UASession = null
      state.UASessionData = null // **

      state.onCallMonitoring = null // ***
    },
    // END :: MONITORING OUTBOUND //

    // PROGRESSIVE OUTBOUND //
    updateProgressiveQueue (state, payload = {}) {
      if (payload.type === 'TRIGGER') {
        state.triggerUpdateProgressiveQueue = payload.data
      } else {
        state.progressiveQueue = payload.data
      }
    },
    // END :: PROGRESSIVE OUTBOUND //

    // PREDICTIVE OUTBOUND //
    startPredictiveCallPerData (state, { payload, getters }) {
      // 
      payload.currentProcess = 'REGISTER_ID_CALL'
      payload.data.currentProcess = payload.currentProcess
      payload.data.voip = {
        statusConnection: 'CONNECTING',
        statusCall: 'RINGING'
      }

      let respPrevent = false

      console.log('initial payload.jmlRetry', payload.jmlRetry)

      const callbackPrevent = (prevent = {}) => {
        switch (payload.currentProcess) {
          case 'REGISTER_ID_CALL':
            if (payload.jmlRetry <= payload.maxRetry) {
              return this.dispatch('voip/startPredictiveCallPerData', {
                ...payload,
                jmlRetry: payload.jmlRetry + 1
              })

            } else {
              if (prevent.alert) {
                window.Swal.fire(prevent.alert)
              }

              payload.currentProcess = 'FAILED_REGISTER_ID_CALL'
              payload.data.currentProcess = payload.currentProcess
              payload.data.voip.statusConnection = 'FAILED_TO_CONNECT'
              payload.data.voip.statusCall = 'DISCONNECTED'

              payload.updateValue(payload.data)

              console.log('payload.maxRetry', payload.maxRetry)
              console.log('payload.jmlRetry', payload.jmlRetry)
              console.log('payload.currentProcess', payload.currentProcess)

              return payload.register.catchCallback(payload)
            }
            
        }
      }

      /**
       * @ (2) START OUTGOING CALL
       */
      const funcStartOutgoingCall = (respPayload) => {
        const destinationNumber = respPayload.destinationNumber
        const idCall = respPayload.idCall

        let dataCall = respPayload.dataCall || {}
        dataCall = {
          ...dataCall,
          io_type: 'OUTBOUND',
          line_type: 'CALL',
          id_call: idCall,
          destination_number: destinationNumber,
          caller_number: null, // default undefined - will be get after CRON,
          calling_method: dataCall.calling_method,
          request_filename_recording: dataCall.request_filename_recording,

          api_register_start_at: dataCall.api_register_start_at,
          api_register_finish_at: dataCall.api_register_finish_at,

          rtc_connecting_start_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'), // with miliseconds
          rtc_connecting_finish_at: null, // default - wil be replace after THEN
          rtc_connecting_is_failed: 'D',
          rtc_connecting_failed_reason: {}
        }

        // WARNING **** BYPASS
        /* if (state.isBypass) {
          // WARNING **** BYPASS
          payload.data.isRegister = 'Y'
          payload.data.voip = {
            ...payload.data.voip,
            type: 'CALLING',
            tabId: window.currentTabID,
            ioType: dataCall.io_type,
            idCall: dataCall.id_call,
            callingMethod: dataCall.calling_method,
            destinationNumber: dataCall.destination_number,

            data: dataCall,
            optAction: {
              failedToConnect: false,
              isRejectBeforeConnected: false,
              isRejectAfterConnected: false,
              rejectByThem: false,
              rejectByUs: false
            },
            feature: {
              onMute: false,
              onHold: false
            },

            statusConnection: 'CONNECTING',
            statusCall: 'RINGING',
            reasonCode: null,
            reasonText: null,
            statusAddResult: 'ON_PROCESS',
            reasonAddResult: 'NORMAL'
          }

          payload.updateValue(payload.data)

          // * A * CASE ACCEPTED
          setTimeout(() => {
            // TEST CALL : ACCEPTED
            // (0) update data
            payload.data.voip = {
              ...payload.data.voip,
              idCall: idCall,
              statusConnection: 'CONNECTED',
              statusCall: 'TRANSFERRING',
              data: {
                ...payload.data.voip.data,
                rtc_connecting_finish_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS')
              },
              optAction: {
                isRejectAfterConnected: false,
                isRejectBeforeConnected: false,
                rejectByThem: false,
                rejectByUs: false
              }
            }
            payload.updateValue(payload.data)
            // *** tdk ada callback

            // (1) first step to hold the call (waiting to be transferred)
            payload.data.voip.optAction.onHold = true
            payload.updateValue(payload.data)

            //
            const funcTransferToAgent = () => {
              // (5) jk ada yg ready, ambil first ready agent then continue transfer
              const assignedAgent = _.genClone(state.readyAgentsPredictive[0])
              const destinationTransfer = (assignedAgent.voip_credentials || {}).extention

              console.log('ready transfer to', destinationTransfer)

              payload.jmlRetry = 0
              payload.currentProcess = 'INITIAL_TRANSFER_TO_AGENT'
              payload.data.currentProcess = payload.currentProcess

              // update data
              payload.data.id_assigned_agent = assignedAgent.id
              payload.data.is_accepted = 'Y'
              payload.data.accepted_at = moment().format('YYYY-MM-DD HH:mm:ss')
              payload.updateValue(payload.data)

              nextTick(() => {
                // (6) save informasi terbaru ke DB
                payload.transferrring.intialCallback({
                  payload: payload,
                  thenCallback: () => {
                    // TEST CALL : TRANSFER ACCEPTED / SUCCESS TRANSFER

                    payload.jmlRetry = 0
                    payload.currentProcess = 'SUCCESS_TRANSFER_TO_AGENT'
                    payload.data.currentProcess = payload.currentProcess
                    payload.data.is_transfer = 'Y'
                    payload.data.status_transfer = 'SUCCESS'
                    payload.data.status_fu_transfer = 'DEFAULT'

                    payload.updateValue(payload.data)

                    payload.transferrring.successTransferCallback({
                      payload: payload,
                      thenCallback: () => {
                        console.log('REJECT CALL CERITANYA', payload.data)
                      }
                    })
                  }
                })
              })
            }

            // (2) cek ready agents
            setTimeout(() => {
              // TEST CALL : INITIAL TRANSFER / ASSIGNED READY AGENT
              if (getters.hasReadyAgent === false) {
                // (3) settimeout selama 5 second untuk re-get ready agent
                setTimeout(() => {
                  if (getters.hasReadyAgent === false) {
                    // (4) jk sudah 2x percobaan tidak ada ready agent, call auto di reject by SYSTEM
                    payload.jmlRetry = 0
                    payload.currentProcess = 'FAILED_TRANSFER_TO_AGENT_CAUSE_NO_READY_AGENT'
                    payload.data.currentProcess = payload.currentProcess
                    payload.data.id_assigned_agent = null
                    payload.data.is_accepted = 'N'
                    payload.data.is_transfer = 'N'
                    payload.data.status_transfer = 'FAILED'
                    payload.data.status_fu_transfer = 'DEFAULT'
                    payload.updateValue(payload.data)

                    payload.transferrring.noReadyAgentCallback({
                      payload: payload,
                      thenCallback: () => {
                        console.log('REJECT CALL CERITANYA NO AGENT', payload.data)
                      }
                    })
                  } else {
                    funcTransferToAgent()
                  }
                }, 5 * 5000)
              } else {
                funcTransferToAgent()
              }
            }, 5 * 1000)
          }, 7 * 1000)

          return false // WARNING **** BYPASS
        } */

        //
        const spdOptions = {
          earlyMedia: true,
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: { deviceId: 'default' },
              video: false
            }
          }
        }

        spdOptions.extraHeaders = [
          'X-Call-Id:' + dataCall.id_call,
          'DESTINATION_NUMBER:' + destinationNumber,
          'CUSTOM_ID_CALL:' + dataCall.id_call,
          'CUSTOM_FILENAME_RECORDING:' + dataCall.request_filename_recording
        ] // format: [ 'KEY:VALUE' ]

        // Initiate Session
        // update from 628xxxxxx into 08xxxxxxx
        const destinationNumberLeadingZero = '0' + destinationNumber.substr(2)
        let targetURI = 'sip:' + destinationNumberLeadingZero + '@' + urlWebSocket
        targetURI = UserAgent.makeURI(targetURI)
        
        const WindowUASession = new Inviter(state.UA, targetURI, spdOptions) // *** PATOKAN
        state.UASession = {}

        WindowUASession.data = {
          AudioSourceTrack: null, // by webrtc system
          earlyMedia: null, // by webrtc system
          rinngerObj: null, // by webrtc system
          recorder: null // by webrtc system
        }

        // Configure DATA_CALL
        payload.data.isRegister = 'Y'
        payload.data.voip = {
          ...payload.data.voip,
          type: 'CALLING',
          tabId: window.currentTabID,
          ioType: dataCall.io_type,
          idCall: dataCall.id_call,
          callingMethod: dataCall.calling_method,
          destinationNumber: dataCall.destination_number,

          data: dataCall,
          optAction: {
            failedToConnect: false,
            isRejectBeforeConnected: false,
            isRejectAfterConnected: false,
            rejectByThem: false,
            rejectByUs: false
          },
          feature: {
            onMute: false,
            onHold: false
          },

          statusConnection: 'CONNECTING',
          statusCall: 'RINGING',
          reasonCode: null,
          reasonText: null,
          statusAddResult: 'ON_PROCESS',
          reasonAddResult: 'NORMAL'
        }

        payload.updateValue(payload.data)

        // Configure : Feedback Given By Customer ANSWER (accept) or NO_ANSWER (reject)
        const inviterOptions = {
          requestDelegate: {
            onAccept: (sip) => {
              /**
               * Successfully Connect & Call is answered 
               * Continute to transfer the call into ready agents
               */

              // (0) update data
              payload.data.voip = {
                ...payload.data.voip,
                idCall: idCall,
                statusConnection: 'CONNECTED',
                statusCall: 'TRANSFERRING',
                data: {
                  ...payload.data.voip.data,
                  rtc_connecting_finish_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS')
                },
                optAction: {
                  isRejectAfterConnected: false,
                  isRejectBeforeConnected: false,
                  rejectByThem: false,
                  rejectByUs: false
                }
              }
              payload.updateValue(payload.data)
              // *** tdk ada callback
              
              // (1) first step to hold the call (waiting to be transferred)
              payload.data.voip.optAction.onHold = true
              payload.updateValue(payload.data)

              WindowUASession.sessionDescriptionHandlerOptionsReInvite = WindowUASession.sessionDescriptionHandlerOptionsReInvite.hold = true

              WindowUASession.invite({
                requestDelegate: {
                  onAccept: () => {
                    if (
                      WindowUASession &&
                      WindowUASession.sessionDescriptionHandler &&
                      WindowUASession.sessionDescriptionHandler.peerConnection
                    ) {
                      const pc = WindowUASession.sessionDescriptionHandler.peerConnection

                      // Stop all the inbound streams
                      pc.getReceivers().forEach((RTCRtpReceiver) => {
                        if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = false
                      })

                      // Stop all the outbound streams (especially usefull for Conference Calls!!)
                      pc.getSenders().forEach((RTCRtpSender) => {
                        // Mute Audio
                        if (RTCRtpSender.track && RTCRtpSender.track.kind === 'audio') {
                          if (RTCRtpSender.track.IsMixedTrack === true) {
                            if (
                              WindowUASession.data.AudioSourceTrack &&
                              WindowUASession.data.AudioSourceTrack.kind === 'audio'
                            ) {
                              WindowUASession.data.AudioSourceTrack.enabled = false
                            }
                          }

                          RTCRtpSender.track.enabled = false
                        }
                      })
                    }
                  }
                }
              })

              //
              const funcTransferToAgent = () => {
                // (5) jk ada yg ready, ambil first ready agent then continue transfer
                const assignedAgent = _.genClone(state.readyAgentsPredictive[0])
                const destinationTransfer = (assignedAgent.voip_credentials || {}).extention

                payload.jmlRetry = 0
                payload.currentProcess = 'INITIAL_TRANSFER_TO_AGENT'
                payload.data.currentProcess = payload.currentProcess
                
                // update data
                payload.data.id_assigned_agent = assignedAgent.id
                payload.data.is_accepted = 'Y'
                payload.data.accepted_at = moment().format('YYYY-MM-DD HH:mm:ss')
                payload.updateValue(payload.data)

                nextTick(() => {
                  // (6) save informasi terbaru ke DB
                  payload.transferrring.intialCallback({
                    payload: payload,
                    thenCallback: () => {
                      // (7) lanjut transfer ke AGENT dg HOLD yang masih nyala (apakah hold nya akan copot dengan sendirinya ????)
                      let transferURI = 'sip:' + destinationTransfer + '@' + urlWebSocket
                      transferURI = UserAgent.makeURI(transferURI)

                      const transferOption = {
                        requestOptions: {
                          extraHeaders: [
                            'Referred-By: ' + JSON.stringify({
                              SOURCE_DATA: 'TRANSFER_PREDICTIVE',
                              TRANSFER_FROM_ID_USER: dataCall.created_by,
                              TRANSFER_FROM_NAME_USER: dataCall.created_by === getters.userCredential.id ? getters.userCredential.full_name : '',

                              DESTINATION_TRANSFER_TO: destinationTransfer,
                              DESTINATION_ID_AGENT: assignedAgent.id,

                              CUSTOMER_NUMBER: destinationNumber,
                              CUSTOMER_ID_CALL: dataCall.id_call,
                              CUSTOMER_FILENAME_RECORDING: dataCall.request_filename_recording,

                              CUSTOMER_CLIENT_NAME: dataCall.client_name,
                              CUSTOMER_CAMPAIGN_NAME: dataCall.campaign_name,

                              CUSTOMER_ID_JOB: payload.data.id_job,
                              CUSTOMER_ID_JOB_DATA: payload.data.id,

                              CUSTOMER_ID_FU: dataCall.id_fu,
                              CUSTOMER_PHONE_NO: dataCall.phone_no,
                              CUSTOMER_PHONE_CATEGORY: dataCall.phone_category,

                              CUSTOMER_ID_LOAN: dataCall.id_loan,
                              CUSTOMER_ID_CP_CAMPAIGN: dataCall.id_cp_campaign,
                              
                              CUSTOMER_NAME: dataCall.customer_name,
                              CUSTOMER_AGREEMENT_NO: dataCall.external_loan_no,
                              CUSTOMER_PRODUCT: dataCall.product
                            })
                          ]
                        },
                        requestDelegate: {
                          onAccept: (sip) => {
                            console.log('Blind transfer Accepted', sip)

                            payload.jmlRetry = 0
                            payload.currentProcess = 'SUCCESS_TRANSFER_TO_AGENT'
                            payload.data.currentProcess = payload.currentProcess
                            payload.data.is_transfer = 'Y'
                            payload.data.status_transfer = 'SUCCESS'
                            payload.data.status_fu_transfer = 'DEFAULT'

                            payload.updateValue(payload.data)

                            payload.transferrring.successTransferCallback({
                              payload: payload,
                              thenCallback: () => {
                                if (WindowUASession) {
                                  WindowUASession.bye().catch((error) => {
                                    console.warn('Could not BYE after blind transfer:', error)
                                  })
                                }

                                if (WindowUASession) {
                                  WindowUASession.cancel().catch((e) => {
                                    console.warn('Problem in EndSession(), could not cancel() call', e, WindowUASession)
                                  })
                                }

                                /* if (WindowUASession) {
                                  WindowUASession.reject({
                                    statusCode: 486,
                                    reasonPhrase: 'Busy Here'
                                  }).catch((e) => {
                                    console.warn('Problem in RejectCall(), could not reject() call', e, WindowUASession)
                                  })
                                } */
                              }
                            })

                          },
                          onReject: (sip) => {
                            console.warn('REFER rejected:', sip)

                            payload.jmlRetry = 0
                            payload.currentProcess = 'REJECT_BY_AGENT_WHEN_TRANSFER_TO_AGENT'
                            payload.data.currentProcess = payload.currentProcess
                            payload.data.is_transfer = 'N'
                            payload.data.status_transfer = 'SUCCESS'
                            payload.data.status_fu_transfer = 'REJECTED'
                            
                            payload.updateValue(payload.data)

                            payload.transferrring.rejectCallback({
                              payload: payload,
                              thenCallback: () => {
                                if (WindowUASession) {
                                  WindowUASession.bye().catch((error) => {
                                    console.warn('Could not BYE after blind transfer:', error)
                                  })
                                }

                                if (WindowUASession) {
                                  WindowUASession.cancel().catch((e) => {
                                    console.warn('Problem in EndSession(), could not cancel() call', e, WindowUASession)
                                  })
                                }

                                /* if (WindowUASession) {
                                  WindowUASession.reject({
                                    statusCode: 486,
                                    reasonPhrase: 'Busy Here'
                                  }).catch((e) => {
                                    console.warn('Problem in RejectCall(), could not reject() call', e, WindowUASession)
                                  })
                                } */
                              }
                            })
                          }
                        }
                      }

                      WindowUASession.refer(transferURI, transferOption)
                        .catch((error) => {
                          console.warn('Failed to REFER', error)

                          payload.jmlRetry = 0
                          payload.currentProcess = 'FAILED_TRANSFER_TO_AGENT_CAUSE_CONNECTION_PROBLEM'
                          payload.data.currentProcess = payload.currentProcess
                          payload.data.is_transfer = 'N'
                          payload.data.status_transfer = 'FAILED'
                          payload.data.status_fu_transfer = 'DEFAULT'

                          payload.updateValue(payload.data)

                          try {
                            if (WindowUASession) {
                              WindowUASession.bye().catch((error) => {
                                console.warn('Could not BYE after blind transfer:', error)
                              })
                            }

                            if (WindowUASession) {
                              WindowUASession.cancel().catch((e) => {
                                console.warn('Problem in EndSession(), could not cancel() call', e, WindowUASession)
                              })
                            }

                            /* if (WindowUASession) {
                              WindowUASession.reject({
                                statusCode: 486,
                                reasonPhrase: 'Busy Here'
                              }).catch((e) => {
                                console.warn('Problem in RejectCall(), could not reject() call', e, WindowUASession)
                              })
                            } */
                          } catch (err) {
                            console.log(err)
                          }

                          payload.transferrring.connectingProblemCallback({
                            payload: payload,
                            thenCallback: () => {
                              if (WindowUASession) {
                                WindowUASession.bye().catch((error) => {
                                  console.warn('Could not BYE after blind transfer:', error)
                                })
                              }

                              if (WindowUASession) {
                                WindowUASession.cancel().catch((e) => {
                                  console.warn('Problem in EndSession(), could not cancel() call', e, WindowUASession)
                                })
                              }

                              /* if (WindowUASession) {
                                WindowUASession.reject({
                                  statusCode: 486,
                                  reasonPhrase: 'Busy Here'
                                }).catch((e) => {
                                  console.warn('Problem in RejectCall(), could not reject() call', e, WindowUASession)
                                })
                              } */
                            }
                          })
                        })
                    }
                  })
                })
              }

              // (2) cek ready agents
              if (getters.hasReadyAgent === false) {
                // (3) settimeout selama 5 second untuk re-get ready agent
                setTimeout(() => {
                  if (getters.hasReadyAgent === false) {
                    // (4) jk sudah 2x percobaan tidak ada ready agent, call auto di reject by SYSTEM
                    payload.jmlRetry = 0
                    payload.currentProcess = 'FAILED_TRANSFER_TO_AGENT_CAUSE_NO_READY_AGENT'
                    payload.data.currentProcess = payload.currentProcess
                    payload.data.id_assigned_agent = null
                    payload.data.is_accepted = 'N'
                    payload.data.is_transfer = 'N'
                    payload.data.status_transfer = 'FAILED'
                    payload.data.status_fu_transfer = 'DEFAULT'
                    payload.updateValue(payload.data)

                    payload.transferrring.noReadyAgentCallback({
                      payload: payload,
                      thenCallback: () => {
                        if (WindowUASession) {
                          WindowUASession.bye().catch((error) => {
                            console.warn('Could not BYE after blind transfer:', error)
                          })
                        }

                        if (WindowUASession) {
                          WindowUASession.cancel().catch((e) => {
                            console.warn('Problem in EndSession(), could not cancel() call', e, WindowUASession)
                          })
                        }

                        /* if (WindowUASession) {
                          WindowUASession.reject({
                            statusCode: 486,
                            reasonPhrase: 'Busy Here'
                          }).catch((e) => {
                            console.warn('Problem in RejectCall(), could not reject() call', e, WindowUASession)
                          })
                        } */
                      }
                    })
                  } else {
                    funcTransferToAgent()  
                  }
                }, 5 * 5000)
              } else {
                funcTransferToAgent()
              }
            },
            onReject: (response) => {
              /***
               * Failed to connect / Disconnect in the middle / there is something wrong with the status given by the server voip
               */
              payload.currentProcess = 'FAILED_TO_OUTGOING_CALL'
              payload.data.currentProcess = payload.currentProcess
              payload.data.voip = {
                ...payload.data.voip,
                idCall: idCall,
                statusConnection: 'FAILED_TO_CONNECT',
                statusCall: 'DISCONNECTED',
                data: {
                  ...payload.data.voip.data,
                  rtc_connecting_finish_at: payload.data.voip.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
                },
                optAction: {
                  isRejectBeforeConnected: true,
                  rejectByThem: true,
                  rejectByUs: false
                },
                reasonCode: response.message.statusCode,
                reasonText: response.message.reasonPhrase
              }

              payload.updateValue(payload.data)

              nextTick(() => {
                payload.connecting.catchCallback(payload)
              })
            }
          }
        }

        // Configure : When Call is Connecting
        WindowUASession.delegate = {
          onBye: (sip) => {
            /**
              * Ongoing call is Ended (by nya sama siapa ??? belum tau)
              */
            payload.currentProcess = 'FAILED_TO_OUTGOING_CALL'
            payload.data.currentProcess = payload.currentProcess
            payload.data.voip = {
              ...payload.data.voip,
              idCall: idCall,
              statusConnection: 'CONNECTED',
              statusCall: 'CLOSED',
              data: {
                ...payload.data.voip.data,
                rtc_connecting_finish_at: payload.data.voip.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
              },
              optAction: {
                isRejectAfterConnected: true,
                rejectByThem: true,
                rejectByUs: false
              },
              reasonCode: 16,
              reasonText: 'Normal Call clearing'
            }

            payload.updateValue(payload.data)

            nextTick(() => {
              payload.connecting.catchCallback(payload)
            })
          }
        }

        // FINALLY : make a CALL
        WindowUASession.invite(inviterOptions).catch(e => {
          console.warn('Failed to send INVITE:', e)

          if (payload.data.voip) {
            if (payload.data.voip.statusCall === 'RINGING') {
              payload.currentProcess = 'FAILED_TO_OUTGOING_CALL'
              payload.data.currentProcess = payload.currentProcess
              payload.data.voip = {
                ...payload.data.voip,
                idCall: idCall,
                statusConnection: 'FAILED_TO_CONNECT',
                statusCall: 'DISCONNECTED',
                data: {
                  ...payload.data.voip.data,
                  rtc_connecting_finish_at: payload.data.voip.rtc_connecting_finish_at || moment().format('YYYY-MM-DD HH:mm:ss.SSS')
                },
                optAction: {
                  isRejectBeforeConnected: true,
                  rejectByThem: true,
                  rejectByUs: false
                },
                reasonCode: 500,
                reasonText: 'Failed to send INVITE:' + (e.message.reasonPhrase || '')
              }

              payload.updateValue(payload.data)

              nextTick(() => {
                payload.connecting.catchCallback(payload)
              })
            }
          }
        })

        //
        nextTick(() => {
          window.Swal.close()
        })
      }

      /**
       * @ (1) REGISTER ID CALL
       */
      const funcRegister = () => {
        if (getters.UAStatus !== 'CONNECTED') {
          respPrevent = { alert: Wording.voip.ua_is_not_connected }
        } else if (getters.UASessionStatus === 'NOT_READY') {
          respPrevent = { alert: Wording.voip.ua_session_is_not_ready }
        } else if (getters.UASessionStatus === 'BUSY') {
          respPrevent = { alert: Wording.voip.ua_session_is_busy }
        } else if (getters.userIsReady === false) {
          respPrevent = { alert: Wording.voip.no_user_ready }
        } else if (getters.isReadyCallResult) {
          respPrevent = { alert: Wording.voip.should_fill_call_result_first }
        }

        if (respPrevent !== false) {
          return callbackPrevent(respPrevent)
        }

        respPrevent = false

        //
        Gen.apiRest(
          '/crud/do/' + restApi + '/register-id-outgoing-call',
          {
            method: 'POST',
            data: payload.register.data
          }
        )
          .then(resp => {
            // balik ke 0
            payload.jmlRetry = 0
            payload.currentProcess = 'OUTGOING_CALL'
            payload.data.currentProcess = payload.currentProcess
            payload.data.isRegister = 'Y'

            payload.updateValue(payload.data)

            funcStartOutgoingCall({
              idCall: resp.data.response.id_call,
              destinationNumber: resp.data.response.destination_number,
              dataCall: resp.data.response
            })
          })
          .catch(err => {
            err = err.response
            if (err) {
              payload.currentProcess = 'FAILED_REGISTER_ID_CALL'
              return callbackPrevent({
                alert: {
                  ...Wording.errMessage.swalGen,
                  confirmButtonText: 'OK',
                  html: err.data.message
                }
              })
            }
          })
      }

      // RUNNING
      return funcRegister()
    },

    updatePredictiveQueue (state, payload = {}) {
      if (payload.type === 'READY_AGENT') {
        state.readyAgentsPredictive = payload.data
      } else {
        state.predictiveQueue = payload.data
      }
    },
    // END :: PREDICTIVE OUTBOUND //

    // STATIC METHODS //
    initiateCallResult (state, from) {
      if (from === 'PENDING_FORM_RESULT_AUTH') {
        // cause storage is cleared : we set the default value who were not recorded before
        const dtPending = window.pendingFormResultFromAuth || {}

        state.dataCallResult = Gen.reParsingJson({
          ...window.pendingFormResultFromAuth,
          ioType: dtPending.io_type,
          subIoType: dtPending.sub_io_type,
          caller_name: dtPending.customer_name,
          rtc_connecting_start_at: dtPending.rtc_connecting_start_at || dtPending.api_register_start_at,
          rtc_connecting_finish_at: dtPending.rtc_connecting_start_at ? dtPending.rtc_connecting_finish_at : dtPending.api_register_finish_at,
          optAction: {},
          type: 'CALLING',
          statusConnection: dtPending.status_connection || 'FAILED_TO_CONNECT',
          statusCall: dtPending.status_call || 'DISCONNECTED',
          reasonCode: 102,
          reasonText: 'STORAGE_IS_CLEARED',
          statusAddResult: 'READY',
          reasonAddResult: 'NOT_NORMAL',
          rejectWhen: dtPending.when_rejected || 'BEFORE_CONNECT',
          rejectBy: dtPending.type_rejected || 'THEM'
        })

        Gen.putStorage('dataCallResult', state.dataCallResult)

        this.dispatch('voip/openCallResult')

        nextTick(() => {
          window.pendingFormResultFromAuth = null
        })
      }
    },

    openCallResult (state) {
      state.openCallResult = true

      // update : agent
      this.dispatch('updateCurrentAgentStatus', {
        status: 'ACW'
      })
    },

    clearDataCallResult (state) {
      state.openCallResult = false
      state.dataCallResult = null
    },

    bindCallResult (state) {
      // eslint-disable-next-line no-self-assign
      state.openCallResult = state.openCallResult
      // eslint-disable-next-line no-self-assign
      state.dataCallResult = state.dataCallResult
    },

    updateSessionData (state, payload = {}) {
      if (
        payload && 
        state.UASessionData && 
        (state.UASessionData || {}).idCall === payload.idCall
      ) {
        state.UASessionData = {
          ...(state.UASessionData || {}),
          ...payload,
          data: {
            ...(state.UASessionData || {}).data || {},
            ...payload.data || {}
          },
          optAction: {
            ...(state.UASessionData || {}).optAction || {},
            ...payload.optAction || {}
          },
          feature: {
            ...(state.UASessionData || {}).feature || {},
            ...payload.feature || {}
          }
        }
      } else {
        state.UASessionData = null
      }
    },

    onTrackAddedEvent (state) {
      // Gets remote tracks
      // TODO: look at detecting video, so that UI switches to audio/video automatically.
      if (!window.UASession) return false
      const peerConnection = window.UASession.sessionDescriptionHandler.peerConnection

      const remoteAudioStream = new MediaStream()
      const localAudioStream = new MediaStream()

      peerConnection.getTransceivers().forEach((transceiver) => {
        // Add Media
        const receiver = transceiver.receiver
        if (receiver.track) {
          if (receiver.track.kind === 'audio') {
            remoteAudioStream.addTrack(receiver.track)
          }
        }

        const local = transceiver.sender
        if (local.track) {
          localAudioStream.addTrack(local.track)
        }
      })

      // Attach Audio
      if (remoteAudioStream.getAudioTracks().length >= 1) {
        const remoteAudio = new window.Audio()
        remoteAudio.autoplay = true
        remoteAudio.srcObject = remoteAudioStream

        remoteAudio.onloadedmetadata = (e) => {
          if (typeof remoteAudio.sinkId !== 'undefined') {
            remoteAudio.setSinkId('default').then(() => {
              console.log('sinkId applied: ' + 'default')
            }).catch(function (e) {
              console.warn('Error using setSinkId: ', e)
            })
          }
          remoteAudio.play()

          if ((state.UASessionData.data || {}).io_type === 'INBOUND') {
            const audioContext = new window.AudioContext()
            const audioIn01 = audioContext.createMediaStreamSource(localAudioStream)
            const audioIn02 = audioContext.createMediaStreamSource(remoteAudioStream)
            const dest = audioContext.createMediaStreamDestination()

            audioIn01.connect(dest)
            audioIn02.connect(dest)

            window.UASession.data.recorder = new Recorder(audioContext)
            window.UASession.data.recorder.init(dest.stream)
            window.UASession.data.recorder.start()

            console.log('start recording')
          }
        }
      }
    }
  },
  actions: {
    preventCall ({ getters }) {
      if (getters.UAStatus !== 'CONNECTED') {
        return window.Swal.fire(Wording.voip.ua_is_not_connected)
      } else if (getters.UASessionStatus === 'NOT_READY') {
        return window.Swal.fire(Wording.voip.ua_session_is_not_ready)
      } else if (getters.UASessionStatus === 'BUSY') {
        return window.Swal.fire(Wording.voip.ua_session_is_busy)
      } else if (
        getters.agentIsReady === false &&
        getters.userIsReady === false
      ) {
        return window.Swal.fire(Wording.voip.no_user_ready)
      } else if (getters.isReadyCallResult) {
        return window.Swal.fire(Wording.voip.should_fill_call_result_first)
      } else if (
        getters.hasPredictiveQueue &&
        getters.isCurrentTabPredictive === false
      ) {
        return window.Swal.fire(Wording.voip.there_is_ongoing_predictive)
      }

      return false
    },
    initial ({ state, commit, getters, rootGetters }, x = {}) {
      commit('initial', { x, getters, rootGetters })
      commit('initialDevice')
    },
    initiateIncomingCall ({ commit, getters, rootGetters }, payload = {}) {
      commit('initiateIncomingCall', { payload, getters, rootGetters })
    },
    startIncomingCall ({ commit, getters }, payload = {}) {
      commit('startIncomingCall', { payload, getters })
    },
    answerIncomingCall ({ commit, getters }, payload = {}) {
      commit('answerIncomingCall', { payload, getters })
    },
    endIncomingCall ({ commit, getters }, payload = {}) {
      commit('endIncomingCall', { payload, getters })
    },
    holdIncomingCall ({ commit, getters }) {
      commit('holdIncomingCall', { getters })
    },
    muteIncomingCall ({ commit, getters }) {
      commit('muteIncomingCall', { getters })
    },
    dropSessionIncomingCall ({ commit, getters }, payload = {}) {
      commit('dropSessionIncomingCall', { payload, getters })
    },
    initialBeforeHardClose ({ commit, getters, rootGetters }) {
      commit('initialBeforeHardClose', { getters, rootGetters })
    },
    startPredictiveCallPerData ({ commit, getters }, payload = {}) {
      commit('startPredictiveCallPerData', { payload, getters })
    },
    registerOutgoingCall ({ commit, getters }, payload = {}) {
      commit('registerOutgoingCall', { payload, getters })
    },
    startOutgoingCall ({ commit, getters }, payload = {}) {
      commit('startOutgoingCall', { payload, getters })
    },
    holdOutgoingCall ({ commit, getters }) {
      commit('holdOutgoingCall', { getters })
    },
    muteOutgoingCall ({ commit, getters }) {
      commit('muteOutgoingCall', { getters })
    },
    endOutgoingCall ({ commit, getters }) {
      commit('endOutgoingCall', { getters })
    },
    dropSessionOutgoingCall ({ commit, getters }, reason = 'NORMAL') {
      commit('dropSessionOutgoingCall', { reason, getters })
    },
    dropSessionMonitoringOutgoingCall ({ commit, getters }, reason = 'NORMAL') {
      commit('dropSessionMonitoringOutgoingCall', { reason, getters })
    },
    openCallResult ({ commit }) {
      commit('openCallResult')
    },
    clearDataCallResult ({ commit }) {
      commit('clearDataCallResult')
    },
    broadcastOutgoingCall ({ commit, getters }, data = {}) {
      commit('broadcastOutgoingCall', { data, getters })
    },
    monitoringOutgoingCall ({ commit, getters }, payload) {
      commit('monitoringOutgoingCall', { payload, getters })
    }
  }
}

export default moduleVoip
