<template>
<div id="app">
  <router-view/>
  <vue-progress-bar></vue-progress-bar>

  <PanelInboundAddResult v-if="loaded && typeInbound === 'INBOUND_BIASA'"
    :open="openResult"
    :data="dataResult"
    />
  <PanelAddResult v-else-if="loaded"
    :open="openResult"
    :data="dataResult"
    />

  <VoipInboundCall v-if="loaded"
    :open="hasOngoingInbound"
    />
</div>
</template>

<script>
import PanelAddResult from '@/components/VVoip/PanelAddResult.vue'
import PanelInboundAddResult from '@/components/VVoip/PanelInboundAddResult.vue'
import VoipInboundCall from '@/components/VVoip/PanelInboundCall.vue'

export default {
  components: {
    PanelAddResult,
    PanelInboundAddResult,
    VoipInboundCall
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    authenticated () {
      return this.$store.getters.authenticated
    },
    typeInbound () {
      return this.$store.getters['voip/typeInbound']
    },
    hasOngoingInbound () {
      return this.$store.getters['voip/hasOngoingInbound']
    },
    dataCallResult () {
      return this.$store.getters['voip/dataCallResult']
    },
    openCallResult () {
      return this.$store.state.voip.openCallResult
    },
    dataWhatsappResult () {
      return this.$store.state.whatsappTempResult
    },
    openWhatsappResult () {
      return this.$store.getters.openWhatsappResult
    },
    openResult () {
      return this.openCallResult || this.openWhatsappResult
    },
    typeOpenResult () {
      if (this.openCallResult) return 'CALL'
      else if (this.openWhatsappResult) return 'WHATSAPP'
      else return ''
    },
    dataResult () {
      if (this.openCallResult) {
        return this.dataCallResult
      } else if (this.openWhatsappResult) {
        return this.dataWhatsappResult
      } else {
        return {}
      }
    }
  },
  mounted () {
    if (this.authenticated) {
      // Voip : Starting connectino to voip server
      this.$store.dispatch('voip/initial')
    }

    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish()

    // cek data
    if (this.typeOpenResult === 'WHATSAPP') {
      if (this.$store.state.currentAgentStatus === 'ACW') {
        // to ready
        this.$store.dispatch('updateCurrentAgentStatus', {
          status: 'READY'
        })
      }

      this.$store.commit('update_whatsappTempResult', {})
    }

    //
    setTimeout(() => {
      if (this.authenticated) {
        this.loaded = true
      }
    }, 3 * 1000) // tunda 3 detik
  },
  created () {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      setTimeout(() => {
        this.$Progress.finish()
      }, 1000)
    })
  }
}
</script>
