export default [
  {
    path: ''
  },
  {
    path: 'login',
    name: 'BoLogin',
    component: () => import('@/views/BoLogin/IndexView.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty'
    }
  },

  {
    path: 'dashboard',
    name: 'BoDashboard',
    component: () => import('@/views/BoDashboard.vue'),
    meta: {
      generalMenu: true,
      menu: 'BoDashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard'
    }
  },
  {
    path: 'update-profile',
    name: 'BoUpdateProfile',
    component: () => import('@/views/BoUpdateProfile.vue'),
    meta: {
      generalMenu: true,
      menu: 'BoUpdateProfile',
      pageTitle: 'Update My Profile',
      objName: 'Update My Profile'
    }
  },
  {
    path: 'notification',
    name: 'BoNotification',
    component: () => import('@/views/BoNotification.vue'),
    meta: {
      generalMenu: true,
      menu: 'BoNotification',
      pageTitle: 'Notification',
      objName: 'Notification'
    }
  },

  {
    path: 'kpi-dashboard/:pageSlug?/:pageId?',
    name: 'BoKpiDashboard',
    component: () => import('@/views/BoKpiDashboard/IndexView.vue'),
    meta: {
      menu: 'BoKpiDashboard',
      pageTitle: 'KPI Dashboard',
      objName: 'KPI Dashboard'
    }
  },

  {
    path: 'setting/audit-trail',
    name: 'BoLogAuditTrail',
    component: () => import('@/views/BoLogAuditTrail/IndexView.vue'),
    meta: {
      menu: 'BoLogAuditTrail',
      pageTitle: 'Audit Trail',
      objName: 'Audit Trail'
    }
  },
  {
    path: 'setting/app-config',
    name: 'BoAppConfig',
    component: () => import('@/views/BoAppConfig/IndexView.vue'),
    meta: {
      menu: 'BoAppConfig',
      pageTitle: 'Konfigurasi App',
      objName: 'Konfigurasi'
    }
  },

  {
    path: 'setting/user/:pageSlug?/:pageId?',
    name: 'BoUser',
    component: () => import('@/views/BoUser/IndexView.vue'),
    meta: {
      menu: 'BoUser',
      pageTitle: 'User',
      objName: 'User'
    }
  },
  {
    path: 'setting/user-level/:pageSlug?/:pageId?',
    name: 'BoUserLevel',
    component: () => import('@/views/BoUserLevel/IndexView.vue'),
    meta: {
      menu: 'BoUserLevel',
      pageTitle: 'Access Level',
      objName: 'Access Level'
    }
  },

  {
    path: 'broadcast/sms/campaign/:pageSlug?/:pageId?',
    name: 'BoBroadcastSmsCampaign',
    component: () => import('@/views/BoBroadcast/BoBroadcastSms/BoBroadcastSmsCampaign/IndexView.vue'),
    meta: {
      menu: 'BoBroadcastSmsCampaign',
      pageTitle: 'Broadcast SMS Campaign',
      objName: 'Campaign'
    }
  },
  {
    path: 'broadcast/sms/template/:pageSlug?/:pageId?',
    name: 'BoBroadcastSmsTemplate',
    component: () => import('@/views/BoBroadcast/BoBroadcastSms/BoBroadcastSmsTemplate/IndexView.vue'),
    meta: {
      menu: 'BoBroadcastSmsTemplate',
      pageTitle: 'Broadcast SMS Template',
      objName: 'Template'
    }
  },
  {
    path: 'broadcast/email/campaign/:pageSlug?/:pageId?',
    name: 'BoBroadcastEmailCampaign',
    component: () => import('@/views/BoBroadcast/BoBroadcastEmail/BoBroadcastEmailCampaign/IndexView.vue'),
    meta: {
      menu: 'BoBroadcastEmailCampaign',
      pageTitle: 'Broadcast Email Campaign',
      objName: 'Campaign'
    }
  },
  {
    path: 'broadcast/email/template/:pageSlug?/:pageId?',
    name: 'BoBroadcastEmailTemplate',
    component: () => import('@/views/BoBroadcast/BoBroadcastEmail/BoBroadcastEmailTemplate/IndexView.vue'),
    meta: {
      menu: 'BoBroadcastEmailTemplate',
      pageTitle: 'Broadcast Email Template',
      objName: 'Template'
    }
  },
  {
    path: 'broadcast/whatsapp/campaign/:pageSlug?/:pageId?',
    name: 'BoBroadcastWhatsappCampaign',
    component: () => import('@/views/BoBroadcast/BoBroadcastWhatsapp/BoBroadcastWhatsappCampaign/IndexView.vue'),
    meta: {
      menu: 'BoBroadcastWhatsappCampaign',
      pageTitle: 'Broadcast Whatsapp Campaign',
      objName: 'Campaign'
    }
  },
  {
    path: 'broadcast/whatsapp/template/:pageSlug?/:pageId?',
    name: 'BoBroadcastWhatsappTemplate',
    component: () => import('@/views/BoBroadcast/BoBroadcastWhatsapp/BoBroadcastWhatsappTemplate/IndexView.vue'),
    meta: {
      menu: 'BoBroadcastWhatsappTemplate',
      pageTitle: 'Broadcast Whatsapp Template',
      objName: 'Template'
    }
  },

  {
    path: 'outbound/agent/campaign/:pageId?/:pageSlug?/:pageSubId?',
    name: 'BoAgentCampaign',
    component: () => import('@/views/BoOutbound/BoFUCampaign/IndexView.vue'),
    meta: {
      pageType: 'CAMPAIGN',
      menu: 'BoAgentCampaign',
      pageTitle: 'Campaign',
      objName: 'Campaign'
    }
  },
  {
    path: 'outbound/agent/loan/:pageId?/:pageSlug?/:pageSubId?',
    name: 'BoAgentCampaignLoan',
    component: () => import('@/views/BoCampaignLoan/IndexView.vue'),
    meta: {
      pageType: 'AGENT',
      menu: 'BoAgentCampaignLoan',
      pageTitle: 'Loan',
      objName: 'Loan'
    }
  },
  {
    path: 'outbound/campaign/:pageId?/:pageSlug?/:pageSubId?',
    name: 'BoManagerSpvCampaign',
    component: () => import('@/views/BoOutbound/BoListCampaign/IndexView.vue'),
    meta: {
      menu: 'BoManagerSpvCampaign',
      pageTitle: 'Campaign',
      objName: 'Campaign'
    }
  },
  {
    path: 'outbound/loan/:pageId?/:pageSlug?/:pageSubId?',
    name: 'BoManagerSpvCampaignLoan',
    component: () => import('@/views/BoCampaignLoan/IndexView.vue'),
    meta: {
      pageType: 'MANAGER_SPV',
      menu: 'BoManagerSpvCampaignLoan',
      pageTitle: 'Loan',
      objName: 'Loan'
    }
  },
  {
    path: 'outbound/whatsapp-panel/:pageSlug?/:pageId?',
    name: 'BoFollowUpWhatsapp',
    component: () => import('@/views/BoOutbound/BoWhatsappPanel/IndexView.vue'),
    meta: {
      panelType: 'FOLLOW_UP',
      menu: 'BoFollowUpWhatsapp',
      pageTitle: 'Whatsapp Panel',
      objName: 'Whatsapp Panel'
    }
  },
  {
    path: 'outbound/call-schedule/:pageSlug?/:pageId?',
    name: 'BoAgentCallSchedule',
    component: () => import('@/views/BoOutbound/BoCallSchedule/IndexView.vue'),
    meta: {
      menu: 'BoAgentCallSchedule',
      pageTitle: 'Call Schedule',
      objName: 'Call Schedule'
    }
  },

  {
    path: 'outbound/progressive-call/archived/:pageSlug?/:pageId?',
    name: 'BoProgressiveCallArchived',
    component: () => import('@/views/BoProgressiveCall/IndexView.vue'),
    meta: {
      pageType: 'ARCHIVED',
      menu: 'BoProgressiveCall',
      pageTitle: 'Progressive Call - Data Archived',
      objName: 'Progressive Call'
    }
  },
  {
    path: 'outbound/progressive-call/:pageSlug?/:pageId?',
    name: 'BoProgressiveCall',
    component: () => import('@/views/BoProgressiveCall/IndexView.vue'),
    meta: {
      pageType: 'PLAYGROUND',
      menu: 'BoProgressiveCall',
      pageTitle: 'Progressive Call',
      objName: 'Progressive Call'
    }
  },

  {
    path: 'inbound/customer/:pageSlug?/:pageId?',
    name: 'BoInboundCustomer',
    component: () => import('@/views/BoInbound/BoInboundCustomer/IndexView.vue'),
    meta: {
      menu: 'BoInboundCustomer',
      pageTitle: 'Inbound Customers & Contacts',
      objName: 'Customer & Contact'
    }
  },
  {
    path: 'inbound/ticket/:pageSlug?/:pageId?',
    name: 'BoInboundTicket',
    component: () => import('@/views/BoInbound/BoInboundTicket/IndexView.vue'),
    meta: {
      menu: 'BoInboundTicket',
      pageTitle: 'Inbound Tickets',
      objName: 'Ticket'
    }
  },

  {
    path: 'campaign/:pageSlug?/:pageId?',
    name: 'BoCampaign',
    component: () => import('@/views/BoCampaign/IndexView.vue'),
    meta: {
      pageType: 'ADMIN',
      menu: 'BoCampaign',
      pageTitle: 'Campaign',
      objName: 'Campaign'
    }
  },
  {
    path: 'campaign/loan/:pageSlug?/:pageId?',
    name: 'BoCampaignLoan',
    component: () => import('@/views/BoCampaignLoan/IndexView.vue'),
    meta: {
      menu: 'BoCampaignLoan',
      pageTitle: 'Loan',
      objName: 'Loan'
    }
  },

  {
    path: 'campaign/predictive-call/archived/:pageSlug?/:pageId?',
    name: 'BoPredictiveCallArchived',
    component: () => import('@/views/BoPredictiveCall/IndexView.vue'),
    meta: {
      pageType: 'ARCHIVED',
      menu: 'BoPredictiveCall',
      pageTitle: 'Predictive Call - Data Archived',
      objName: 'Predictive Call'
    }
  },
  {
    path: 'campaign/predictive-call/:pageSlug?/:pageId?',
    name: 'BoPredictiveCall',
    component: () => import('@/views/BoPredictiveCall/IndexView.vue'),
    meta: {
      pageType: 'PLAYGROUND',
      menu: 'BoPredictiveCall',
      pageTitle: 'Predictive Call',
      objName: 'Predictive Call'
    }
  },

  {
    path: 'payment-result/:pageSlug?/:pageSubSlug?/:pageId?',
    name: 'BoPaymentResult',
    component: () => import('@/views/BoPaymentResult/IndexView.vue'),
    meta: {
      menu: 'BoPaymentResult',
      pageTitle: 'Payment Result',
      objName: 'Payment Result'
    }
  },

  {
    path: 'raw-data/campaign/list-loan',
    name: 'BoRawDataListLoan',
    component: () => import('@/views/BoRawData/IndexView.vue'),
    meta: {
      menu: 'BoRawData',
      pageTitle: 'Data Raw',
      objName: 'Campaign'
    }
  },
  {
    path: 'raw-data/campaign/:pageSlug?/:pageId?',
    name: 'BoRawData',
    component: () => import('@/views/BoRawData/IndexView.vue'),
    meta: {
      menu: 'BoRawData',
      pageTitle: 'Data Raw',
      objName: 'Campaign'
    }
  },
  
  {
    path: 'monitoring/agent/:pageSlug?/:pageId?',
    name: 'BoMonitoringAgent',
    component: () => import('@/views/BoMonitoring/BoMonitoringAgent/IndexView.vue'),
    meta: {
      menu: 'BoMonitoringAgent',
      pageTitle: 'Agent Monitoring',
      objName: 'Agent'
    }
  },
  {
    path: 'monitoring/call/:pageSlug?/:pageId?',
    name: 'BoMonitoringCall',
    component: () => import('@/views/BoMonitoring/BoMonitoringCall/IndexView.vue'),
    meta: {
      menu: 'BoMonitoringCall',
      pageTitle: 'Call Monitoring',
      objName: 'Call'
    }
  },
  {
    path: 'monitoring/whatsapp/:pageSlug?/:pageId?',
    name: 'BoMonitoringWhatsapp',
    component: () => import('@/views/BoOutbound/BoWhatsappPanel/IndexView.vue'),
    meta: {
      panelType: 'READ_ONLY',
      menu: 'BoMonitoringWhatsapp',
      pageTitle: 'Mointoring Whatsapp',
      objName: 'Mointoring Whatsapp'
    }
  },

  {
    path: 'report/log-assignment-data/:pageSlug?/:pageId?',
    name: 'BoReportLogAssignment',
    component: () => import('@/views/BoReport/BoReportLogAssignment/IndexView.vue'),
    meta: {
      menu: 'BoReportLogAssignment',
      pageTitle: 'Assignment Data Log',
      objName: 'Assignment Data Log'
    }
  },
  {
    path: 'report/productivity-agent/:pageSlug?/:pageId?',
    name: 'BoReportLogProductivityAgent',
    component: () => import('@/views/BoReport/BoReportLogProductivityAgent/IndexView.vue'),
    meta: {
      panelType: 'READ_ONLY',
      menu: 'BoReportLogProductivityAgent',
      pageTitle: 'Mointoring Whatsapp',
      objName: 'Mointoring Whatsapp'
    }
  },
  {
    path: 'report/log-call/:pageSlug?/:pageId?',
    name: 'BoReportLogCall',
    component: () => import('@/views/BoReport/BoReportLogCall/IndexView.vue'),
    meta: {
      panelType: 'READ_ONLY',
      menu: 'BoReportLogCall',
      pageTitle: 'Call Log',
      objName: 'Call Log'
    }
  },
  {
    path: 'report/log-whatsapp/:pageSlug?/:pageId?',
    name: 'BoReportLogWhatsapp',
    component: () => import('@/views/BoReport/BoReportLogWhatsapp/IndexView.vue'),
    meta: {
      panelType: 'READ_ONLY',
      menu: 'BoReportLogWhatsapp',
      pageTitle: 'Whatsapp Log',
      objName: 'Whatsapp Log'
    }
  },
  {
    path: 'report/proof-payment/:pageSlug?/:pageId?',
    name: 'BoReportLogProofPayment',
    component: () => import('@/views/BoReport/BoReportLogProofPayment/IndexView.vue'),
    meta: {
      menu: 'BoReportLogProofPayment',
      pageTitle: 'Report Proof Payment',
      objName: 'Proof Payment'
    }
  },
  {
    path: 'report/payment-result/:pageSlug?/:pageId?',
    name: 'BoReportLogPaymentResult',
    component: () => import('@/views/BoReport/BoReportLogPaymentResult/IndexView.vue'),
    meta: {
      menu: 'BoReportLogPaymentResult',
      pageTitle: 'Report Payment Result',
      objName: 'Payment Result'
    }
  },
  {
    path: 'report/qa/:pageSlug?/:pageId?',
    name: 'BoReportLogQA',
    component: () => import('@/views/BoReport/BoReportLogQA/IndexView.vue'),
    meta: {
      menu: 'BoReportLogQA',
      pageTitle: 'Report QA',
      objName: 'QA'
    }
  },
  {
    path: 'report/broadcast-summary/:pageSlug?/:pageId?',
    name: 'BoReportBroadcastSummary',
    component: () => import('@/views/BoReport/BoReportBroadcastSummary/IndexView.vue'),
    meta: {
      menu: 'BoReportBroadcastSummary',
      pageTitle: 'Report Broadcast Summary',
      objName: 'Broadcast Summary'
    }
  },
  {
    path: 'report/broadcast-sms/:pageSlug?/:pageId?',
    name: 'BoReportBroadcastSMS',
    component: () => import('@/views/BoReport/BoReportBroadcastSMS/IndexView.vue'),
    meta: {
      menu: 'BoReportBroadcastSMS',
      pageTitle: 'Report Broadcast SMS',
      objName: 'Broadcast SMS'
    }
  },
  {
    path: 'report/broadcast-email/:pageSlug?/:pageId?',
    name: 'BoReportBroadcastEmail',
    component: () => import('@/views/BoReport/BoReportBroadcastEmail/IndexView.vue'),
    meta: {
      menu: 'BoReportBroadcastEmail',
      pageTitle: 'Report Broadcast Email',
      objName: 'Broadcast Email'
    }
  },
  {
    path: 'report/broadcast-whatsapp/:pageSlug?/:pageId?',
    name: 'BoReportBroadcastWhatsapp',
    component: () => import('@/views/BoReport/BoReportBroadcastWhatsapp/IndexView.vue'),
    meta: {
      menu: 'BoReportBroadcastWhatsapp',
      pageTitle: 'Report Broadcast Whatsapp',
      objName: 'Broadcast Whatsapp'
    }
  },

  {
    path: 'setting/outbound-management/call/progressive-call/:pageSlug?/:pageId?',
    name: 'BoOutboundProgressiveCallSetting',
    component: () => import('@/views/BoIOBoundManagement/BoProgressiveCallSetting/IndexView.vue'),
    meta: {
      menu: 'BoOutboundProgressiveCallSetting',
      methodType: 'PROGRESSIVE',
      pageTitle: 'Progressive Call',
      objName: 'Progressive Call'
    }
  },
  {
    path: 'setting/outbound-management/call/predictive-call/:pageSlug?/:pageId?',
    name: 'BoOutboundPredictiveCallSetting',
    component: () => import('@/views/BoIOBoundManagement/BoPredictiveCallSetting/IndexView.vue'),
    meta: {
      menu: 'BoOutboundPredictiveCallSetting',
      methodType: 'PROGRESSIVE',
      pageTitle: 'Progressive Call',
      objName: 'Progressive Call'
    }
  },
  {
    path: 'setting/outbound-management/call/follow-up-status/:pageSlug?/:pageId?',
    name: 'BoOutCallFollowUpStatus',
    component: () => import('@/views/BoIOBoundManagement/BoFollowUpStatus/IndexView.vue'),
    meta: {
      menu: 'BoOutboundFollowUpStatus',
      ioType: 'OUTBOUND',
      lineType: 'CALL',
      pageTitle: 'Call Status',
      objName: 'Call Status'
    }
  },
  {
    path: 'setting/outbound-management/call/assigned-follow-up-status/:pageSlug?/:pageId?',
    name: 'BoOutCallAssignedFollowUpStatus',
    component: () => import('@/views/BoIOBoundManagement/BoAssignedFollowUpStatus/IndexView.vue'),
    meta: {
      menu: 'BoOutboundAssignedFollowUpStatus',
      ioType: 'OUTBOUND',
      lineType: 'CALL',
      pageTitle: 'Assigned Call Status',
      objName: 'Assigned Call Status'
    }
  },
  {
    path: 'setting/outbound-management/whatsapp/follow-up-status/:pageSlug?/:pageId?',
    name: 'BoOutWaFollowUpStatus',
    component: () => import('@/views/BoIOBoundManagement/BoFollowUpStatus/IndexView.vue'),
    meta: {
      menu: 'BoOutboundFollowUpStatus',
      ioType: 'OUTBOUND',
      lineType: 'WHATSAPP',
      pageTitle: 'Whatsapp Status',
      objName: 'Whatsapp Status'
    }
  },
  {
    path: 'setting/outbound-management/whatsapp/assigned-follow-up-status/:pageSlug?/:pageId?',
    name: 'BoOutWaAssignedFollowUpStatus',
    component: () => import('@/views/BoIOBoundManagement/BoAssignedFollowUpStatus/IndexView.vue'),
    meta: {
      menu: 'BoOutboundAssignedFollowUpStatus',
      ioType: 'OUTBOUND',
      lineType: 'WHATSAPP',
      pageTitle: 'Assigned Whatsapp Status',
      objName: 'Assigned Whatsapp Status'
    }
  },

  {
    path: 'setting/inbound-management/follow-up-status/:pageSlug?/:pageId?',
    name: 'BoInboundFollowUpStatus',
    component: () => import('@/views/BoIOBoundManagement/BoFollowUpStatus/IndexView.vue'),
    meta: {
      menu: 'BoInboundFollowUpStatus',
      ioType: 'INBOUND',
      lineType: 'CALL',
      pageTitle: 'Call Status',
      objName: 'Call Status'
    }
  },
  {
    path: 'setting/inbound-management/assigned-follow-up-status/:pageSlug?/:pageId?',
    name: 'BoInboundAssignedFollowUpStatus',
    component: () => import('@/views/BoIOBoundManagement/BoAssignedFollowUpStatus/IndexView.vue'),
    meta: {
      menu: 'BoInboundAssignedFollowUpStatus',
      ioType: 'INBOUND',
      lineType: 'CALL',
      pageTitle: 'Assigned Call Status',
      objName: 'Assigned Call Status'
    }
  },

  {
    path: 'setting/whatsapp/devices/:pageId?/:pageSlug?/:pageSubId?',
    name: 'BoWhatsappDevices',
    component: () => import('@/views/BoWhatsapp/BoWhatsappDevices/IndexView.vue'),
    meta: {
      menu: 'BoWhatsappDevices',
      pageTitle: 'Whatsapp Devices',
      objName: 'Whatsapp Device'
    }
  },
  {
    path: 'setting/whatsapp/phones/:pageId?/:pageSlug?/:pageSubId?',
    name: 'BoWhatsappPhones',
    component: () => import('@/views/BoWhatsapp/BoWhatsappPhones/IndexView.vue'),
    meta: {
      menu: 'BoWhatsappPhones',
      pageTitle: 'Whatsapp Phones',
      objName: 'Whatsapp Phone'
    }
  },

  {
    path: 'setting/master/agent-status/:pageSlug?/:pageId?',
    name: 'BoMrAgentStatus',
    component: () => import('@/views/BoMaster/BoMrAgentStatus/IndexView.vue'),
    meta: {
      menu: 'BoMrAgentStatus',
      pageTitle: 'Agent Status',
      objName: 'Agent Status'
    }
  },
  {
    path: 'setting/master/department/:pageSlug?/:pageId?',
    name: 'BoMrDepartement',
    component: () => import('@/views/BoMaster/BoMrDepartement/IndexView.vue'),
    meta: {
      menu: 'BoMrDepartement',
      pageTitle: 'Department',
      objName: 'Department'
    }
  },
  {
    path: 'setting/master/branch/:pageSlug?/:pageId?',
    name: 'BoMrBranch',
    component: () => import('@/views/BoMaster/BoMrBranch/IndexView.vue'),
    meta: {
      menu: 'BoMrBranch',
      pageTitle: 'Branch',
      objName: 'Branch'
    }
  },
  {
    path: 'setting/master/client/:pageSlug?/:pageId?',
    name: 'BoMrClient',
    component: () => import('@/views/BoMaster/BoMrClient/IndexView.vue'),
    meta: {
      menu: 'BoMrClient',
      pageTitle: 'Client',
      objName: 'Client'
    }
  },
  {
    path: 'setting/master/kpi/:pageSlug?/:pageId?',
    name: 'BoMrKpiSetting',
    component: () => import('@/views/BoMaster/BoMrKpiSetting/IndexView.vue'),
    meta: {
      menu: 'BoMrKpiSetting',
      pageTitle: 'KPI Settings',
      objName: 'KPI Settings'
    }
  },
  {
    path: 'setting/master/default-working-time/:pageSlug?/:pageId?',
    name: 'BoMrDefaultWorkingTime',
    component: () => import('@/views/BoMaster/BoMrDefaultWorkingTime/IndexView.vue'),
    meta: {
      menu: 'BoMrDefaultWorkingTime',
      pageTitle: 'Default Working Time',
      objName: 'Default Working Time'
    }
  },
  {
    path: 'setting/master/update-call-script/:pageSlug?/:pageId?',
    name: 'BoMrUpdateCallScript',
    component: () => import('@/views/BoMaster/BoMrUpdateCallScript/IndexView.vue'),
    meta: {
      menu: 'BoMrUpdateCallScript',
      pageTitle: 'Update Call Script',
      objName: 'Update Call Script'
    }
  },

  {
    path: 'qa/follow-up/:pageSlug?/:pageId?',
    name: 'BoQaResult',
    component: () => import('@/views/BoQA/BoQaResult/IndexView.vue'),
    meta: {
      menu: 'BoQaResult',
      pageTitle: 'QA Result',
      objName: 'QA Result'
    }
  },
  {
    path: 'qa/setting/whatsapp-indicator/:pageSlug?/:pageId?',
    name: 'BoMrQaWhatsappIndicator',
    component: () => import('@/views/BoQA/BoSettings/BoMrQaWhatsappIndicator/IndexView.vue'),
    meta: {
      menu: 'BoMrQaWhatsappIndicator',
      pageTitle: 'Whatsapp Indicator',
      objName: 'Whatsapp Indicator'
    }
  },
  {
    path: 'qa/setting/call-indicator/:pageSlug?/:pageId?',
    name: 'BoMrQaCallIndicator',
    component: () => import('@/views/BoQA/BoSettings/BoMrQaCallIndicator/IndexView.vue'),
    meta: {
      menu: 'BoMrQaCallIndicator',
      pageTitle: 'Call Indicator',
      objName: 'Call Indicator'
    }
  },
  {
    path: 'qa/setting/label-scoring-result/:pageSlug?/:pageId?',
    name: 'BoMrQaLabelScoringResult',
    component: () => import('@/views/BoQA/BoSettings/BoMrQaLabelScoringResult/IndexView.vue'),
    meta: {
      menu: 'BoMrQaLabelScoringResult',
      pageTitle: 'Label Scoring Result',
      objName: 'Label Scoring Result'
    }
  },
  {
    path: 'qa/setting/case-result/:pageSlug?/:pageId?',
    name: 'BoMrQaCaseResult',
    component: () => import('@/views/BoQA/BoSettings/BoMrQaCaseResult/IndexView.vue'),
    meta: {
      menu: 'BoMrQaCaseResult',
      pageTitle: 'Case Result',
      objName: 'Case Result'
    }
  },
  {
    path: 'qa/setting/indicator-scoring/:pageSlug?/:pageId?',
    name: 'BoMrQaIndicatorScoring',
    component: () => import('@/views/BoQA/BoSettings/BoMrQaIndicatorScoring/IndexView.vue'),
    meta: {
      menu: 'BoMrQaIndicatorScoring',
      pageTitle: 'Indicator Scoring',
      objName: 'Indicator Scoring'
    }
  },
  {
    path: 'qa/setting/indicator-group/:pageSlug?/:pageId?',
    name: 'BoMrQaIndicatorGroup',
    component: () => import('@/views/BoQA/BoSettings/BoMrQaIndicatorGroup/IndexView.vue'),
    meta: {
      menu: 'BoMrQaIndicatorGroup',
      pageTitle: 'Indicator Group',
      objName: 'Indicator Group'
    }
  }
]
