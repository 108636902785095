<template>
<b-modal :modelValue="props.open" 
  :hide-header-close="lineType === 'CALL'"
  @hidden="onClose"
  hide-footer no-close-on-backdrop no-close-on-esc size="lg" :title="'Add ' + lineLabel + ' Result'">
  <div v-if="props.open">
    <PanelBusy v-if="formConf.getting" />
    <Form v-else @submit="submitForm" @invalid-submit="submitForm" ref="VForm">
      <div class="mb-4">
        <div class="wrap_preview p-4 mb-2">
          <div class="row gx-2 gy-2">
            <div class="col-sm-3">
              <div class="preview_data">
                <h3>Customer Name</h3>
                <p>{{ data.customer_name }}</p>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="preview_data">
                <h3>Agreement No</h3>
                <p>{{ data.external_loan_no }}</p>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="preview_data">
                <h3>Product</h3>
                <p>{{ data.product }}</p>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-6">
              <div class="preview_data">
                <h3>Phone Category</h3>
                <p>{{ data.phone_category }}</p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="preview_data">
                <h3>Phone Number</h3>
                <p>{{ data.phone_no }}</p>
              </div>
            </div>
            <template v-if="startedAt && endedAt && lineType === 'CALL'">
              <div class="col-sm-4">
                <div class="preview_data">
                  <h3>Started At</h3>
                  <p>{{ dateFormat(startedAt, 'DD MMM YYYY HH:mm:ss.SSS') }}</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="preview_data">
                  <h3>Ended At</h3>
                  <p>{{ dateFormat(endedAt, 'DD MMM YYYY HH:mm:ss.SSS') }}</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="preview_data">
                  <h3>Duration</h3>
                  <p>{{ duration }}</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="row gx-2 gy-2">
        <div class="col-md-4">
          <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.contact_type[0] }} <small v-if="hasRule(rules, 'contact_type', 'required')" class="text-danger">*</small></label>
          <vSelect 
            :options="Master.mrContacType"
            v-model="input.contact_type"
            label="text"
            :reduce="(v) => v.value"
            :clearable="false"
            placeholder="-- Select Group --"  />
          <VValidate
            v-model="input.contact_type"
            :label="rules.contact_type[0]"
            :rules="rules.contact_type[1]" />
        </div>
        <div v-if="input.contact_type" class="col-md-8">
          <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.id_followup_status[0] }} <small v-if="hasRule(rules, 'id_followup_status', 'required')" class="text-danger">*</small></label>
          <vSelect 
            :options="fileMrFollowUpStatus"
            v-model="input.id_followup_status"
            label="text"
            :reduce="(v) => v.value"
            :clearable="false"
            placeholder="-- Select Follow Up Status --"  />
          <VValidate
            v-model="input.id_followup_status"
            :label="rules.id_followup_status[0]"
            :rules="rules.id_followup_status[1]" />
        </div>

        <template v-if="input.id_followup_status">

          <template v-if="selectedFuStatus.static_slug === 'PAID'">
            <div class="clearfix"></div>

            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.fu_paid_amount[0] }} <small v-if="hasRule(rules, 'fu_paid_amount', 'required')" class="text-danger">*</small></label>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text>Rp</b-input-group-text>
                </template>
                <vMoney 
                  v-model.lazy="input.fu_paid_amount" 
                  class="form-control"
                  v-bind="moneyConf" />
              </b-input-group>
              <VValidate
                :modelValue="input.fu_paid_amount > 0 ? 1 : null"
                :label="rules.fu_paid_amount[0]"
                :rules="rules.fu_paid_amount[1]" />
            </div>

            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.fu_paid_date[0] }} <small v-if="hasRule(rules, 'fu_paid_date', 'required')" class="text-danger">*</small></label>
              <b-input-group>
                <flatPickr 
                  v-model="input.fu_paid_date" 
                  placeholder="YYYY-MM-DD"
                  class="form-control" />
                <template #append>
                  <b-input-group-text>
                    <i class="ti ti-calendar ti-sm"></i>
                  </b-input-group-text>
                </template>
              </b-input-group>
              <VValidate
                v-model="input.fu_paid_date"
                :label="rules.fu_paid_date[0]"
                :rules="rules.fu_paid_date[1]" />
            </div>

            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.fu_paid_via[0] }} <small v-if="hasRule(rules, 'fu_paid_via', 'required')" class="text-danger">*</small></label>
              <input type="text" class="form-control" v-model="input.fu_paid_via" />
              <VValidate
                v-model="input.fu_paid_via"
                :label="rules.fu_paid_via[0]"
                :rules="rules.fu_paid_via[1]" />
            </div>
          </template>

          <template v-else-if="selectedFuStatus.static_slug === 'PTP'">
            <div class="clearfix"></div>

            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.fu_ptp_amount[0] }} <small v-if="hasRule(rules, 'fu_ptp_amount', 'required')" class="text-danger">*</small></label>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text>Rp</b-input-group-text>
                </template>
                <vMoney 
                  v-model.lazy="input.fu_ptp_amount" 
                  class="form-control"
                  v-bind="moneyConf" />
              </b-input-group>
              <VValidate
                :modelValue="input.fu_ptp_amount > 0 ? 1 : null"
                :label="rules.fu_ptp_amount[0]"
                :rules="rules.fu_ptp_amount[1]" />
            </div>

            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.fu_ptp_date[0] }} <small v-if="hasRule(rules, 'fu_ptp_date', 'required')" class="text-danger">*</small></label>
              <b-input-group>
                <flatPickr 
                  v-model="input.fu_ptp_date" 
                  placeholder="YYYY-MM-DD"
                  class="form-control" />
                <template #append>
                  <b-input-group-text>
                    <i class="ti ti-calendar ti-sm"></i>
                  </b-input-group-text>
                </template>
              </b-input-group>
              <VValidate
                v-model="input.fu_ptp_date"
                :label="rules.fu_ptp_date[0]"
                :rules="rules.fu_ptp_date[1]" />
            </div>

            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.fu_ptp_via[0] }} <small v-if="hasRule(rules, 'fu_ptp_via', 'required')" class="text-danger">*</small></label>
              <input type="text" class="form-control" v-model="input.fu_ptp_via" />
              <VValidate
                v-model="input.fu_ptp_via"
                :label="rules.fu_ptp_via[0]"
                :rules="rules.fu_ptp_via[1]" />
            </div>
          </template>
          
          <template v-if="additionalFields.length">
            <div class="clearfix"></div>

            <template v-for="(item, key) in additionalFields" :key="key">
              <div class="col-md-4">
                <label class="mb-1 text-body-2 text-high-emphasis">
                  {{ item.label }} 
                  <small v-if="item.is_required" class="text-danger">*</small>
                  <small v-else class="text-secondar">(opsional)</small>
                </label>

                <template v-if="item.type === 'dropdown'">
                  <vSelect 
                    :options="item.opt_lists"
                    v-model="input.fu_additonal_data[item.fieldKey]"
                    :clearable="false"
                    placeholder="-- Select Options --"  />
                </template>
                <template v-else-if="item.type === 'date'">
                  <b-input-group>
                    <flatPickr 
                      v-model="input.fu_additonal_data[item.fieldKey]" 
                      placeholder="YYYY-MM-DD"
                      class="form-control" />
                    <template #append>
                      <b-input-group-text>
                        <i class="ti ti-calendar ti-sm"></i>
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                </template>
                <template v-else-if="item.type === 'textarea'">
                  <textarea class="form-control" v-model="input.fu_additonal_data[item.fieldKey]"></textarea>
                </template>
                <template v-else>
                  <input type="text" class="form-control" v-model="input.fu_additonal_data[item.fieldKey]" />
                </template>

                <VValidate v-if="item.is_required"
                  :modelValue="(
                    item.type === 'dropdown'  
                      ? ( input.fu_additonal_data[item.fieldKey].length ? 1 : null ) 
                      : input.fu_additonal_data[item.fieldKey]
                  )"
                  :label="item.label"
                  rules="required" />
              </div>
            </template>
          </template>

          <div class="clearfix"></div>

          <div class="col-md-8">
            <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.fu_remarks[0] }} <small v-if="hasRule(rules, 'fu_remarks', 'required')" class="text-danger">*</small></label>
            <textarea v-model="input.fu_remarks" class="form-control"></textarea>
            <VValidate
              v-model="input.fu_remarks"
              :label="rules.fu_remarks[0]"
              :rules="rules.fu_remarks[1]" />
          </div>

          <div v-if="lineType === 'CALL'" class="col-md-12">
            <b-form-checkbox v-model="input.has_call_schedule">
              {{ rules.has_call_schedule[0] }}
            </b-form-checkbox>
          </div>

          <template v-if="input.has_call_schedule">
            <div class="clearfix"></div>

            <div class="col-md-6">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.call_schedule_date[0] }} <small v-if="hasRule(rules, 'call_schedule_date', 'required')" class="text-danger">*</small></label>
              <b-input-group>
                <flatPickr 
                  v-model="input.call_schedule_date" 
                  :config="{ minDate: moment().add(1, 'days').format('YYYY-MM-DD') }"
                  placeholder="YYYY-MM-DD"
                  class="form-control" />
                <template #append>
                  <b-input-group-text>
                    <i class="ti ti-calendar ti-sm"></i>
                  </b-input-group-text>
                </template>
              </b-input-group>
              <VValidate
                v-model="input.call_schedule_date"
                :label="rules.call_schedule_date[0]"
                :rules="rules.call_schedule_date[1]" />
            </div>

            <div class="col-md-6">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.call_schedule_time[0] }} <small v-if="hasRule(rules, 'call_schedule_time', 'required')" class="text-danger">*</small></label>
              <b-input-group>
                <b-form-input
                  v-model="input.call_schedule_time"
                  type="time" />
                <template #append>
                  <b-input-group-text>
                    <i class="ti ti-clock ti-sm"></i>
                  </b-input-group-text>
                </template>
              </b-input-group>
              <VValidate
                v-model="input.call_schedule_time"
                :label="rules.call_schedule_time[0]"
                :rules="rules.call_schedule_time[1]" />
            </div>

          </template>

        </template>
      </div>
      <div class="text-right mt-4">
        <button class="btn btn-primary">Submit Result</button>
      </div>
    </Form>
  </div>
</b-modal>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, computed, ref, reactive, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { Form, formIsValid, focusToError, hasRule } from '@/libs/veeValidate.js'
import { Gen, Wording, Master } from '@/libs/Gen.js'

import VValidate from '@/components/VValidate.vue'
import PanelBusy from '@/components/PanelBusy.vue'
import _ from '@/libs/lodash.js'
import { moment, dateFormat } from '@/libs/moment.js'

import vSelect from '@/libs/vSelect.js'
import flatPickr from 'vue-flatpickr-component'
import { Money3Component as vMoney } from 'v-money3'

const moneyConf = {
  decimal: ',',
  thousands: '.',
  prefix: '',
  suffix: '',
  precision: 0,
  allowBlank: true,
  masked: false
}

// initiate
const restApi = 'BoCampaignFollowUp'
const store = useStore()
const emits = defineEmits([
  'update:open'
])
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  data: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

// variables
const data = ref(props.data)

const lineType = computed(() => {
  return data.value.line_type
})

const lineLabel = computed(() => {
  return lineType.value === 'CALL' ? 'Call' : 'Whatsapp'
})

const startedAt = computed(() => {
  if (data.value.rtc_ongoing_start_at && data.value.rtc_ongoing_finish_at) {
    return data.value.rtc_ongoing_start_at
  } else {
    return data.value.rtc_connecting_start_at
  }
})

const endedAt = computed(() => {
  if (data.value.rtc_ongoing_start_at && data.value.rtc_ongoing_finish_at) {
    return data.value.rtc_ongoing_finish_at
  } else {
    return data.value.rtc_connecting_finish_at
  }
})

const duration = computed(() => {
  const c = startedAt.value
  const d = endedAt.value
  const e = moment.duration(moment(d).diff(c))
  return (
    String(e.hours()).padStart(2, '0') + ':' + 
    String(e.minutes()).padStart(2, '0') + ':' + 
    String(e.seconds()).padStart(2, '0')
  )
})

// variables forms
const VForm = ref(null)

const rules = {
  contact_type: ['Follow Up Type', 'required'],
  id_followup_status: ['Follow Up Status', 'required'],
  fu_remarks: ['Notes', ''],
  fu_paid_amount: ['Paid Amount', 'required'],
  fu_paid_date: ['Paid Date', 'required'],
  fu_paid_via: ['Paid Via', 'required'],
  fu_ptp_amount: ['PTP Amount', 'required'],
  fu_ptp_date: ['PTP Date', 'required'],
  fu_ptp_via: ['PTP Via', 'required'],
  has_call_schedule: ['Need Further Follow-up?', ''],
  call_schedule_date: ['Schedule Date', 'required'],
  call_schedule_time: ['Schedule Time', 'required']
}

const formConf = reactive({
  getting: true,
  sending: false
})

const input = ref({
  contact_type: null,
  id_followup_status: null,
  fu_additonal_data: {},
  fu_remarks: null,
  has_call_schedule: false,
  call_schedule_date: null,
  call_schedule_time: null
})

const mrFollowUpStatus = ref([])

const fileMrFollowUpStatus = computed(() => {
  return _.filter(mrFollowUpStatus.value, { contact_type: input.value.contact_type })
})

const selectedFuStatus = computed(() => {
  return _.find(mrFollowUpStatus.value, { value: input.value.id_followup_status }) || {}
})

const additionalFields = computed(() => {
  if (input.value.id_followup_status) {
    const fields = selectedFuStatus.value.additional_fields || []

    return _.map(fields, v => {
      v.fieldKey = (v.label || '').toLowerCase()
      return v
    })
  } else {
    return []
  }
})

// methods
function openForm () {
  formConf.getting = true

  Gen.apiRest(
    '/crud/get/' + restApi + '/form-add-result',
    {
      method: 'POST',
      data: {
        id_client: data.value.id_client,
        line_type: data.value.line_type
      }
    }
  )
    .then(resp => {
      mrFollowUpStatus.value = resp.data.mrFollowUpStatus

      nextTick(() => {
        formConf.getting = false
      })
    })
    .catch(err => {
      err = err.response
      window.Swal.fire({
        ...Wording.errMessage.swalGen,
        html: err.data.message
      })
    })
}

function onClose () {
  if (lineType.value === 'WHATSAPP') {
    store.dispatch('updateCurrentAgentStatus', {
      status: 'READY'
    })
    store.commit('update_whatsappTempResult', {})
  }
}

function submitForm () {
  if (formIsValid(VForm)) {
    formConf.sending = true
    const vLineType = lineType.value
    const vCallingMethod = data.value.calling_method

    let queueStillActive = 'N'

    if (vLineType === 'CALL' && vCallingMethod === 'PROGRESSIVE' && store.getters['voip/hasProgressiveQueue']) {
      queueStillActive = 'Y'
    }

    Gen.loadingGlobal()

    Gen.apiRest(
      '/crud/do/' + restApi + '/' + (vLineType === 'CALL' ? 'add-result-outgoing-call' : 'add-result-outgoing-whatsapp'),
      {
        data: {
          ...data.value,
          ...input.value,
          has_call_schedule: input.value.has_call_schedule ? 'Y' : 'N',
          call_schedule_date: input.value.has_call_schedule ? input.value.call_schedule_date : null,
          call_schedule_time: input.value.has_call_schedule ? input.value.call_schedule_time : null,
          queue_stil_active: queueStillActive
        }
      }
    )
      .then(resp => {
        // update : agent
        store.dispatch('updateCurrentAgentStatus', { status: 'READY' })

        // reset call result
        if (vLineType === 'CALL') {
          store.dispatch('voip/clearDataCallResult')
        }

        // resep wa result
        if (vLineType === 'WHATSAPP') {
          store.commit('update_whatsappTempResult', {})
        }

        // trigger update existing list loan
        if (
          vLineType === 'CALL' &&
          vCallingMethod === 'PROGRESSIVE' && 
          resp.data.type !== 'QUIT'
        ) {
          store.commit(
            'voip/updateProgressiveQueue',
            {
              type: 'TRIGGER',
              data: resp.data
            }
          )
        } else if (vLineType === 'CALL' && vCallingMethod === 'PREDICTIVE') {
          console.log('belum not yet')
        } else {
          store.commit('update_updatedSelectedLoan', resp.data.updatedLoan)
        }

        window.Swal.fire({
          icon: 'success',
          title: resp.data.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        })

        nextTick(() => {
          emits('update:open', false)
        })
      })
      .catch(err => {
        err = err.response

        if (err.data.is_finish) { // errno
          window.Swal.fire({
            ...Wording.errMessage.swalGen,
            title: err.data.message,
            html: 'Klik "Perbarui Halaman" untuk mendapatkan data terbaru.',
            confirmButtonText: 'Perbarui Halaman'
          })

          // update : agent
          store.dispatch('updateCurrentAgentStatus', { status: 'READY' })

          // reset call result
          if (lineType.value === 'CALL') {
            store.dispatch('voip/clearDataCallResult')
          }

          nextTick(() => {
            emits('update:open', false)
          })
        } else {
          window.Swal.fire({
            ...Wording.errMessage.swalGen,
            html: err.data.message
          })
        }
      })
  } else {
    focusToError()
  }
}

// lifecycle
onMounted(() => {
  input.value = {
    contact_type: null,
    id_followup_status: null,
    fu_additonal_data: {},
    fu_remarks: null
  }
  data.value = props.data

  if (props.open) {
    openForm()
  }
})

watch(() => props.open, open => {
  if (open) {
    input.value = {
      contact_type: null,
      id_followup_status: null,
      fu_additonal_data: {},
      fu_remarks: null
    }

    data.value = props.data

    openForm()
  }
})

watch(() => props.data, () => {
  input.value = {
    contact_type: null,
    id_followup_status: null,
    fu_additonal_data: {},
    fu_remarks: null
  }
  data.value = props.data
})

watch(() => input.value.contact_type, () => {
  input.value.id_followup_status = null
  input.value.fu_additonal_data = {}
})

watch(() => input.value.id_followup_status, () => {
  input.value.fu_additonal_data = {}
})
</script>

<style lang="scss" scoped>
@import '@/@assets/scss/libs/flatpickr.css'
</style>
