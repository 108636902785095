export const formIsValid = (refForm) => {
  return Object.keys(refForm.value.getErrors()).length <= 0
}

export const focusToError = (timeout = 0) => {
  if (document.querySelector('.invalid-feedback')) {
    setTimeout(() => {
      const el = document.querySelector('.invalid-feedback')
      el.scrollIntoView()

      window.scroll({
        top: document.querySelector('.invalid-feedback').getBoundingClientRect().top + window.scrollY - 200,
        behavior: 'smooth'
      })
    }, timeout)
  }
}

// export const checkValidation = (vRule, value) => {
//   const rules = vRule.split('|')

//   for (let x = 0; x < rules.length; x++) {
//     const rule = rules[x]
//     const rValue = parseFloat(rule.split(':')[1] || '0')

//     if (rule === 'required') {
//       if (!value) {
//         return 'Wajib Diisi'
//       }
//     } else if (rule.indexOf('max:')) {
//       if ((value || '').length > rValue) {
//         return 'Maks. karakter ' + rValue
//       }
//     } else if (rule.indexOf('min:')) {
//       if ((value || '').length < rValue) {
//         return 'Min. karakter ' + rValue
//       }
//     } else if (rule.indexOf('numeric')) {
//       if (isNaN(value) === false) {
//         return 'Harus Numeric'
//       }
//     }
//   }

//   return 'valid'
// }

export const checkValidation = (vRule, value) => {
  const rules = vRule.split('|')

  for (let x = 0; x < rules.length; x++) {
    const rule = rules[x]
    const rValue = parseFloat(rule.split(':')[1] || '0')

    if (rule === 'required') {
      if (!value) {
        return 'Wajib Diisi'
      }
    } else if (rule.indexOf('max:') !== -1) {
      if ((value || '').length > rValue) {
        return 'Maks. karakter ' + rValue
      }
    } else if (rule.indexOf('min:') !== -1) {
      if ((value || '').length < rValue) {
        return 'Min. karakter ' + rValue
      }
    } else if (rule.indexOf('numeric') !== -1) {
      if (isNaN(value)) {
        return 'Harus Numeric'
      }
    }
  }

  return ''
}

export const hasRule = (rules, label, rule) => {
  return rules[label][1].indexOf(rule) > -1
}

export * from 'vee-validate'
