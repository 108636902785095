<template>
<b-modal :modelValue="props.open" 
  hide-header-close hide-footer no-close-on-backdrop no-close-on-esc size="lg"
  title="Add Ticket (Call Result)">
  <div v-if="props.open">
    <PanelBusy v-if="formConf.getting" />
    <Form v-else @submit="submitForm" @invalid-submit="submitForm" ref="VForm">
      <div class="mb-4">
        <div class="wrap_preview p-4 mb-2">
          <div class="row gx-2 gy-2">
            <div class="col-sm-3">
              <div class="preview_data">
                <h3>Caller Name</h3>
                <p>{{ data.customer_name }}</p>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="preview_data">
                <h3>Phone Number</h3>
                <p>{{ data.phone_no }}</p>
              </div>
            </div>
            <template v-if="startedAt && endedAt">
              <div class="clearfix"></div>
              <div class="col-sm-4">
                <div class="preview_data">
                  <h3>Started At</h3>
                  <p>{{ dateFormat(startedAt, 'DD MMM YYYY HH:mm:ss.SSS') }}</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="preview_data">
                  <h3>Ended At</h3>
                  <p>{{ dateFormat(endedAt, 'DD MMM YYYY HH:mm:ss.SSS') }}</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="preview_data">
                  <h3>Duration</h3>
                  <p>{{ duration }}</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="heading_panel mb-3 px-4 mt-0">
        <h3 class="fs-16px text-333">Customer Information</h3>
      </div>
      <div class="px-4 mb-2">
        <div class="row gx-2 gy-2">
          <div class="col-md-4">
            <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.id_client[0] }} <small v-if="hasRule(rules, 'id_client', 'required')" class="text-danger">*</small></label>
            <vSelect 
              :options="mrClient"
              v-model="input.id_client"
              label="text"
              :reduce="(v) => v.value"
              :clearable="false"
              placeholder="-- Select Client --"  />
            <VValidate
              v-model="input.id_client"
              :label="rules.id_client[0]"
              :rules="rules.id_client[1]" />
          </div>
          <div v-if="(
            input.id_client && 
            !formConf.getSelectClient && 
            mrCustomer.length
          )" 
            class="col-md-6">
            <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.id_customer[0] }} <small v-if="hasRule(rules, 'id_customer', 'required')" class="text-danger">*</small></label>
            <vSelect 
              :options="mrCustomer"
              v-model="input.id_customer"
              label="text"
              :reduce="(v) => v.value"
              :clearable="false"
              placeholder="-- Select Customer --">
              <template #option="{ value, text }">
                <span :class="{
                  'fw-bold': value === 'CREATE_NEW'
                }">
                  {{ text }}
                </span>
              </template>
            </vSelect>
            <VValidate
              v-model="input.id_customer"
              :label="rules.id_customer[0]"
              :rules="rules.id_customer[1]" />
          </div>
        </div>
        
        <div v-if="(
            input.id_customer && 
            !formConf.getSelectClient
          )" class="mt-2">
          <div v-if="isEditCustomer || isCreateNewCustomer" 
            class="row gx-2 gy-2">
            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.customer_type[0] }} <small v-if="hasRule(rules, 'customer_type', 'required')" class="text-danger">*</small></label>
              <vSelect 
                :options="mrCustomerType"
                v-model="input.customer_type"
                label="text"
                :reduce="(v) => v.value"
                :clearable="false"
                placeholder="-- Select Customer Type --"  />
              <VValidate
                v-model="input.customer_type"
                :label="rules.customer_type[0]"
                :rules="rules.customer_type[1]" />
            </div>
            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.customer_name[0] }} <small v-if="hasRule(rules, 'customer_name', 'required')" class="text-danger">*</small></label>
              <input v-model="input.customer_name" type="text" class="form-control"/>
              <VValidate
                v-model="input.customer_name"
                :label="rules.customer_name[0]"
                :rules="rules.customer_name[1]" />
            </div>
            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.customer_gender[0] }} <small v-if="hasRule(rules, 'customer_gender', 'required')" class="text-danger">*</small></label>
              <vSelect 
                :options="mrCustomerGender"
                v-model="input.customer_gender"
                label="text"
                :reduce="(v) => v.value"
                :clearable="false"
                placeholder="-- Select Gender --"  />
              <VValidate
                v-model="input.customer_gender"
                :label="rules.customer_gender[0]"
                :rules="rules.customer_gender[1]" />
            </div>
            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.customer_email_address[0] }} <small v-if="hasRule(rules, 'customer_email_address', 'required')" class="text-danger">*</small></label>
              <input v-model="input.customer_email_address" type="email" class="form-control"/>
              <VValidate
                v-model="input.customer_email_address"
                :label="rules.customer_email_address[0]"
                :rules="rules.customer_email_address[1]" />
            </div>
            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.customer_pob[0] }} <small v-if="hasRule(rules, 'customer_pob', 'required')" class="text-danger">*</small></label>
              <input v-model="input.customer_pob" type="text" class="form-control"/>
              <VValidate
                v-model="input.customer_pob"
                :label="rules.customer_pob[0]"
                :rules="rules.customer_pob[1]" />
            </div>
            <div class="col-md-4">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.customer_dob[0] }} <small v-if="hasRule(rules, 'customer_dob', 'required')" class="text-danger">*</small></label>
              <b-input-group>
                <flatPickr 
                  v-model="input.customer_dob" 
                  placeholder="YYYY-MM-DD"
                  class="form-control" />
                <template #append>
                  <b-input-group-text>
                    <i class="ti ti-calendar ti-sm"></i>
                  </b-input-group-text>
                </template>
              </b-input-group>
              <VValidate
                v-model="input.customer_dob"
                :label="rules.customer_dob[0]"
                :rules="rules.customer_dob[1]" />
            </div>
            <div class="col-md-6">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.customer_full_address[0] }} <small v-if="hasRule(rules, 'customer_full_address', 'required')" class="text-danger">*</small></label>
              <textarea v-model="input.customer_full_address" class="form-control"></textarea>
              <VValidate
                v-model="input.customer_full_address"
                :label="rules.customer_full_address[0]"
                :rules="rules.customer_full_address[1]" />
            </div>
          </div>
          <div v-else 
            class="wrap_preview p-4 mb-2">
            <a href="javascript:;" @click="input.is_edit_customer = 'Y'">
              <i class="ti ti-pencil me-2"></i> Edit data customer
            </a>
            <div class="row gx-2 gy-2">
              <div class="col-sm-3">
                <div class="preview_data">
                  <h3>Customer Name</h3>
                  <p>{{ input.customer_name }}</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="preview_data">
                  <h3>Get Matches Contact No</h3>
                  <p>{{ input.customer_phone_no || 'Not Found' }}</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="preview_data">
                  <h3>Customer Type</h3>
                  <p>{{ input.customer_type }}</p>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="col-sm-3">
                <div class="preview_data">
                  <h3>Gender</h3>
                  <p>{{ input.customer_gender || '-' }}</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="preview_data">
                  <h3>Place of Birth</h3>
                  <p>{{ input.customer_pob || '-' }}</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="preview_data">
                  <h3>Date of Birth</h3>
                  <p>{{ input.customer_dob ? dateFormat(input.customer_dob, 'DD MMM YYYY') : '-' }}</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="preview_data">
                  <h3>Email Address</h3>
                  <p>{{ input.customer_email_address || '-' }}</p>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="preview_data">
                  <h3>Address</h3>
                  <p>{{ input.customer_full_address || '-' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="heading_panel mb-3 px-4 mt-4">
        <h3 class="fs-16px text-333">Ticket Information</h3>
      </div>
      <div class="px-4 mb-2">
        <div class="row gx-2 gy-2">
          <div class="col-md-6">
            <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.ticket_subject[0] }} <small v-if="hasRule(rules, 'ticket_subject', 'required')" class="text-danger">*</small></label>
            <input v-model="input.ticket_subject" type="text" class="form-control"/>
            <VValidate
              v-model="input.ticket_subject"
              :label="rules.ticket_subject[0]"
              :rules="rules.ticket_subject[1]" />
          </div>

          <div class="col-md-6">
            <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.ticket_priority[0] }} <small v-if="hasRule(rules, 'ticket_priority', 'required')" class="text-danger">*</small></label>
            <vSelect 
              :options="mrTicketPriority"
              v-model="input.ticket_priority"
              label="text"
              :reduce="(v) => v.value"
              :clearable="false"
              placeholder="-- Select Priority --"  />
            <VValidate
              v-model="input.ticket_priority"
              :label="rules.ticket_priority[0]"
              :rules="rules.ticket_priority[1]" />
          </div>

          <div class="col-md-8">
            <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.id_followup_status[0] }} <small v-if="hasRule(rules, 'id_followup_status', 'required')" class="text-danger">*</small></label>
            <vSelect 
              :options="mrFollowUpStatus"
              v-model="input.id_followup_status"
              label="text"
              :reduce="(v) => v.value"
              :clearable="false"
              placeholder="-- Select Follow Up Status --"  />
            <VValidate
              v-model="input.id_followup_status"
              :label="rules.id_followup_status[0]"
              :rules="rules.id_followup_status[1]" />
          </div>

          <template v-if="input.id_followup_status">
            
            <template v-if="additionalFields.length">
              <div class="clearfix"></div>

              <template v-for="(item, key) in additionalFields" :key="key">
                <div class="col-md-4">
                  <label class="mb-1 text-body-2 text-high-emphasis">
                    {{ item.label }} 
                    <small v-if="item.is_required" class="text-danger">*</small>
                    <small v-else class="text-secondar">(opsional)</small>
                  </label>

                  <template v-if="item.type === 'dropdown'">
                    <vSelect 
                      :options="item.opt_lists"
                      v-model="input.fu_additonal_data[item.fieldKey]"
                      :clearable="false"
                      placeholder="-- Select Options --"  />
                  </template>
                  <template v-else-if="item.type === 'date'">
                    <b-input-group>
                      <flatPickr 
                        v-model="input.fu_additonal_data[item.fieldKey]" 
                        placeholder="YYYY-MM-DD"
                        class="form-control" />
                      <template #append>
                        <b-input-group-text>
                          <i class="ti ti-calendar ti-sm"></i>
                        </b-input-group-text>
                      </template>
                    </b-input-group>
                  </template>
                  <template v-else-if="item.type === 'textarea'">
                    <textarea class="form-control" v-model="input.fu_additonal_data[item.fieldKey]"></textarea>
                  </template>
                  <template v-else>
                    <input type="text" class="form-control" v-model="input.fu_additonal_data[item.fieldKey]" />
                  </template>

                  <VValidate v-if="item.is_required"
                    :modelValue="(
                      item.type === 'dropdown'  
                        ? ( input.fu_additonal_data[item.fieldKey].length ? 1 : null ) 
                        : input.fu_additonal_data[item.fieldKey]
                    )"
                    :label="item.label"
                    rules="required" />
                </div>
              </template>
            </template>

            <div class="clearfix"></div>

            <div class="col-md-8">
              <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.fu_description[0] }} <small v-if="hasRule(rules, 'fu_description', 'required')" class="text-danger">*</small></label>
              <textarea v-model="input.fu_description" class="form-control"></textarea>
              <VValidate
                v-model="input.fu_description"
                :label="rules.fu_description[0]"
                :rules="rules.fu_description[1]" />
            </div>
          </template>

          <div class="clearfix"></div>

          <div class="col-md-4">
            <label class="mb-1 text-body-2 text-high-emphasis">{{ rules.ticket_due_date[0] }} <small v-if="hasRule(rules, 'ticket_due_date', 'required')" class="text-danger">*</small></label>
            <b-input-group>
              <flatPickr 
                v-model="input.ticket_due_date" 
                placeholder="YYYY-MM-DD"
                class="form-control" />
              <template #append>
                <b-input-group-text>
                  <i class="ti ti-calendar ti-sm"></i>
                </b-input-group-text>
              </template>
            </b-input-group>
            <VValidate
              v-model="input.ticket_due_date"
              :label="rules.ticket_due_date[0]"
              :rules="rules.ticket_due_date[1]" />
          </div>
        </div>
      </div>

      <div class="text-right mt-4">
        <button class="btn btn-primary">Submit Result</button>
      </div>
    </Form>
  </div>
</b-modal>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, computed, ref, reactive, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Form, formIsValid, focusToError, hasRule } from '@/libs/veeValidate.js'
import { Gen, Wording } from '@/libs/Gen.js'

import VValidate from '@/components/VValidate.vue'
import PanelBusy from '@/components/PanelBusy.vue'
import _ from '@/libs/lodash.js'
import { moment, dateFormat } from '@/libs/moment.js'

import vSelect from '@/libs/vSelect.js'
import flatPickr from 'vue-flatpickr-component'

// initiate
const restApi = 'BoInboundTicket'
const route = useRoute()
const router = useRouter()
const store = useStore()
const emits = defineEmits([
  'update:open'
])
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  data: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

// variables
const data = ref(props.data)

const startedAt = computed(() => {
  if (data.value.rtc_ongoing_start_at && data.value.rtc_ongoing_finish_at) {
    return data.value.rtc_ongoing_start_at
  } else {
    return data.value.rtc_connecting_start_at
  }
})

const endedAt = computed(() => {
  if (data.value.rtc_ongoing_start_at && data.value.rtc_ongoing_finish_at) {
    return data.value.rtc_ongoing_finish_at
  } else {
    return data.value.rtc_connecting_finish_at
  }
})

const duration = computed(() => {
  const c = startedAt.value
  const d = endedAt.value
  const e = moment.duration(moment(d).diff(c))
  return (
    String(e.hours()).padStart(2, '0') + ':' + 
    String(e.minutes()).padStart(2, '0') + ':' + 
    String(e.seconds()).padStart(2, '0')
  )
})

// variables forms
const VForm = ref(null)

const rules = {
  id_client: ['Select Client', 'required'],
  id_customer: ['Select Customer', 'required'],
  customer_type: ['Customer Type', 'required'],
  customer_name: ['Customer Name', 'required'],
  customer_gender: ['Gender', 'required'],
  customer_email_address: ['Email Address', 'email'],
  customer_full_address: ['Full Address', ''],
  customer_pob: ['Place of Birth', ''],
  customer_dob: ['Date of Birth', ''],
  ticket_subject: ['Subject', 'required'],
  ticket_priority: ['Ticket Priority', 'required'],
  ticket_due_date: ['Due Date', 'required'],
  id_followup_status: ['Follow Up Status', 'required'],
  fu_description: ['Description', 'required']
}

const formConf = reactive({
  getting: true,
  getSelectClient: false,
  sending: false
})

const input = ref({})

const mrClient = ref([])
const mrCustomer = ref([])
const mrCustomerType = ref([])
const mrCustomerGender = ref([])
const mrTicketPriority = ref([])
const mrFollowUpStatus = ref([])

const isCreateNewCustomer = computed(() => {
  return input.value.id_customer === 'CREATE_NEW'
})

const isEditCustomer = computed(() => {
  return input.value.is_edit_customer === 'Y'
})

const selectedFuStatus = computed(() => {
  return _.find(mrFollowUpStatus.value, { value: input.value.id_followup_status }) || {}
})

const additionalFields = computed(() => {
  if (input.value.id_followup_status) {
    const fields = selectedFuStatus.value.additional_fields || []

    return _.map(fields, v => {
      v.fieldKey = (v.label || '').toLowerCase()
      return v
    })
  } else {
    return []
  }
})

// methods
function openForm () {
  formConf.getting = true

  Gen.apiRest(
    '/crud/get/' + restApi + '/form-add-result',
    {
      method: 'POST',
      data: {
        name: route.name
      }
    }
  )
    .then(resp => {
      mrClient.value = resp.data.mrClient
      mrTicketPriority.value = resp.data.mrTicketPriority
      mrCustomerType.value = resp.data.mrCustomerType
      mrCustomerGender.value = resp.data.mrCustomerGender

      nextTick(() => {
        formConf.getting = false
      })
    })
    .catch(err => {
      err = err.response
      window.Swal.fire({
        ...Wording.errMessage.swalGen,
        html: err.data.message
      })
    })
}

function selectClient () {
  input.value.id_followup_status = null
  input.value.id_customer = null

  if (input.value.id_client) {
    formConf.getSelectClient = true

    Gen.apiRest(
      '/crud/get/' + restApi + '/select-client',
      {
        method: 'POST',
        data: {
          id_client: input.value.id_client,
          caller_name: data.value.customer_name,
          caller_number: data.value.phone_no
        }
      }
    )
      .then(resp => {
        mrFollowUpStatus.value = resp.data.mrFollowUpStatus
        mrCustomer.value = resp.data.mrCustomer

        nextTick(() => {
          formConf.getSelectClient = false
        })
      })
      .catch(err => {
        mrFollowUpStatus.value = []
        mrCustomer.value = []
        err = err.response
        window.Swal.fire({
          ...Wording.errMessage.swalGen,
          html: err.data.message
        })
      })
  } else {
    mrFollowUpStatus.value = []
    mrCustomer.value = []
  }
}

function selectCustomer () {
  input.value = {
    ...input.value,
    is_edit_customer: null,
    customer_type: null,
    customer_name: data.value.customer_name,
    customer_gender: null,
    customer_email_address: null,
    customer_full_address: null,
    customer_pob: null,
    customer_dob: null,
    customer_phone_no: data.value.phone_no
  }

  if (input.value.id_customer) {
    if (isCreateNewCustomer.value) {
      input.value = {
        ...input.value,
        is_edit_customer: 'Y'
      }
    } else {
      const selectedCustomer = _.find(
        mrCustomer.value,
        { value: input.value.id_customer }
      ) || {}

      input.value = {
        ...input.value,
        is_edit_customer: 'N',
        customer_type: selectedCustomer.customer_type,
        customer_name: selectedCustomer.customer_name,
        customer_gender: selectedCustomer.gender,
        customer_email_address: selectedCustomer.email_address,
        customer_full_address: selectedCustomer.full_address,
        customer_pob: selectedCustomer.pob,
        customer_dob: selectedCustomer.dob,
        customer_phone_no: selectedCustomer.phone_no
      }
    }
  }
}

function submitForm () {
  if (formIsValid(VForm)) {
    formConf.sending = true

    input.value.ticket_description = input.value.fu_description
    input.value.ticket_additonal_data = {} // tdk ada fiturnya

    input.value.is_select_customer = isCreateNewCustomer.value ? 'N' : 'Y'
    input.value.id_customer = isCreateNewCustomer.value ? 0 : input.value.id_customer
    input.value.is_edit_customer = isCreateNewCustomer.value ? 'Y' : input.value.is_edit_customer

    Gen.loadingGlobal()
    emits('update:open', false)

    Gen.apiRest(
      '/crud/do/' + restApi + '/add-result-incoming-call',
      {
        data: {
          ...data.value,
          ...input.value
        }
      }
    )
      .then(resp => {
        // update : agent
        store.dispatch('updateCurrentAgentStatus', { status: 'READY' })

        // reset call result
        store.dispatch('voip/clearDataCallResult')

        //
        window.Swal.fire({
          icon: 'success',
          title: resp.data.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        }).then(() => {
          window.location.href = router.resolve({
            name: restApi,
            params: {
              pageSlug: 'detail',
              pageId: resp.data.ticketPageId
            }
          }).href
        })
      })
      .catch(err => {
        err = err.response

        if (err.data.is_finish) { // errno
          window.Swal.fire({
            ...Wording.errMessage.swalGen,
            title: err.data.message,
            html: 'Klik "Perbarui Halaman" untuk mendapatkan data terbaru.',
            confirmButtonText: 'Perbarui Halaman'
          })

          // update : agent
          store.dispatch('updateCurrentAgentStatus', { status: 'READY' })

          // reset call result
          store.dispatch('voip/clearDataCallResult')

          nextTick(() => {
            emits('update:open', false)
          })
        } else {
          window.Swal.fire({
            ...Wording.errMessage.swalGen,
            html: err.data.message
          })
        }
      })
  } else {
    focusToError()
  }
}

// lifecycle
onMounted(() => {
  input.value = {
    ticket_additonal_data: {},
    fu_additonal_data: {}
  }
  data.value = props.data

  if (props.open) {
    openForm()
  }
})

watch(() => props.open, open => {
  if (open) {
    input.value = {
      ticket_additonal_data: {},
      fu_additonal_data: {}
    }

    data.value = props.data

    openForm()
  }
})

watch(() => props.data, () => {
  input.value = {
    ticket_additonal_data: {},
    fu_additonal_data: {}
  }
  data.value = props.data
})

watch(() => input.value.id_client, () => {
  selectClient()
})

watch(() => input.value.id_customer, () => {
  selectCustomer()
})

watch(() => input.value.id_followup_status, () => {
  input.value.fu_additonal_data = {}
})
</script>

<style lang="scss" scoped>
@import '@/@assets/scss/libs/flatpickr.css'
</style>
