import { createApp } from 'vue' // Vue, configureCompat
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// import libraries
import BootstrapVueNext from 'bootstrap-vue-next'
import '@/libs/validation4.js'
import VueProgressBar from '@aacassandra/vue3-progressbar'
import BSkeleton from '@/components/BSkeleton.vue'
window.Swal = require('sweetalert2')

// load auth first then load app template
store.dispatch('watchAuth').then(() => { 
  document.getElementById('pLoader').remove()

  // initiate app
  const app = createApp(App)
  app.config.globalProperties.loadingOverlay = true

  app.config.warnHandler = function (msg, vm, trace) {
    return null
  }

  app
    .use(BootstrapVueNext)
    .use(VueProgressBar, {
      color: '#7367f0',
      failedColor: '#874b4b',
      thickness: '3px',
      transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
      },
      autoRevert: true,
      location: 'top',
      inverse: false
    })
    .component('BSkeleton', BSkeleton)
    .use(store)
    .use(router)
    .mount('#app')

  // do refresh token
  const refreshToken = () => {
    window.isRefresh = 'CSRF'
    fetch(
      process.env.VUE_APP_API_URL + '/refresh-token?type=csrf-' + document.querySelector('meta[name=\'sess-cookie\']').content, 
      { 
        method: 'GET', 
        credentials: 'include',
        headers: { 
          'X-CSRF-TOKEN': document.querySelector('meta[name=\'csrf-token\']').content, 
          'X-SESS-PAGE-ID': window.sessPageID,
          'X-API-KEY': 'Basic ' + process.env.VUE_APP_API_KEY 
        } 
      }
    )
      .then(response => response)
      .then(async response => {
        if (response.status === 200) {
          if (document.querySelector('meta[name=\'sess-cookie\']').content === 'INIT_LOCAL') {
            const resp = await response.json()
            document.querySelector('meta[name="sess-cookie"]').setAttribute('content', resp.cookieName)
            document.querySelector('meta[name="csrf-token"]').setAttribute('content', resp.token)
          } else {
            document.querySelector('meta[name="csrf-token"]').setAttribute('content', await response.text())
          }
        } else {
          document.querySelector('meta[name="csrf-token"]').setAttribute('content', '')
        }
        window.isRefresh = false
      })
  }

  (function () {
    refreshToken()
    
    setInterval(() => {
      refreshToken()
    }, 15 * 60 * 1000)
  })()
})
