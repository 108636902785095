import * as moment from 'moment'
moment.locale('en')

const dateFormat = (date, format = 'DD MMM YYYY HH:mm') => {
  if (format === 'dateOnly') format = 'DD MMMM YYYY'

  if (!date) return ''
  else return moment(date).format(format)
}

const monthFormat = (numMonth, format = 'MMMM') => {
  return moment().month(numMonth - 1).format(format)
}

export { moment, dateFormat, monthFormat }
