<template>
<Field type="hidden" v-model="input" :rules="rules" :name="label+key+'_vInput'" :label="label" />
<ErrorMessage :name="label+key+'_vInput'" class="invalid-feedback">
  <template #message>
    <slot name="message"></slot>
  </template>
</ErrorMessage>
</template>

<script setup>
import { Field, ErrorMessage } from 'vee-validate'
import { onMounted, ref, watch, defineProps } from 'vue'

const key = new Date().getTime()
const props = defineProps(['modelValue', 'name', 'label', 'rules', 'vid'])
const input = ref(props.modelValue)

onMounted(() => {
  input.value = props.modelValue
})

watch(() => props.modelValue, (v) => {
  input.value = v
})
</script>
