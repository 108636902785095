import _ from 'lodash'

_.mixin({
  genClone: (value) => {
    if (!value) return value
    else if (
      value.constructor === ([]).constructor ||
      value.constructor === ({}).constructor
    ) {
      return JSON.parse(JSON.stringify(value))
    } else {
      return value
    }
  },
  findValue: (x) => {
    const dataValue = _.find(x.data, x.filter) || {}
    return dataValue[x.field]
  }
})

export default _
