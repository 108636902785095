import { defineRule, configure } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json'
import AllRules from '@vee-validate/rules'

defineRule('_validate_datetime', value => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }

  try {
    const a = (new Date(value)).toString().toLowerCase()
    
    if (a.indexOf('invalid date') > -1) {
      return 'This field is invalid date/time'
    }

    return true
  } catch {
    return 'This field is invalid date/time'
  }
})

defineRule('min_time', (value, [minTime]) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }

  const vTime = value.toString().split(':')
  const mTime = minTime.toString().split(':')

  if (vTime[0] < mTime[0]) {
    return 'This field minimum time is ' + minTime
  } else if (vTime[0] === mTime[0] && vTime[1] < mTime[1]) {
    return 'This field minimum time is ' + minTime
  }

  return true
})

defineRule('max_time', (value, [maxTime]) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }

  const vTime = value.toString().split(':')
  const mTime = maxTime.toString().split(':')

  if (vTime[0] > mTime[0]) {
    return 'This field maximum time is ' + maxTime
  } else if (vTime[0] === mTime[0] && vTime[1] > mTime[1]) {
    return 'This field maximum time is ' + maxTime
  }

  return true
})

defineRule('has_space', value => {
  if (!value || !value.length) {
    return true
  }

  if (value.toString().indexOf(' ') > -1) {
    return 'This field should be seperated with underscore, not space.'
  }

  return true
})

defineRule('is_duplicate_value', value => {
  if (value === 'Y') {
    return 'This field is duplicate'
  }

  return true
})

defineRule('is_invalid', (value, [cause]) => {
  if (value === 'Y') {
    return 'This field is invalid causes: ' + cause
  }

  return true
})

Object.keys(AllRules).forEach(rule => { 
  defineRule(rule, AllRules[rule]) 
})

configure({ 
  validateOnInput: true, 
  generateMessage: localize('en', en) 
})
