import { createStore } from 'vuex'
import createMultiTabState from 'vuex-multi-tab-state'
import { Gen, Master } from '@/libs/Gen.js'
import moment from 'moment'
import _ from 'lodash'

import moduleVoip from './voip.js'

export default createStore({
  plugins: [
    createMultiTabState({
      statesPaths: [
        'BoUserToken', 
        'BoUser', 
        'badgeNotif', 
        'listDownload',
        'currentAgentStatus',
        'agentStatuses',
        'whatsappLinkedNumber',
        'confMoveDataToSub',
        'confPredictiveQueue',
        'confProgressiveQueue',
        'voip.UASessionData',
        'voip.dataCallResult',
        'voip.predictiveQueue',
        'whatsappTempResult'
      ]
    })
  ],
  state: {
    meta: { title: process.env.VUE_APP_TITLE },
    BoUserToken: null,
    BoUser: {},
    loadingGlobal: false,
    data: {},
    badgeNotif: 0,
    listDownload: [],
    currentAgentStatus: null,
    agentStatuses: [],
    whatsappLinkedNumber: null, // 628xxxx
    codeResetStoreEveryLogin: [
      'confMoveDataToSub',
      'updatedSelectedLoan',
      'whatsappLinkedNumber',
      'whatsappTempResult'
    ],
    confMoveDataToSub: { code: null, id_checked_loan: [] },
    whatsappTempResult: {},
    updatedSelectedLoan: null
  },
  getters: {
    BoUserToken: state => state.BoUserToken,
    BoUser: state => state.BoUser || {},
    authenticated: state => state.BoUserToken && state.BoUser?.username,
    isUserAdmin: state => {
      return (state.BoUser || {}).level_type === Master.kodeULevelAdmin
    },
    isUserAgent: state => {
      return (state.BoUser || {}).level_type === Master.kodeULevelAgent
    },
    isUserManager: state => {
      return (state.BoUser || {}).level_type === Master.kodeULevelManager
    },
    isUserSpv: state => {
      return (state.BoUser || {}).level_type === Master.kodeULevelSpv
    },
    isUserManagerSpv: state => {
      return [Master.kodeULevelManager, Master.kodeULevelSpv].indexOf((state.BoUser || {}).level_type) > -1
    },
    openWhatsappResult: state => {
      // eslint-disable-next-line no-unneeded-ternary
      return Object.keys(state.whatsappTempResult).length ? true : false
    }
  },
  mutations: {
    updateUser (state, value = null) {
      value = value || '{"level":{}}'
      state.BoUser = value
      if (value) Gen.putStorage('BoUser', value)
      else Gen.removeStorage('BoUser')
    },
    updateUserToken (state, value = null) {
      state.BoUserToken = value
      if (value) Gen.putStorage('BoUserToken', value)
      else Gen.removeStorage('BoUserToken')
    },
    logoutUserToken (state) {
      if (window.refreshUA) clearInterval(window.refreshUA)
      const token = state.BoUserToken
      state.BoUserToken = null
      state.BoUser = {}
      Gen.removeStorage('BoUser')
      Gen.removeStorage('BoUserToken')
      localStorage.setItem('newLogin', 'Y')
      window.location.href = process.env.VUE_APP_LOGOUT_URL + '?token=' + token
    },
    turnOnLoading (state) {
      state.loadingGlobal = true
      document.body.style.overflow = 'hidden'
    },
    turnOffLoading (state) {
      state.loadingGlobal = false
      document.body.style.overflow = 'unset'
    },
    updateBadgeNotif (state, value = 0) {
      state.badgeNotif = value
    },
    updateListDownload (state, value = []) {
      state.listDownload = value
    },
    update_currentAgentStatus (state, value) {
      state.currentAgentStatus = value
    },
    update_agentStatuses (state, value = []) {
      state.agentStatuses = value
    },
    update_whatsappLinkedNumber (state, value = []) {
      state.whatsappLinkedNumber = value
    },
    update_whatsappTempResult (state, value = {}) {
      state.whatsappTempResult = value
    },
    update_confMoveDataToSub (state, value = {}) {
      if (value === null || value === 'REMOVE') {
        state.confMoveDataToSub = {
          parent_cmp_id: null,
          to_cmp_type: null,
          to_cmp_id: null,
          from_cmp_id: null,
          calling_method: null,
          code: null,
          id_checked_loan: []
        }

        Gen.removeStorage('confMoveDataToSub')
      } else {
        state.confMoveDataToSub = {
          parent_cmp_id: value.parent_cmp_id,
          to_cmp_type: value.to_cmp_type,
          to_cmp_id: value.to_cmp_id,
          from_cmp_id: value.from_cmp_id,
          calling_method: value.calling_method,
          code: value.code,
          id_checked_loan: value.id_checked_loan || []
        }

        Gen.putStorage('confMoveDataToSub', state.confMoveDataToSub)
      }
    },
    update_updatedSelectedLoan (state, value = {}) {
      // untuk mengupdate status list loan setelah add result (WA/CALL)
      if (value === null || value === 'REMOVE') {
        state.updatedSelectedLoan = null
      } else {
        state.updatedSelectedLoan = value
      }
    }
  },
  actions: {
    resetStoreEveryLogin ({ commit, state }) {
      _.forEach(state.codeResetStoreEveryLogin, code => {
        commit('update_' + code, 'REMOVE')
      })
    },
    updateCurrentAgentStatus ({ commit, getters }, payload) {
      if (getters.isUserAgent) {
        Gen.apiRest(
          '/auth/do/agent-status/update',
          {
            method: 'POST',
            data: {
              new_status: payload.status,
              new_current_activity: payload.activity || false
            }
          }
        )
          .then(() => {
            commit('update_currentAgentStatus', payload.status)
          })
      }
    },
    async watchAuth ({ commit, getters }) {
      initTab()

      if (new URLSearchParams(location.search).get('uToken') && process.env.VUE_APP_MODE === 'local') {
        const response = await fetch(
          process.env.VUE_APP_API_URL + '/local-only?uToken=' + new URLSearchParams(location.search).get('uToken'), 
          {
            method: 'GET',
            credentials: 'include',
            headers: { 'X-API-KEY': 'Basic ' + process.env.VUE_APP_API_KEY }
          }
        )
        localStorage.setItem('newLogin', 'Y')
        Gen.putStorage('BoUserToken', await response.text())
        window.location.href = process.env.VUE_APP_BASE_URL
      } else {
        const userToken = Gen.getStorage('BoUserToken')
        commit('updateUserToken', userToken)
        commit('updateUser', null)

        if (userToken) {
          try {
            let resp = await Gen.apiRest('/auth/get/info')
            resp = resp.data
            if (resp.status === 'success') {
              commit('updateUser', resp.data)
              commit('updateBadgeNotif', resp.badgeNotif)
              commit('updateListDownload', resp.listDownload)

              commit('update_currentAgentStatus', resp.currentAgentStatus)
              commit('update_agentStatuses', resp.agentStatuses)

              window.pendingFormResultFromAuth = resp.pendingFormResult
              window.pendingProgressiveQueue = resp.pendingProgressiveQueue
              window.pendingPredictiveQueue = resp.pendingPredictiveQueue
              window.monitoringBroadcastTo = resp.monitoringBroadcastTo

              setTimeout(() => {
                if (getters.isUserAgent) {
                  document.querySelector('html').classList.add('layout-navbar-fixed')
                }
              }, 50)

              // refresh token auth
              window.refreshUA = () => {
                window.isRefresh = 'UA'
                setTimeout(() => {
                  fetch(
                    process.env.VUE_APP_API_URL + '/auth/refresh-token',
                    {
                      method: 'GET',
                      credentials: 'include',
                      headers: Gen.apiHeader()
                    }
                  )
                    .then(response => response)
                    .then(async response => {
                      if (response.status === 200) {
                        const resp = await response.json()
                        commit('updateUserToken', resp.codeChallenge)
                        commit('updateUser', resp.authInfo)
                      } else {
                        commit('logoutUserToken')
                      }
                      window.isRefresh = false
                    })
                }, window.isHoldRefreshUA === 'Y' ? 10000 : 0)
              }

              // refresh agent to stays updated
              window.refreshAgentStaysUpdated = () => {
                Gen.apiRest('/auth/refresh-agent-stays-update')
                  .then(() => {})
                  .catch(() => {})
              }

              window.intervalAsUserLoggedOn = setInterval(() => {
                if (window.firstTab() === window.currentTabID) {
                  window.refreshUA()

                  if (getters.isUserAgent) {
                    window.refreshAgentStaysUpdated()
                  }
                }
              }, 30 * 60 * 1000) // refresh every 30 minutes
            } else {
              commit('logoutUserToken')
            }
          } catch (error) {
            commit('logoutUserToken')
          }
        }
      }
    }
  },
  modules: {
    voip: moduleVoip
  }
})

const initTabCount = () => {
  const nowTime = moment().format('YYYY-MM-DD HH:mm:ss')
  let tabs = JSON.parse(localStorage.getItem('Tabs') || '[]')
  if (_.find(tabs, v => v.tab === window.currentTabID)) {
    const index = _.map(tabs, 'tab').indexOf(window.currentTabID)
    tabs[index].updated = nowTime
  } else {
    tabs.push({
      tab: window.currentTabID,
      updated: nowTime
    })
  }
  tabs = _.filter(tabs, v => {
    return Math.abs(moment(v.updated).diff(nowTime, 'seconds')) <= 5
  })
  localStorage.setItem('Tabs', JSON.stringify(tabs))
}

const initTab = () => {
  window.currentTabID = moment().valueOf()
  setInterval(() => {
    initTabCount()
  }, 5 * 1000) // 5s
}

window.firstTab = () => {
  const tabs = JSON.parse(localStorage.getItem('Tabs') || '[]')
  return tabs[0].tab || ''
}
